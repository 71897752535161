import { BehaviorSubject, forkJoin, from, Subject, throwError, Observable } from 'rxjs';
import { IndexDbTable } from './index-db-table';
import {
  EXECUTE_AS_TRANSACTION_EVENT_BUFFER_TIME_SPAN,
  IndexDbConfig,
  TableConfig,
  TableNames,
} from './index-db-config';
import { bufferTime, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { IndexDbConnectionState } from './connection-state';

class ExecuteAsTransactionEvent {
  completed$ = new Subject<any>();
  error$ = new Subject<any>();
  constructor(
    public tableNames: TableNames[],
    public sequence: (t: IDBTransaction) => Observable<any>,
  ) {}
}

export class IndexDbConnection {
  private static database$$: BehaviorSubject<IndexDbConnectionState> = new BehaviorSubject<IndexDbConnectionState>(
    null,
  );
  private static config: IndexDbConfig;
  private executeAsTransactionEvents$$ = new Subject<ExecuteAsTransactionEvent>();

  private static init(config: IndexDbConfig): void {
    if (!IndexDbConnection.database$$.value) {
      IndexDbConnection.config = config;
      const { dbName, version, tables } = config;
      const req: IDBOpenDBRequest = indexedDB.open(dbName, version);
      req.onsuccess = function (evt) {
        const database = this.result;
        IndexDbConnection.database$$.next({ database });
        console.log('[IndexedDB]: Successfully connected to indexDb');
      };
      req.onerror = function (evt) {
        IndexDbConnection.database$$.next({ error: evt });
        console.error('[IndexedDB]: Failed to connect to indexDb', (evt.target as any).errorCode);
      };

      req.onupgradeneeded = function (evt) {
        console.log('[IndexedDB]: Upgrade needed. Creating indexDb schema...');
        const database: IDBDatabase = (evt.currentTarget as any).result;
        const storesLength = database.objectStoreNames.length;
        for (let i = 0; i < storesLength; i++) {
          try {
            const storeName = database.objectStoreNames.item(0);
            database.deleteObjectStore(storeName);
            console.log('[IndexedDb]: Removed store ', storeName);
          } catch (error) {
            console.error('Error deleting object store: ', database.objectStoreNames.item(i), ' ', error);
          }
        }
        Object.keys(tables).map((tableName: TableNames) => {
          const tableConfig = tables[tableName];
          const { options } = tableConfig;
          const store = database.createObjectStore(tableName, {
            keyPath: options.keyPath,
            autoIncrement: options.autoIncrement,
          });
          if (options.indexes) {
            Object.keys(options.indexes).forEach((indexedKey) => {
              store.createIndex(indexedKey, indexedKey, options.indexes[indexedKey]);
            });
          }
          if (!store.transaction.oncomplete) {
            store.transaction.oncomplete = function (event) {
              console.log('[IndexDB]: Successfully created tables: ', Object.keys(tables).join(', '));
            };
            store.transaction.onerror = function (event) {
              console.error('[IndexDB]: Error creating tables');
            };
          }
        });
      };
    }
  }

  constructor(private config: IndexDbConfig) {
    IndexDbConnection.init(config);
    this.executeAsTransactionEvents$$
      .pipe(
        bufferTime(EXECUTE_AS_TRANSACTION_EVENT_BUFFER_TIME_SPAN),
        filter((e) => !!e.length),
        map((events: ExecuteAsTransactionEvent[]) => {
          const eventsGroupedByTables = {};
          for (const e of events) {
            const tableNamesHash = e.tableNames.join(',');
            if (!eventsGroupedByTables[tableNamesHash]) {
              eventsGroupedByTables[tableNamesHash] = [];
            }
            eventsGroupedByTables[tableNamesHash].push(e);
          }
          return eventsGroupedByTables;
        }),
        mergeMap((mapByTables: { [k: string]: ExecuteAsTransactionEvent[] }) => {
          const observablesByTables$ = [];
          for (const k in mapByTables) {
            const { tableNames } = mapByTables[k][0];
            observablesByTables$.push(this.processExecuteAsTransactionEvents(tableNames, mapByTables[k]));
          }
          return forkJoin(observablesByTables$);
        }),
      )
      .subscribe();
  }

  public executeAsTransaction(
    tableNames: TableNames[],
    transactionCallback: (transaction: IDBTransaction) => Observable<any>,
  ): Promise<any> {
    const e = new ExecuteAsTransactionEvent(tableNames, transactionCallback);
    this.executeAsTransactionEvents$$.next(e);
    return new Promise<any>((resolve, reject) => {
      e.completed$.pipe(take(1)).subscribe((v) => {
        resolve(v);
      });
      e.error$.pipe(take(1)).subscribe((e) => reject(e));
    });
  }

  public table<T extends TableNames>(tableName: T): IndexDbTable<T> {
    return new IndexDbTable<T>(
      IndexDbConnection.config.tables[tableName as T] as unknown as TableConfig<T>,
      IndexDbConnection.database$$,
    );
  }

  public async cleanData(): Promise<unknown> {
    const { database, error } = await this.resolveDbConnection();
    if (!error) {
      return this.doCleanData(database);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Ranges from 0 to 1
   */
  public getQuotaUsePercentage(): Observable<number> {
    if (navigator.storage && navigator.storage.estimate) {
      return from(navigator.storage.estimate()).pipe(
        map((estimate) => {
          if (estimate['usageDetails'] && estimate['usageDetails'].indexedDB) {
            return estimate['usageDetails'].indexedDB / estimate.quota;
          }
          return estimate.usage / estimate.quota;
        }),
      );
    } else {
      return throwError("The browser doesn't support Storage Manager");
    }
  }

  private doCleanData(database: IDBDatabase): Promise<void> {
    const tables = Object.keys(IndexDbConnection.config.tables);
    // open a read/write db transaction, ready for clearing the data
    return new Promise<void>((resolve, reject) => {
      const transaction = database.transaction(tables, 'readwrite');

      // report on the success of the transaction completing, when everything is done
      transaction.oncomplete = function (event) {
        console.log('[IndexedDB]: Successfully cleaned data');
        resolve();
      };

      transaction.onerror = function (event) {
        reject(event);
      };

      tables.forEach((table) => {
        // create an object store on the transaction
        const objectStore = transaction.objectStore(table);

        // Make a request to clear all the data out of the object store
        objectStore.clear();
      });
    });
  }

  private processExecuteAsTransactionEvents(
    tableNames: TableNames[],
    events: ExecuteAsTransactionEvent[],
  ): Observable<any> {
    return IndexDbConnection.database$$.pipe(
      filter((db) => !!db),
      take(1),
      switchMap(({ database, error }) => {
        if (error) {
          return throwError(error);
        }
        const transaction = database.transaction(tableNames, 'readwrite');
        return new Observable<any>((observer) => {
          transaction.oncomplete = () => {
            observer.next();
            observer.complete();
          };
          transaction.onerror = (errorEvent) => {
            observer.error(errorEvent);
            for (const e of events) {
              e.error$.next(errorEvent);
            }
          };
          for (const event of events) {
            event
              .sequence(transaction)
              .pipe(take(1))
              .subscribe(
                (v) => event.completed$.next(v),
                (e) => event.error$.next(e),
                () => event.completed$.next(undefined),
              );
          }
        });
      }),
    );
  }

  private resolveDbConnection(): Promise<IndexDbConnectionState> {
    return IndexDbConnection.database$$
      .pipe(
        filter((db) => !!db),
        take(1),
      )
      .toPromise();
  }
}
