import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';
import { WpError } from '../../types';
import { GetRideRequest, GetRideResponse } from '../../../../api/endpoints/get-ride';
import { GetRideDriverFeeResponse } from '../../../../api/endpoints/get-ride-driver-fee';
import { GetRidePolylineResponse } from '../../../../api/endpoints/get-ride-polyline';
import { GetEndOfRideInspectionAbstractByRideResponse } from '../../../../api/endpoints/get-end-of-ride-inspection-abstract-by-ride';

const moduleKey = 'Data';
const submoduleKey = 'Rides';

/**
 * Route traces
 */
export const resetRouteTrace = createAction(
  `[${moduleKey}] [${submoduleKey}] [Route Trace] Reset route trace`,
  props<{ rideId: string }>(),
);

export const incomingRouteTracesReceived = createAction(
  `[${moduleKey}] [${submoduleKey}] [Route Trace] Incoming route traces received`,
  props<{ rideId: string; routeTraces: fromTypes.PortalRouteTracePoint[] }>(),
);

export const routeTraceHistoryReceived = createAction(
  `[${moduleKey}] [${submoduleKey}] [Route Trace] Route trace history received`,
  props<{
    rideId: string;
    routeTraces: fromTypes.PortalRouteTracePoint[];
    lastIndex: number;
  }>(),
);

export const routeTraceError = createAction(
  `[${moduleKey}] [${submoduleKey}] [Route Trace] Route trace error`,
  props<{ rideId: string; error: fromTypes.WpError }>(),
);

export const getRideRouteTraceIndexSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}] [Route Trace] Get ride route trace index success`,
  props<{ rideId: string; indexType: 'start' | 'end'; index: number }>(),
);

/**
 * Ride V2
 * Polyline is loaded separately, if the ride qualifies for it (has the corresponding status)
 */
export const getRideV2Requested = createAction(
  '[RideData] API: getRideV2 requested',
  props<{ request: GetRideRequest }>(),
);

export const getRideV2RequestedOnFlashUpdate = createAction(
  '[RideData] API: getRideV2 requested on flash update',
  props<{ request: GetRideRequest }>(),
);
export const getRideV2Success = createAction(
  '[RideData] API: getRideV2 success',
  props<{ rideId: string; response: GetRideResponse }>(),
);
export const getRideV2Failed = createAction(
  '[RideData] API: getRideV2 failed',
  props<{ rideId: string; error: WpError }>(),
);

/**
 * Driver fee by the ride
 */
export const getDriverFeeByRideRequested = createAction(
  '[RideData] API: getRideDriverFee requested',
  props<{ rideId: string }>(),
);

export const getDriverFeeByRideSuccess = createAction(
  '[RideData] API: getRideDriverFee success',
  props<{ rideId: string; response: GetRideDriverFeeResponse }>(),
);

export const getDriverFeeByRideFailed = createAction(
  '[RideData] API: getRideDriverFee failed',
  props<{ rideId: string; error: WpError }>(),
);

/**
 * Ride polyline
 */
export const loadRidePolylineIfEligible = createAction(
  '[RideData] Load ride polyline if eligible',
  props<{ rideId: string }>(),
);

export const loadRidePolylineIdle = createAction('[RideData] Load ride polyline idle', props<{ rideId: string }>());

export const getRidePolylineRequested = createAction(
  '[RideData] API: getRidePolyline requested',
  props<{ rideId: string }>(),
);

export const getRidePolylineSuccess = createAction(
  '[RideData] API: getRidePolyline success',
  props<{ rideId: string; response: GetRidePolylineResponse }>(),
);

export const getRidePolylineFailed = createAction(
  '[RideData] API: getRidePolyline failed',
  props<{ rideId: string; error: WpError }>(),
);

/**
 * Ride polyline
 */
export const getEndOfRideInspectionRequested = createAction(
  '[RideData] API: getEndOfRideInspection requested',
  props<{ rideId: string }>(),
);

export const getEndOfRideInspectionSuccess = createAction(
  '[RideData] API: getEndOfRideInspection success',
  props<{ rideId: string; response: GetEndOfRideInspectionAbstractByRideResponse }>(),
);

export const getEndOfRideInspectionFailed = createAction(
  '[RideData] API: getEndOfRideInspection failed',
  props<{ rideId: string; error: WpError }>(),
);
