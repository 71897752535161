<div class="wp-dp-month">
  <div class="month-label" (click)="onLabelClick()">{{ decade.label }} <span class="arrow-up"></span></div>

  <div class="vertical-line"></div>

  <ng-container *ngIf="!isLoading; else loading">
    <div class="days">
      <div *ngFor="let year of yearsForDecade$ | async" class="day">
        <wp-dp-year [year]="year" />
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <wp-section-spinner />
  </ng-template>
</div>
