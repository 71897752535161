import { from, Observable, of } from 'rxjs';
import { catchError, delay, map, retryWhen, take, tap } from 'rxjs/operators';
import {
  APITypeMapping,
  CachedAPIs,
  MAX_RETRY_COUNT,
  REMOVE_LEAST_RECENT_ITEMS_ON_QUOTA_VIOLATION,
  RETRY_TIMEOUT,
} from './cache-config';
import { getConnectionInstance, TableNames } from './index-db';

export function getCached<T extends CachedAPIs>(
  requestHash: string,
): Observable<APITypeMapping[T]['response'] | undefined> {
  const serverCacheTable = getConnectionInstance().table(TableNames.server_requests_cache);
  const transactionSequence = (transaction) => {
    return serverCacheTable.get(requestHash, transaction).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(RETRY_TIMEOUT),
          tap((error) => console.log('[IndexDB Cache]: Retrying execute transaction ...', error)),
          take(MAX_RETRY_COUNT),
        ),
      ),
      take(1),
      map((item) => item?.payload),
      catchError((error) => {
        console.error(error);
        return of(undefined);
      }),
      tap((cached) => {
        if (cached) {
          serverCacheTable
            .upsert(requestHash, { payload: cached, lastUsedTimestamp: new Date().getTime() }, transaction)
            .subscribe();
        }
      }),
    );
  };

  return from(getConnectionInstance().executeAsTransaction([TableNames.server_requests_cache], transactionSequence));
}

export function setCached<T extends CachedAPIs>(
  requestHash: string,
  response: APITypeMapping[T]['response'],
): Observable<void> {
  return getConnectionInstance()
    .table<TableNames.server_requests_cache>(TableNames.server_requests_cache)
    .upsert(requestHash, {
      payload: response,
      lastUsedTimestamp: new Date().getTime(),
    })
    .pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(RETRY_TIMEOUT),
          tap((error) => console.log('[IndexDB Cache]: Failed to retrying...', error)),
          take(MAX_RETRY_COUNT),
        ),
      ),
      catchError((error) => {
        console.log('[IndexDB Cache]: Failed to set item ', error);
        return of(error);
      }),
    );
}

export function cleanCacheLRU(): Observable<any[]> {
  return getConnectionInstance()
    .table(TableNames.server_requests_cache)
    .query(
      { field: 'lastUsedTimestamp', direction: 'prev', limit: REMOVE_LEAST_RECENT_ITEMS_ON_QUOTA_VIOLATION },
      () => true,
    );
}
