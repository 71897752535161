<div class="wp-input">
  <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
    @if (!!label) {
    <mat-label>{{ label }}</mat-label>
    }
    <div matPrefix>
      <ng-content select="wp-input-prefix"></ng-content>
    </div>
    <input
      #inputEl
      matInput
      tabindex="{{tabIndex}}"
      autocomplete="off"
      [formControl]="control"
      [errorStateMatcher]="errorStateMatcher"
      [type]="type"
      [maxlength]="maxLength"
      [id]="id"
      [readonly]="readOnly"
      (blur)="onBlurEvent()"
      (input)="onInputEvent()"
      (keyup)="keyUpEvent.emit($event)"
      (focusin)="focusInEvent.emit()"
      (focusout)="focusOutEvent.emit()"
    />

    <div matSuffix>
      <ng-content select="wp-input-suffix"></ng-content>
    </div>
    <mat-hint *ngIf="hint && !control.errors"> {{ hint }} </mat-hint>
    <mat-error *ngIf="control.invalid && control.errors?.required">
      Required field
    </mat-error>
    <mat-error *ngIf="control.invalid && control.errors?.email">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="!!control.errors?.minlength?.requiredLength">
      {{ minLengthError }}
    </mat-error>
    <mat-error
      *ngIf="customErrorMessageKey && !!control.errors && !!control.errors[customErrorMessageKey]"
    >
      {{ control.errors[customErrorMessageKey] }}
    </mat-error>
  </mat-form-field>
</div>
