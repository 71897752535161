import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/campus-location-data.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CampusApiService } from '../../services/campus-api.service';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CampusLocationsDataEffects {
  constructor(
    private actions$: Actions,
    private api: CampusApiService,
  ) {}

  /**
   * Fetch campus locations
   */
  public loadCampusLocationsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCampusLocationsRequested, fromActions.getCampusLocationsRequestedFromStudentDetails),
      mergeMap((action) => {
        const { campusId, districtId } = action;
        return this.api.getCampusLocations(campusId, districtId).pipe(
          map((res) => fromActions.getCampusLocationsSuccess({ campusId, locations: res })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.getCampusLocationsFailed({
                campusId,
                error: {
                  text: 'Failed to get campus locations',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Make location primary
   */
  public makeLocationPrimaryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.makeLocationPrimaryRequested),
      switchMap((action) => {
        const { locationId, campusId, districtId } = action;
        return this.api.makeLocationPrimary(locationId, campusId, districtId).pipe(
          map(() =>
            fromActions.makeLocationPrimarySuccess({
              locationId,
              campusId,
              districtId,
            }),
          ),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.makeLocationPrimaryFailed({
                locationId,
                campusId,
                districtId,
                error: {
                  text: 'Failed to update primary location',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Remove location
   */
  public removeLocationRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeLocationRequested),
      switchMap((action) => {
        const { locationId, campusId, districtId } = action;
        return this.api.deactivateCampusLocation(locationId, campusId, districtId).pipe(
          map(() => fromActions.removeLocationSuccess({ locationId, campusId, districtId })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.removeLocationFailed({
                locationId,
                campusId,
                districtId,
                error: {
                  text: 'Failed to deactivate pickup/dropoff location',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
