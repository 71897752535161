import { Injectable, NgZone } from '@angular/core';

import * as Rollbar from 'rollbar';
import { InMemoryCache } from '../features/drivers/utils';

@Injectable()
export class RollbarIgnoreService {
  private cache: InMemoryCache;

  constructor(private zone: NgZone) {
    this.cache = new InMemoryCache(this.zone, 240, 5);
  }

  /**
   * @return true if an item should be ignored by Rollbar
   */
  public checkIgnore(...args: Rollbar.LogArgument[]): boolean {
    const key = this.getKey(...args);
    if (!key) {
      // Some weird item that can't be cached
      return false;
    }
    if (this.cache.has(key)) {
      return true;
    }
    this.cache.set(key, true);
    return false;
  }

  /**
   * Generates `${msgLength}_${first60charsOfMsg}` key for a given item
   * @return string for valid item, otherwise undefined
   */
  private getKey(...args: Rollbar.LogArgument[]): string | undefined {
    if (!args.length) {
      return undefined;
    }

    let msg: string;

    if (args[0] instanceof Error) {
      const err = args[0] as Error;
      msg = err.message;
    } else if (typeof args[0] === 'string') {
      msg = args[0] as string;
    }

    // Whether msg is undefined or an empty string
    if (!msg) {
      return undefined;
    }

    let key: string;
    if (msg.length <= 60) {
      key = `${msg.length}_${msg}`;
    } else {
      key = `${msg.length}_${msg.slice(0, 60)}`;
    }

    return key;
  }
}
