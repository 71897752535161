import { Pipe, PipeTransform } from '@angular/core';
import { displayHHMMTimeString } from '@rootTypes/utils/common/time';
import { formattingConstants } from '@rootTypes/utils/common/date-time-fns';

@Pipe({
  name: 'displayHHMMTimeString',
})
export class DisplayHHMMTimeStringPipe implements PipeTransform {
  transform(value: string, format = formattingConstants.timeNoTimezone): string {
    return displayHHMMTimeString(value, format);
  }
}
