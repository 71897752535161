import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { firebaseUserId } from '../auth/store/selectors';
import { AuthState } from '../auth/store/reducers';
import { RollbarService } from './rollbar.service';

@Injectable()
export class RollbarSessionService {
  private subscribed: boolean;

  constructor(
    private store: Store<AuthState>,
    private rollbar: RollbarService,
  ) {}

  /**
   * Configures Rollbar with currently logged in user.
   * This method should be called once the app has been initialized.
   * (see: AppComponent)
   */
  public subscribeOnUserSession(): void {
    if (this.subscribed) {
      throw new Error('Trying to call subscribeOnUserSession() more than once');
    }

    this.store.select(firebaseUserId).subscribe((userId) => {
      // user logged in
      if (userId) {
        this.setUser(userId);
        // user logs out or there is a pending authentication
      } else {
        this.unsetUser();
      }
    });
    this.subscribed = true;
  }

  /**
   *  Set the person data to be sent with all errors of the session.
   */
  private setUser(userId: string): void {
    this.rollbar.configure({
      payload: {
        person: {
          id: userId,
        },
      },
    });
  }

  /**
   * Unsets the person data to be sent with all errors of the session
   */
  private unsetUser(): void {
    this.rollbar.configure({
      payload: {
        person: {
          id: null,
        },
      },
    });
  }
}
