import { Injectable } from '@angular/core';
import { AccountsFacade } from '../../auth/store';
import { APITypeMapping, CachedAPIs, MAX_ALLOWED_PERCENTAGE_OF_QUOTA_USE } from './cache-config';
import { catchError, debounceTime, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { cleanCacheLRU, getCached, setCached } from './cache-utils';
import { of, Subject, Observable } from 'rxjs';
import { getConnectionInstance } from './index-db';

@Injectable({ providedIn: 'root' })
export class IndexedDbCacheService {
  public addToCacheEvent$ = new Subject();
  constructor(private accountsFacade: AccountsFacade) {
    /**
     * TODO: Remove after test
     */
    // this.testCache();
    /**
     * Check indexedDb quota usage and perform LRU cache cleaning
     */
    this.addToCacheEvent$
      .pipe(
        debounceTime(2000),
        switchMap(() => getConnectionInstance().getQuotaUsePercentage()),
        catchError((err) => {
          console.error(err);
          return of(null);
        }),
        switchMap((quotaRatio) => {
          const percentage = Math.floor(quotaRatio * 1000000) / 10000;
          console.log('[IndexedDB Cache]: Quota use percentage: ', percentage);
          if (quotaRatio && percentage > MAX_ALLOWED_PERCENTAGE_OF_QUOTA_USE) {
            console.log('[IndexedDB Cache]: Quota violation. Will remove least recently used items');
            return cleanCacheLRU().pipe(
              map((removedItems) => {
                console.log('[IndexedDB Cache]: Removed ', removedItems.length, ' least recently used items');
              }),
              catchError((error) => {
                console.error('[IndexedDB Cache]: Error while cleaning cache ', error);
                return of(error);
              }),
            );
          }
          return of(undefined);
        }),
      )
      .subscribe();
  }

  public indexedDbCache<T extends CachedAPIs>(
    api: T,
    request: APITypeMapping[T]['request'],
    apiFn: (request: APITypeMapping[T]['request']) => Observable<APITypeMapping[T]['response']>,
    forceRefresh = false,
  ): Observable<APITypeMapping[T]['response']> {
    return this.accountsFacade.getFirebaseUserId$().pipe(
      take(1),
      switchMap((accountId) => {
        if (!accountId) {
          console.warn(`${api} won't be able to use caching. No account id for this user.`);
          return apiFn(request);
        }
        const apiRequestHash = api + '_' + accountId + '_' + JSON.stringify(request);
        const response$ = forceRefresh
          ? apiFn(request)
          : getCached<T>(apiRequestHash).pipe(
              switchMap((cached) => {
                if (cached) {
                  return of(cached);
                } else {
                  return apiFn(request);
                }
              }),
            );
        return response$.pipe(
          tap((response) => setCached<T>(apiRequestHash, response).subscribe()),
          tap(() => this.addToCacheEvent$.next(undefined)),
        );
      }),
    );
  }

  private async testCache(): Promise<void> {
    const apiFn = (n: number): Observable<string> => {
      return of('response ' + n).pipe(delay(2000));
    };
    const s = new Date().getTime();
    for (let i = 0; i < 4000; i++) {
      try {
        this.indexedDbCache(CachedAPIs.testCache, i, apiFn)
          .toPromise()
          .then(() => {
            const e = new Date().getTime();
            console.log('Set cache item ' + i + ' in ', (e - s) / 1000 + ' sec');
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
}
