import { Injectable, ErrorHandler } from '@angular/core';

import { RollbarService } from './rollbar.service';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    /**
     * We should inject this service even if it is not used
     * by this class, to be sure Rollbar instance has been provided already
     */
    private rollbar: RollbarService,
  ) {}

  public handleError(error: any): void {
    // This also sends error to Rollbar if it's enabled by environment
    console.error(error.originalError || error);
  }
}
