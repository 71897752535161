import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { asyncScheduler, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';
import { PortalRouteTracePoint } from 'src/app/types/entities/ride';
import { GoogleAddress, GoogleMapState, MapMarker } from '../interfaces';
import { mapCenterDefault } from '../models/map-center-default';
import { MapFocuser } from '../models/map-focuser';
import { MovementIndicatorMode, RouteDisplayMode } from '../models/map-modes';
import { MarkerMode } from '../models/map-modes/marker-mode';
import { MapBounds } from '@rootTypes';
import { greyMapThemeStyles } from '../grey-map-theme-styles';
import { CustomRouteRenderer } from '../models/custom-route-renderer';

const initialGoogleMapState: GoogleMapState = {
  map: null,
  displayTracePoints: [],
  displayDefaultDrivingRoutePoints: [],
  markers: [],
  isShowTraceHistory: false,
  isFocusOnCar: true,
  isDisplayDefaultDrivingRoute: false,
  isAnimateTrace: false,
  animateToPoint: null,
};

const unlimited = Number.MAX_SAFE_INTEGER;

@Component({
  selector: 'wp-ride-google-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
})
export class RideGoogleMapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() public resetOn: string;
  /**
   * Map options
   */
  @Input() public mapHeight = '250px';
  @Input() public mapTypeId: google.maps.MapTypeId = google.maps.MapTypeId.ROADMAP;
  @Input() public mapWidth = '405px';
  @Input() public openOn: GoogleAddress;
  @Input() public zoom = 8;
  /**
   * Marker options
   */
  @Input() public markers: MapMarker[];
  // display these trace points, if isShowTraceHistory=true
  @Input() public displayTracePoints: PortalRouteTracePoint[] = [];
  // display default driving route for these points if isDisplayDefaultDrivingRoute=true
  @Input() public displayDefaultDrivingRoutePoints: PortalRouteTracePoint[] = [];
  // should the component show displayTracePoints
  @Input() public isShowTraceHistory = false;
  // should the component show displayDefaultDrivingRoutePoints
  @Input() public isDisplayDefaultDrivingRoute = false;
  // TODO: [PORTAL-487] remove field once we have migrated to provide all points needed to draw the polyline
  // should the component get and set directions, using google maps, between the provided default driving route points
  @Input() public isCalculateDirectionsNeeded = true;
  // displays animated marker (car);
  @Input() public isAnimateTrace = false;
  // shows animated car movement to this point on each change of this param, skips first
  @Input() public animateToPoint: PortalRouteTracePoint[];
  // view ride video popup
  @Input() public isViewRideVideo: boolean;

  // consider this distance between points as a trace gap, by default - no trace gaps
  @Input() public traceGapMeters: number = unlimited;

  @Input() public fitMapOnMarkersChange = true;
  @Input() public isGreyTheme = true;
  @Input() public boundsChangedThrottleMs = 500;

  /**
   * Fires once when the map has been loaded
   */
  @Output() public loaded = new EventEmitter<void>();
  @Output() public idle = new EventEmitter<void>();

  @Output() public boundsInit = new EventEmitter<MapBounds>();
  @Output() public boundsChanged = new EventEmitter<MapBounds>();
  @Output() public viewAllVideoClicked = new EventEmitter<number>();
  @Output() public viewDriverVideoClicked = new EventEmitter<number>();

  public mapTilesLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Show driver speed, time and battery state in a popover
   */
  private showAdditionalRouteTraceInfo = wpEnvironment.showAdditionalRouteTraceInfo;
  /**
   * San Jose, California
   */
  private map: google.maps.Map;
  /**
   * Map utilities
   */
  private markerMode: MarkerMode;
  private routeDisplayMode: RouteDisplayMode;
  private movementMode: MovementIndicatorMode;
  // focuses map on car
  private mapFocuser: MapFocuser;
  // holds map state
  private state$: BehaviorSubject<GoogleMapState> = new BehaviorSubject<GoogleMapState>({ ...initialGoogleMapState });
  @ViewChild('map') private mapRef: ElementRef;

  private subscriptions: Subscription;

  constructor(
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    this.initMap();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.markers) {
      this.state$.next({
        ...this.state$.value,
        markers: changes.markers.currentValue || [],
      });
    }
    if (changes.displayTracePoints) {
      this.state$.next({
        ...this.state$.value,
        displayTracePoints: changes.displayTracePoints.currentValue || [],
      });
    }
    if (changes.displayDefaultDrivingRoutePoints) {
      this.state$.next({
        ...this.state$.value,
        displayDefaultDrivingRoutePoints: changes.displayDefaultDrivingRoutePoints.currentValue || [],
      });
    }
    if (changes.isShowTraceHistory) {
      this.state$.next({
        ...this.state$.value,
        isShowTraceHistory: changes.isShowTraceHistory.currentValue || false,
      });
    }
    if (changes.isDisplayDefaultDrivingRoute) {
      this.state$.next({
        ...this.state$.value,
        isDisplayDefaultDrivingRoute: changes.isDisplayDefaultDrivingRoute.currentValue || false,
      });
    }
    if (changes.isAnimateTrace) {
      this.state$.next({
        ...this.state$.value,
        isAnimateTrace: changes.isAnimateTrace.currentValue || false,
      });
    }
    if (changes.animateToPoint) {
      this.state$.next({
        ...this.state$.value,
        animateToPoint: changes.animateToPoint.currentValue || null,
      });
    }
    if (changes.resetOn && !changes.resetOn.isFirstChange()) {
      this.doOnReset();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
      this.movementMode?.dispose();
      this.markerMode?.dispose();
      this.routeDisplayMode?.dispose();
    }
    google.maps.event.clearListeners(this.map, 'zoom_changed');
  }

  public ngOnInit(): void {
    this.setStateSubscriptions();
    this.signalMapLoading();
    // this.setStateLogger();
  }

  public onRecenterClik(): void {
    this.markerMode.fitMapToMarkers();
  }

  private initMap(): void {
    // init map
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      center: this.openOn ? this.openOn.geometry.location : mapCenterDefault,
      clickableIcons: false,
      mapTypeId: this.mapTypeId,
      zoom: this.zoom,
      disableDefaultUI: false,
      fullscreenControl: true,
      mapTypeControlOptions: { mapTypeIds: [] },
      zoomControl: true,
      streetViewControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      styles: this.isGreyTheme ? [...greyMapThemeStyles] : undefined,
      gestureHandling: 'greedy',
    });

    // loaded listener
    google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      this.loaded.emit();
      this.state$.next({
        ...this.state$.value,
        map: this.map,
      });
      this.initMapUtilities();
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        setTimeout(() => {
          this.idle.emit();
        }, 600);
      });
    });
    google.maps.event.addListenerOnce(this.map, 'dragend', () => {
      this.setFocusMapOnCar(false);
    });

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      this.boundsInit.emit(this.getBounds());

      const innerBoundsChanged = new Subject<MapBounds>();
      const innerBoundsChangedSub = innerBoundsChanged
        .pipe(throttleTime(this.boundsChangedThrottleMs, asyncScheduler, { leading: false, trailing: true }))
        .subscribe((bounds) => {
          this.boundsChanged.emit(bounds);
        });
      this.subscriptions.add(innerBoundsChangedSub);

      google.maps.event.addListener(this.map, 'bounds_changed', () => {
        innerBoundsChanged.next(this.getBounds());
      });
    });
  }

  private doOnReset(): void {
    if (this.markerMode) {
      this.markerMode.reset();
    }
    if (this.routeDisplayMode) {
      this.routeDisplayMode.reset();
    }
    if (this.movementMode) {
      this.movementMode.reset();
    }
    this.signalMapLoading();
  }

  private signalMapLoading(): void {
    this.mapTilesLoading$.next(true);
    setTimeout(() => {
      this.mapTilesLoading$.next(false);
    }, 700);
  }

  private initMapUtilities(): void {
    // only show route trace info when display route traces

    const customRouteRenderer = new CustomRouteRenderer(
      this.state$.value.map,
      [...(this.state$.value.displayTracePoints || [])],
      this.traceGapMeters,
      this.showAdditionalRouteTraceInfo,
      this.isViewRideVideo,
      this.ngZone,
    );
    customRouteRenderer.onViewVideoClicked((type, ts) => {
      if (type === 'driver') {
        this.viewDriverVideoClicked.emit(ts);
      } else if (type === 'all') {
        this.viewAllVideoClicked.emit(ts);
      }
    });
    this.markerMode = new MarkerMode(this.state$, this.fitMapOnMarkersChange);
    this.routeDisplayMode = new RouteDisplayMode(
      this.state$,
      customRouteRenderer,
      this.traceGapMeters,
      this.showAdditionalRouteTraceInfo,
      this.isCalculateDirectionsNeeded,
    );
    this.movementMode = new MovementIndicatorMode(
      this.state$,
      customRouteRenderer,
      this.traceGapMeters,
      this.showAdditionalRouteTraceInfo,
      this.ngZone,
    );
  }

  private setStateSubscriptions(): void {
    this.subscriptions = new Subscription();
    // this.setFocusMapSubscriptions();
  }

  private setFocusMapSubscriptions(): void {
    const isFocusMapOnCar$ = this.state$.asObservable().pipe(
      map((state) => state.isFocusOnCar),
      distinctUntilChanged(),
    );

    const animateToPointChanged$ = this.state$.asObservable().pipe(
      map((state) => state.animateToPoint),
      distinctUntilChanged(),
    );

    // const mapFocusOnSubscription = isFocusMapOnCar$
    //   .pipe(
    //     switchMap((isFocusMapOnCar) => {
    //       if (isFocusMapOnCar) {
    //         return animateToPointChanged$.pipe(
    //           map((routeTrace) => routeTracePointToLatLng(routeTrace)),
    //         );
    //       } else {
    //         return of(this.map.getCenter());
    //       }
    //     }),
    //   )
    //   .subscribe((point) => {
    //     if (this.mapFocuser) {
    //       this.mapFocuser.focusOn(point);
    //     }
    //   });
    // this.subscriptions.add(mapFocusOnSubscription);
  }

  private setFocusMapOnCar(isFocusMapOnCar: boolean): void {
    this.state$.next({
      ...this.state$.value,
      isFocusOnCar: isFocusMapOnCar,
    });
  }

  /**
   * Do not remove, use for debugging
   */
  private setStateLogger(): void {
    const stateLogSub = this.state$.asObservable().subscribe((state) => {
      console.log('::Google map state changed', state);
    });
    this.subscriptions.add(stateLogSub);
  }

  private getBounds(): MapBounds {
    const bounds = this.map.getBounds();
    return {
      northEast: bounds.getNorthEast().toJSON(),
      southWest: bounds.getSouthWest().toJSON(),
    };
  }
}
