import { Injectable } from '@angular/core';
import { BaseApiService } from './services/api/base-api.service';
import * as fromApi from '.';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SessionService } from './services/api/session.service';
import { DownloadDriverDocumentApiService } from './endpoints/services/download-driver-document-api.service';
import { ExportRouteApiService, ExportRouteOptions, ExportRouteSheetData } from './services/export-route-api.service';
import { AuthProviderApiService } from './services/auth-provider-api.service';
import { IndexedDbCacheService } from './cache/indexed-db-cache.service';
import { CachedAPIs } from './cache/cache-config';
import { CharterCatalogApiService } from './services/charter-catalog-api.service';
import { GetRideRequest, GetRideResponse } from './endpoints/get-ride';
import { objectToFormData } from '@rootTypes/utils/common/object-to-form-data';
import { transformRouteIssueByDistrictProgramsResponseToLegacy } from './endpoints/get-route-issues-by-district-programs/transform-route-issue-by-district-programs-response-to-legacy';
import { transformGetUnassignedExplorerStudentsV2ByDistrictProgramsToLegacy } from './endpoints/get-unassigned-explorer-students-v2-by-district-programs/transform-get-unassigned-explorer-students-v2-by-district-programs-to-legacy';

@Injectable({ providedIn: 'root' })
export class ApiService {
  /**
   * Store last fetched route trace indices by rides here.
   * Those are needed for the flash client, to reduce route traces payload
   */
  private lastRouteTraceIndices$: BehaviorSubject<{ [rideId: string]: number }> = new BehaviorSubject<{
    [p: string]: number;
  }>({});

  private charterEndpoint = `charter/${wpEnvironment.userRole}`;
  private rideDeliveryEndpoint = `ride-delivery/${wpEnvironment.userRole}`;

  constructor(
    private baseApi: BaseApiService,
    private session: SessionService,
    private downloadDriverDocumentApi: DownloadDriverDocumentApiService,
    private exportRouteApi: ExportRouteApiService,
    private authProviderApi: AuthProviderApiService,
    private indexedDbCacheService: IndexedDbCacheService,
    private charterCatalogApi: CharterCatalogApiService,
  ) {}

  public addStudentToExplorer(
    request: fromApi.addStudentToExplorer.AddStudentToExplorerRequest,
  ): Observable<fromApi.addStudentToExplorer.AddStudentToExplorerResponse> {
    return this.baseApi.post('addStudentToExplorer', { ...request });
  }

  public adjustFinanceTransactionPricing(
    request: fromApi.adjustFinanceTransactionPricing.AdjustFinanceTransactionPricingRequest,
  ): Observable<fromApi.adjustFinanceTransactionPricing.AdjustFinanceTransactionPricingResponse> {
    return this.baseApi.post('adjustFinanceTransactionPricing', { ...request });
  }

  public approveTimeEntry(
    request: fromApi.approveTimeEntry.ApproveTimeEntryRequest,
  ): Observable<fromApi.approveTimeEntry.ApproveTimeEntryResponse> {
    return this.baseApi.post('approveTimeEntry', { ...request });
  }

  public approveRouteGroupScheduleChangeRequest(
    request: fromApi.approveRouteGroupScheduleChangeRequest.ApproveRouteGroupScheduleChangeRequest,
  ): Observable<fromApi.approveRouteGroupScheduleChangeRequest.ApproveRouteGroupScheduleChangeRequestResponse> {
    return this.baseApi.post('approveRouteGroupScheduleChangeRequest', request);
  }

  public assignActivity(
    request: fromApi.assignActivity.AssignActivityRequest,
  ): Observable<fromApi.assignActivity.AssignActivityResponse> {
    return this.baseApi.post('assignActivity', { ...request });
  }

  public assignRouteGroupOwnerDriver(
    request: fromApi.assignRouteGroupOwnerDriver.AssignRouteGroupOwnerDriverRequest,
  ): Observable<fromApi.assignRouteGroupOwnerDriver.AssignRouteGroupOwnerDriverResponse> {
    return this.baseApi.post('assignRouteGroupOwnerDriver', { ...request });
  }

  public calculateExplorerRouteGroupsWeekdaysBundle(
    request: fromApi.calculateExplorerRouteGroupsWeekdaysBundle.CalculateExplorerRouteGroupsWeekdaysBundleRequest,
  ): Observable<fromApi.calculateExplorerRouteGroupsWeekdaysBundle.CalculateExplorerRouteGroupsWeekdaysBundleResponse> {
    return this.baseApi.post('calculateExplorerRouteGroupsWeekdaysBundle', request);
  }

  public calculateFinanceTransactionPricing(
    request: fromApi.calculateFinanceTransactionPricing.CalculateFinanceTransactionPricingRequest,
  ): Observable<fromApi.calculateFinanceTransactionPricing.CalculateFinanceTransactionPricingResponse> {
    return this.baseApi.post('calculateFinanceTransactionPricing', request);
  }

  public calculateRevisedExplorerRouteStopTimes(
    request: fromApi.calculateRevisedExplorerRouteStopTimes.CalculateRevisedExplorerRouteStopTimesRequest,
  ): Observable<fromApi.calculateRevisedExplorerRouteStopTimes.CalculateRevisedExplorerRouteStopTimesResponse> {
    return this.baseApi.post('calculateRevisedExplorerRouteStopTimes', request);
  }

  public cancelActivity(
    request: fromApi.cancelActivity.CancelActivityRequest,
  ): Observable<fromApi.cancelActivity.CancelActivityResponse> {
    return this.baseApi.post('cancelActivity', request);
  }

  public cancelLatestExplorerRouteChanges(
    request: fromApi.cancelLatestExplorerRouteChanges.CancelLatestExplorerRouteChangesRequest,
  ): Observable<fromApi.cancelLatestExplorerRouteChanges.CancelLatestExplorerRouteChangesResponse> {
    return this.baseApi.post('cancelLatestExplorerRouteChanges', request);
  }

  public createOrUpdateDriverPin(
    request: fromApi.createOrUpdateDriverPin.CreatePinRequest,
  ): Observable<fromApi.createOrUpdateDriverPin.CreatePinResponse> {
    return this.baseApi.post('createOrUpdateDriverPin', { ...request });
  }

  public createTimeEntry(
    request: fromApi.createTimeEntry.CreateTimeEntryRequest,
  ): Observable<fromApi.createTimeEntry.CreateTimeEntryResponse> {
    return this.baseApi.post('createTimeEntry', request);
  }

  public createDriverBusReport(
    request: fromApi.createDriverBusReport.CreateDriverBusReportRequest,
  ): Observable<fromApi.createDriverBusReport.CreateDriverBusReportResponse> {
    return this.baseApi.post('createDriverBusReport', request);
  }

  public createStudent(
    request: fromApi.createStudent.CreateStudentRequest,
  ): Observable<fromApi.createStudent.CreateStudentResponse> {
    return this.baseApi.post('createStudentV2', request);
  }

  public clockOutActivity(
    request: fromApi.clockOutActivity.ClockOutActivityRequest,
  ): Observable<fromApi.clockOutActivity.ClockOutActivityResponse> {
    return this.baseApi.post('clockOutActivity', request);
  }

  public createActivity(
    request: fromApi.createActivity.CreateActivityRequest,
  ): Observable<fromApi.createActivity.CreateActivityResponse> {
    return this.baseApi.post('createActivity', request);
  }

  public createRouteGroupScheduleChangeRequest(
    request: fromApi.createRouteGroupScheduleChangeRequest.CreateRouteGroupScheduleChangeRequest,
  ): Observable<fromApi.createRouteGroupScheduleChangeRequest.CreateRouteGroupScheduleChangeRequestResponse> {
    return this.baseApi.post('createRouteGroupScheduleChangeRequest', request);
  }

  public createCampus(
    request: fromApi.createCampus.CreateCampusRequest,
  ): Observable<fromApi.createCampus.CreateCampusResponse> {
    return this.baseApi.post('createCampus', request);
  }

  public createDistrict(
    request: fromApi.createDistrict.CreateDistrictRequest,
  ): Observable<fromApi.createDistrict.CreateDistrictResponse> {
    return this.baseApi.post('createDistrict', { ...request });
  }

  public createVendorEmployee(
    request: fromApi.createVendorEmployee.CreateVendorEmployeeRequest,
  ): Observable<fromApi.createVendorEmployee.CreateVendorEmployeeResponse> {
    return this.baseApi.post('createVendorEmployee', { ...request });
  }

  public getRideBulkNotifications(
    request: fromApi.getRideBulkNotifications.GetRideBulkNotificationRequest,
  ): Observable<fromApi.getRideBulkNotifications.GetRideBulkNotificationsResponse> {
    return this.baseApi.post('getRideBulkNotifications', { ...request });
  }

  public updateStudentV2(
    request: fromApi.updateStudentV2.UpdateStudentV2Request,
  ): Observable<fromApi.updateStudentV2.UpdateStudentV2Response> {
    return this.baseApi.post('updateStudentV2', request);
  }

  public updateRouteGroupYard(
    request: fromApi.updateRouteGroupYard.UpdateRouteGroupYardRequest,
  ): Observable<fromApi.updateRouteGroupYard.UpdateRouteGroupYardResponse> {
    return this.baseApi.post('updateRouteGroupYard', request);
  }

  public updateActivity(
    request: fromApi.updateActivity.UpdateActivityRequest,
  ): Observable<fromApi.updateActivity.UpdateActivityResponse> {
    return this.baseApi.post('updateActivity', request);
  }

  public getSchoolEmployee(
    request: fromApi.getSchoolEmployee.GetSchoolEmployeeRequest,
  ): Observable<fromApi.getSchoolEmployee.GetSchoolEmployeeResponse> {
    return this.baseApi.post('getSchoolEmployee', { ...request });
  }

  public createSchoolEmployee(
    request: fromApi.createSchoolEmployee.CreateSchoolEmployeeRequest,
  ): Observable<fromApi.createSchoolEmployee.CreateSchoolEmployeeResponse> {
    return this.baseApi.post('createSchoolEmployee', { ...request });
  }

  public updateSchoolEmployee(
    request: fromApi.updateSchoolEmployee.UpdateSchoolEmployeeRequest,
  ): Observable<fromApi.updateSchoolEmployee.UpdateSchoolEmployeeResponse> {
    return this.baseApi.post('updateSchoolEmployee', { ...request });
  }

  public updateSchoolEmployeePermissions(
    request: fromApi.updateSchoolEmployeePermissions.UpdateSchoolEmployeePermissionsRequest,
  ): Observable<fromApi.updateSchoolEmployeePermissions.UpdateSchoolEmployeePermissionsResponse> {
    return this.baseApi.post('updateSchoolEmployeePermissions', { ...request });
  }

  public updateFinanceTransactionStatus(
    request: fromApi.updateFinanceTransactionStatus.UpdateFinanceTransactionStatusRequest,
  ): Observable<fromApi.updateFinanceTransactionStatus.UpdateFinanceTransactionStatusResponse> {
    return this.baseApi.post('updateFinanceTransactionStatus', { ...request });
  }

  public updateFinanceTransactionItemStatus(
    request: fromApi.updateFinanceTransactionItemStatus.UpdateFinanceTransactionItemStatusRequest,
  ): Observable<fromApi.updateFinanceTransactionItemStatus.UpdateFinanceTransactionItemStatusResponse> {
    return this.baseApi.post('updateFinanceTransactionItemStatus', { ...request });
  }

  public deactivateCampus(
    request: fromApi.deactivateCampus.DeactivateCampusRequest,
  ): Observable<fromApi.deactivateCampus.DeactivateCampusResponse> {
    return this.baseApi.post('deactivateCampus', { ...request });
  }

  public deactivateCampusCalendar(
    request: fromApi.deactivateCampusCalendar.DeactivateCampusCalendarRequest,
  ): Observable<fromApi.deactivateCampusCalendar.DeactivateCampusCalendarResponse> {
    return this.baseApi.post('deactivateCampusCalendar', { ...request });
  }

  public deactivateCampusSchedule(
    request: fromApi.deactivateCampusSchedule.DeactivateCampusScheduleRequest,
  ): Observable<fromApi.deactivateCampusSchedule.DeactivateCampusScheduleResponse> {
    return this.baseApi.post('deactivateCampusSchedule', { ...request });
  }

  public deactivateCampusLocation(
    request: fromApi.deactivateCampusLocation.DeactivateCampusLocationRequest,
  ): Observable<void> {
    return this.baseApi.post('deactivateCampusLocation', { ...request });
  }

  public deleteActivity(
    request: fromApi.deleteActivity.DeleteActivityRequest,
  ): Observable<fromApi.deleteActivity.DeleteActivityResponse> {
    return this.baseApi.post('deleteActivity', request);
  }

  public downloadDriverRfidCard(
    request: fromApi.downloadDriverRfidCard.DownloadDriverRfidCardRequest,
  ): Observable<fromApi.downloadDriverRfidCard.DownloadDriverRfidCardResponse> {
    return this.baseApi.postBlob('downloadDriverRfidCard', { ...request }).pipe(map((res) => res.data));
  }

  public downloadStudentRfidCard(
    request: fromApi.downloadStudentRfidCard.DownloadStudentRfidCardRequest,
  ): Observable<fromApi.downloadStudentRfidCard.DownloadStudentRfidCardResponse> {
    return this.baseApi.postBlob('downloadStudentRfidCard', { ...request }).pipe(map((res) => res.data));
  }

  public downloadRouteGroupSheet(
    request: fromApi.downloadRouteGroupSheet.DownloadRouteGroupSheetRequest,
  ): Observable<fromApi.downloadRouteGroupSheet.DownloadRouteGroupSheetResponse> {
    return this.baseApi.post('downloadRouteGroupSheet', { ...request });
  }

  public enrollDriverEmail(
    request: fromApi.enrollDriverEmail.EnrollDriverEmailRequest,
  ): Observable<fromApi.enrollDriverEmail.EnrollDriverEmailResponse> {
    return this.baseApi.post('enrollDriverEmail', { ...request });
  }

  public entitySearch(
    request: fromApi.entitySearch.EntitySearchRequest,
  ): Observable<fromApi.entitySearch.EntitySearchResponse> {
    return this.baseApi.post('entitySearch', { ...request });
  }

  public entityFilter(
    request: fromApi.entityFilter.EntityFilterRequest,
  ): Observable<fromApi.entityFilter.EntityFilterResponse> {
    return this.baseApi.post('entityFilter', { ...request });
  }

  public filterRouteGroupSchedule(
    request: fromApi.filterRouteGroupSchedule.FilterRouteGroupScheduleRequest,
  ): Observable<fromApi.filterRouteGroupSchedule.FilterRouteGroupScheduleResponse> {
    return this.baseApi.post('filterRouteGroupSchedule', { ...request });
  }

  public finalizeCharterTripInvoice(
    request: fromApi.finalizeCharterTripInvoice.FinalizeCharterTripInvoiceRequest,
  ): Observable<fromApi.finalizeCharterTripInvoice.FinalizeCharterTripInvoiceResponse> {
    return this.baseApi.post('finalizeCharterTripInvoice', { ...request }, this.charterEndpoint);
  }

  public getRide(request: GetRideRequest): Observable<GetRideResponse> {
    return this.baseApi.post('getRide', request, this.rideDeliveryEndpoint);
  }

  public getActivity(
    request: fromApi.getActivity.GetActivityRequest,
  ): Observable<fromApi.getActivity.GetActivityResponse> {
    return this.baseApi.post('getActivity', { ...request });
  }

  public getActivityLog(activityId: string): Observable<fromApi.getActivityLog.GetActivityLogResponse> {
    return this.baseApi.post('getActivityLog', { activityId });
  }

  public getActivityTypes(): Observable<fromApi.getActivityTypes.GetActivityTypesResponse> {
    return this.baseApi.post('getActivityTypes', {});
  }

  public getBusAddresses(
    request: fromApi.getBusAddresses.GetBusAddressesRequest,
  ): Observable<fromApi.getBusAddresses.GetBusAddressesResponse> {
    return this.baseApi.post('getBusAddresses', request);
  }

  public getAlternativeExplorerRouteGroupsForRoute(
    request: fromApi.getAlternativeExplorerRouteGroupsForRoute.GetAlternativeExplorerRouteGroupsForRouteRequest,
  ): Observable<fromApi.getAlternativeExplorerRouteGroupsForRoute.GetAlternativeExplorerRouteGroupsForRouteResponse> {
    return this.baseApi.post('getAlternativeExplorerRouteGroupsForRoute', request);
  }

  public getAddressByLocation(
    request: fromApi.getAddressByLocation.GetAddressByLocationRequest,
  ): Observable<fromApi.getAddressByLocation.GetAddressByLocationResponse> {
    return this.baseApi.post('getAddressByLocation', request);
  }

  public getDriverProfile(driverId: string): Observable<fromApi.getDriverProfile.GetDriverProfileResponse> {
    return this.baseApi.post('getDriverProfile', {
      driver_id: driverId,
      owner_id: this.session.getUserId(),
    });
  }

  public getDriverActivitiesForFinanceTransaction(
    request: fromApi.getDriverActivitiesForFinanceTransaction.GetDriverActivitiesForFinanceTransactionRequest,
  ): Observable<fromApi.getDriverActivitiesForFinanceTransaction.GetDriverActivitiesForFinanceTransactionResponse> {
    return this.baseApi.post('getDriverActivitiesForFinanceTransaction', request);
  }

  public getEndOfRideInspection(
    request: fromApi.getEndOfRideInspection.GetEndOfRideInspectionRequest,
  ): Observable<fromApi.getEndOfRideInspection.GetEndOfRideInspectionResponse> {
    return this.baseApi.post('getEndOfRideInspection', request);
  }

  public getEndOfDayInspection(
    request: fromApi.getEndOfDayInspection.GetEndOfDayInspectionRequest,
  ): Observable<fromApi.getEndOfDayInspection.GetEndOfDayInspectionResponse> {
    return this.baseApi.post('getEndOfDayInspection', request);
  }

  public getExplorerRoutes(
    request: fromApi.getExplorerRoutes.GetExplorerRoutesRequest,
  ): Observable<fromApi.getExplorerRoutes.GetExplorerRoutesResponse> {
    return this.baseApi.post('getExplorerRoutes', request);
  }

  public getExplorerRoute(
    request: fromApi.getExplorerRoute.GetExplorerRouteRequest,
  ): Observable<fromApi.getExplorerRoute.GetExplorerRouteResponse> {
    return this.baseApi.post('getExplorerRoute', request);
  }

  public getExplorerRoutePublishConfig(
    request: fromApi.getExplorerRoutePublishConfig.GetExplorerRoutePublishConfigRequest,
  ): Observable<fromApi.getExplorerRoutePublishConfig.GetExplorerRoutePublishConfigResponse> {
    return this.baseApi.post('getExplorerRoutePublishConfig', request);
  }

  public getExplorerRouteGroupStartDateOfWeek(
    request: fromApi.getExplorerRouteGroupStartDateOfWeek.GetExplorerRouteGroupStartDateOfWeekRequest,
  ): Observable<fromApi.getExplorerRouteGroupStartDateOfWeek.GetExplorerRouteGroupStartDateOfWeekResponse> {
    return this.baseApi.post('getExplorerRouteGroupStartDateOfWeek', request);
  }

  public getFinanceTransaction(
    request: fromApi.getFinanceTransaction.GetFinanceTransactionRequest,
  ): Observable<fromApi.getFinanceTransaction.GetFinanceTransactionResponse> {
    return this.baseApi.post('getFinanceTransaction', request);
  }

  public getCharterConfig(
    request: fromApi.getCharterConfig.GetCharterConfigRequest,
  ): Observable<fromApi.getCharterConfig.GetCharterConfigResponse> {
    return this.baseApi.post('getCharterConfig', request, this.charterEndpoint);
  }

  public getCharterContract(
    request: fromApi.getCharterContract.GetCharterContractRequest,
  ): Observable<fromApi.getCharterContract.GetCharterContractResponse> {
    return this.baseApi.post('getCharterContract', request, this.charterEndpoint);
  }

  public getCharterCatalog(
    request: fromApi.getCharterCatalog.GetCharterCatalogRequest,
  ): Observable<fromApi.getCharterCatalog.GetCharterCatalogResponse> {
    return from(this.charterCatalogApi.getCharterCatalog(request));
  }

  public getCharterTrip(
    request: fromApi.getCharterTrip.GetCharterTripRequest,
  ): Observable<fromApi.getCharterTrip.GetCharterTripResponse> {
    return this.baseApi.post('getCharterTrip', request, this.charterEndpoint);
  }

  public getCharterTripFinalInvoice(
    request: fromApi.getCharterTripFinalInvoice.GetCharterTripFinalInvoiceRequest,
  ): Observable<fromApi.getCharterTripFinalInvoice.GetCharterTripFinalInvoiceResponse> {
    return this.baseApi.post('getCharterTripFinalInvoice', request, this.charterEndpoint);
  }

  public getCharterTripInvoice(
    request: fromApi.getCharterTripInvoice.GetCharterTripInvoiceRequest,
  ): Observable<fromApi.getCharterTripInvoice.GetCharterTripInvoiceResponse> {
    return this.baseApi.post('getCharterTripInvoice', request, this.charterEndpoint);
  }

  public getCharterRide(
    request: fromApi.getCharterRide.GetCharterRideRequest,
  ): Observable<fromApi.getCharterRide.GetCharterRideResponse> {
    return this.baseApi.post('getCharterRide', request, this.charterEndpoint);
  }

  public getCharterTripInvoiceHistory(
    request: fromApi.getCharterTripInvoiceHistory.GetCharterTripInvoiceHistoryRequest,
  ): Observable<fromApi.getCharterTripInvoiceHistory.GetCharterTripInvoiceHistoryResponse> {
    return this.baseApi.post('getCharterTripInvoiceHistory', request, this.charterEndpoint);
  }

  public getCharterTripItinerary(
    request: fromApi.getCharterTripItinerary.GetCharterTripItineraryRequest,
  ): Observable<fromApi.getCharterTripItinerary.GetCharterTripItineraryResponse> {
    return this.baseApi.post('getCharterTripItinerary', request, this.charterEndpoint);
  }

  public getCharterTripChangeHistory(
    request: fromApi.getCharterTripChangeHistory.GetCharterTripChangeHistoryRequest,
  ): Observable<fromApi.getCharterTripChangeHistory.GetCharterTripChangeHistoryResponse> {
    return this.baseApi.post('getCharterTripChangeHistory', request, this.charterEndpoint);
  }

  public getCharterTripQuote(
    request: fromApi.getCharterTripQuote.GetCharterTripQuoteRequest,
  ): Observable<fromApi.getCharterTripQuote.GetCharterTripQuoteResponse> {
    return this.baseApi.post('getCharterTripQuote', request, this.charterEndpoint);
  }

  public getCharterTripQuotedInvoice(
    request: fromApi.getCharterTripQuotedInvoice.GetCharterTripQuotedInvoiceRequest,
  ): Observable<fromApi.getCharterTripQuotedInvoice.GetCharterTripQuotedInvoiceResponse> {
    return this.baseApi.post('getCharterTripQuotedInvoice', request, this.charterEndpoint);
  }

  public getCurrentRouteGroupContract(
    request: fromApi.getCurrentRouteGroupContract.GetCurrentRouteGroupContractRequest,
  ): Observable<fromApi.getCurrentRouteGroupContract.GetCurrentRouteGroupContractResponse> {
    return this.baseApi.post('getCurrentRouteGroupContract', request);
  }

  public getDistrict(
    request: fromApi.getDistrict.GetDistrictRequest,
  ): Observable<fromApi.getDistrict.GetDistrictResponse> {
    return this.baseApi.post('getDistrict', {
      ...request,
    });
  }

  public getIndexOfTimestamp(
    rideId: string,
    timestamp: number,
  ): Observable<fromApi.getIndexOfTimestamp.GetIndexOfTimestampResponse> {
    return this.baseApi
      .post('getIndexOfTimestamp', { rideId, timestamp })
      .pipe(map((resp) => (resp && typeof resp === 'number' ? resp : null)));
  }

  public getStudentV2(
    request: fromApi.getStudentV2.GetStudentV2Request,
  ): Observable<fromApi.getStudentV2.GetStudentV2Response> {
    return this.baseApi.post('getStudentV2', request);
  }

  public getImportedRouteByName(
    request: fromApi.getImportedRouteByName.GetImportedRouteByNameRequest,
  ): Observable<fromApi.getImportedRouteByName.GetImportedRouteByNameResponse> {
    return this.baseApi.post('getImportedRouteByName', request);
  }

  public getImportedRoutesByRouteGroup(
    request: fromApi.getImportedRoutesByRouteGroup.GetImportedRoutesByRouteGroupRequest,
  ): Observable<fromApi.getImportedRoutesByRouteGroup.GetImportedRoutesByRouteGroupResponse> {
    return this.baseApi.post('getImportedRoutesByRouteGroup', request);
  }

  public getStudentVerificationStatus(
    studentId: string,
  ): Observable<fromApi.getStudentVerificationStatus.GetVerificationStatusResponse> {
    return this.baseApi.post('getStudentVerificationStatus', { studentId }, `verify/${wpEnvironment.userRole}`);
  }

  public getSecuritySettings(userId: string): Observable<fromApi.getSecuritySettings.GetSecuritySettingsResponse> {
    return this.baseApi.post('getSecuritySettings', { userId });
  }

  public getLastRouteTrace(rideId: string): Observable<fromApi.getLastRouteTrace.GetLastRouteTraceResponse> {
    return this.baseApi
      .post('getLastRouteTrace', { rideId })
      .pipe(map((resp) => (resp && typeof resp === 'object' ? resp : null)));
  }

  public getRouteDetails(routeId: string): Observable<fromApi.getRouteDetails.GetRouteDetailsResponse> {
    const dataObj = {
      owner_id: this.session.getUserId(),
      route_id: routeId,
    };
    return this.baseApi.post('getRouteDetails', dataObj);
  }

  public getRoute(request: fromApi.getRoute.GetRouteRequest): Observable<fromApi.getRoute.GetRouteResponse> {
    return this.baseApi.post('getRoute', request);
  }

  public getRouteTraces(
    request: fromApi.getRouteTraces.GetRouteTracesRequest,
  ): Observable<fromApi.getRouteTraces.GetRouteTracesResponse> {
    return this.baseApi.post('getRouteTraces', { ...request }).pipe(
      // store last route trace index
      tap((res) => {
        const { rideId } = request;
        this.lastRouteTraceIndices$.next({
          ...this.lastRouteTraceIndices$.value,
          [rideId]: (request.skip || 0) + res.traces.length,
        });
      }),
      map((resp: fromApi.getRouteTraces.GetRouteTracesResponse) => {
        if (!(resp && resp.traces)) {
          return resp;
        }
        const { traces } = resp;
        const start = request.skip || 0;
        traces.forEach((t, index) => (t.index = start + index));
        return resp;
      }),
    );
  }

  public getRouteTracesForRideId(
    request: fromApi.getRouteTracesForRideId.GetRouteTracesForRideIdRequest,
  ): Observable<fromApi.getRouteTracesForRideId.GetRouteTracesForRideIdResponse> {
    return this.baseApi.post('getRouteTracesForRideId', { ...request }, `route-trace/${wpEnvironment.userRole}`).pipe(
      tap((res) => {
        const { rideId } = request;
        const { lastIndex } = res;
        this.lastRouteTraceIndices$.next({
          ...this.lastRouteTraceIndices$.value,
          [rideId]: lastIndex,
        });
      }),
    );
  }

  /**
   * Not a real API, but used throughout the app
   * combines several real APIs
   */
  public getRouteTracesExtended(
    request: fromApi.getRouteTracesExtended.ExtendedGetRouteTracesRequest,
  ): Observable<fromApi.getRouteTracesExtended.ExtendedGetRouteTracesResponse> {
    const { rideId, limit, skip, startFromTimestamp, endAtTimestamp } = request;
    const startFromIndex$ = startFromTimestamp ? this.getIndexOfTimestamp(rideId, startFromTimestamp) : of(null);
    const endAtIndex$ = endAtTimestamp ? this.getIndexOfTimestamp(rideId, endAtTimestamp) : of(null);

    return combineLatest([startFromIndex$, endAtIndex$]).pipe(
      switchMap(([startAtIndex, endAtIndex]) => {
        // if getting route trace points after timestamp, skip should be incremented by one
        const startAtIndexIncremented = startAtIndex ? startAtIndex + 1 : 0;
        const targetSkip = startAtIndexIncremented || skip || 0;
        const targetLimit = endAtIndex ? endAtIndex - (startAtIndex || 0) : limit || null;
        return this.getRouteTraces({
          rideId,
          skip: targetSkip,
          limit: targetLimit,
        }).pipe(
          map((originalResp) => {
            return {
              ...originalResp,
              startIndex: startAtIndex !== null ? targetSkip : undefined,
              endIndex: endAtIndex || undefined,
            } as fromApi.getRouteTracesExtended.ExtendedGetRouteTracesResponse;
          }),
        );
      }),
    );
  }

  public getRouteStudent(
    request: fromApi.getRouteStudent.GetRouteStudentRequest,
  ): Observable<fromApi.getRouteStudent.GetRouteStudentResponse> {
    return this.baseApi.post('getRouteStudent', request);
  }

  public getRouteCalendar(routeId: string): Observable<fromApi.getRouteCalendar.GetRouteCalendarResponse> {
    return this.baseApi.post('getRouteCalendar', { routeId });
  }

  public getRouteCalendarV2(
    request: fromApi.getRouteCalendarV2.GetRouteCalendarRequestV2,
  ): Observable<fromApi.getRouteCalendarV2.GetRouteCalendarV2Response> {
    return this.baseApi.post('getRouteCalendarV2', request);
  }

  public updateRouteCalendar(
    request: fromApi.updateRouteCalendar.UpdateRouteCalendarRequest,
  ): Observable<fromApi.updateRouteCalendar.UpdateRouteCalendarResponse> {
    return this.baseApi.post('updateRouteCalendar', request);
  }

  public getRouteGroup(
    request: fromApi.getRouteGroup.GetRouteGroupRequest,
  ): Observable<fromApi.getRouteGroup.GetRouteGroupResponse> {
    return this.baseApi.post('getRouteGroup', request);
  }

  public getRouteGroupOwnerHistory(
    request: fromApi.getRouteGroupOwnerHistory.GetRouteGroupOwnerHistoryRequest,
  ): Observable<fromApi.getRouteGroupOwnerHistory.GetRouteGroupOwnerHistoryResponse> {
    return this.baseApi.post('getRouteGroupOwnerHistory', request);
  }

  public getRouteGroupScheduleChangeRequest(
    request: fromApi.getRouteGroupScheduleChangeRequest.GetRouteGroupScheduleChangeRequestRequest,
  ): Observable<fromApi.getRouteGroupScheduleChangeRequest.GetRouteGroupScheduleChangeRequestResponse> {
    return this.baseApi.post('getRouteGroupScheduleChangeRequest', request);
  }

  public getRouteGroupScheduleChangeRequestConfig(
    request: fromApi.getRouteGroupScheduleChangeRequestConfig.GetRouteGroupScheduleChangeRequestConfigRequest,
  ): Observable<fromApi.getRouteGroupScheduleChangeRequestConfig.GetRouteGroupScheduleChangeRequestConfigResponse> {
    return this.baseApi.post('getRouteGroupScheduleChangeRequestConfig', request);
  }

  public getRouteGroupContractHistory(
    request: fromApi.getRouteGroupContractHistory.GetRouteGroupContractHistoryRequest,
  ): Observable<fromApi.getRouteGroupContractHistory.GetRouteGroupContractHistoryResponse> {
    return this.baseApi.post('getRouteGroupContractHistory', request);
  }

  public getRouteGroupContractNumberList(
    request: fromApi.getRouteGroupContractNumberList.GetRouteGroupContractNumberListRequest,
  ): Observable<fromApi.getRouteGroupContractNumberList.GetRouteGroupContractNumberListResponse> {
    return this.baseApi.post('getRouteGroupContractNumberList', request);
  }

  public getRouteNotificationStudentsStatus(
    request: fromApi.getRouteNotificationStudentsStatus.GetRouteNotificationStudentsStatusRequest,
  ): Observable<fromApi.getRouteNotificationStudentsStatus.GetRouteNotificationStudentsStatusResponse> {
    return this.baseApi.post('getRouteNotificationStudentsStatus', request);
  }

  public getRouteGroupDriverAssignments(
    request: fromApi.getRouteGroupDriverAssignments.GetRouteGroupDriverAssignmentsRequest,
  ): Observable<fromApi.getRouteGroupDriverAssignments.GetRouteGroupDriverAssignmentsResponse> {
    return this.baseApi.post('getRouteGroupDriverAssignments', request);
  }

  public getRouteGroupActivityPaymentConfigs(
    request: fromApi.getRouteGroupActivityPaymentConfigs.GetRouteGroupActivityPaymentConfigsRequest,
  ): Observable<fromApi.getRouteGroupActivityPaymentConfigs.GetRouteGroupActivityPaymentConfigsResponse> {
    return this.baseApi.post('getRouteGroupActivityPaymentConfigs', request);
  }

  public syncDriverActivitiesWithChr(
    request: fromApi.syncDriverActivitiesWithChr.SyncDriverActivitiesWithChrRequest,
  ): Observable<fromApi.syncDriverActivitiesWithChr.SyncDriverActivitiesWithChrResponse> {
    return this.baseApi.post('syncDriverActivitiesWithChr', request);
  }

  public syncRoutesToRouteExplorer(
    request: fromApi.syncRoutesToRouteExplorer.SyncRoutesToRouteExplorerRequest,
  ): Observable<fromApi.syncRoutesToRouteExplorer.SyncRoutesToRouteExplorerResponse> {
    return this.baseApi.post('syncRoutesToRouteExplorer', request);
  }

  public syncYardLastPayPeriodActivitiesWithChr(
    request: fromApi.syncYardLastPayPeriodActivitiesWithChr.SyncYardLastPayPeriodActivitiesWithChrRequest,
  ): Observable<fromApi.syncYardLastPayPeriodActivitiesWithChr.SyncYardLastPayPeriodActivitiesWithChrResponse> {
    return this.baseApi.post('syncYardLastPayPeriodActivitiesWithChr', request);
  }

  public setRouteGroupActivityPaymentConfigs(
    request: fromApi.setRouteGroupActivityPaymentConfigs.SetRouteGroupActivityPaymentConfigsRequest,
  ): Observable<fromApi.setRouteGroupActivityPaymentConfigs.SetRouteGroupActivityPaymentConfigsResponse> {
    return this.baseApi.post('setRouteGroupActivityPaymentConfig', request);
  }

  public manageRouteGroupDriverAssignment(
    request: fromApi.manageRouteGroupDriverAssignment.ManageRouteGroupDriverAssignmentRequest,
  ): Observable<fromApi.manageRouteGroupDriverAssignment.ManageRouteGroupDriverAssignmentResponse> {
    return this.baseApi.post('manageRouteGroupDriverAssignments', request);
  }

  public modifyExplorerRouteStopTimes(
    request: fromApi.modifyExplorerRouteStopTimes.ModifyExplorerRouteStopTimesRequest,
  ): Observable<fromApi.modifyExplorerRouteStopTimes.ModifyExplorerRouteStopTimesResponse> {
    return this.baseApi.post('modifyExplorerRouteStopTimes', request);
  }

  public notifyRouteStudents(
    request: fromApi.notifyRouteStudents.NotifyRouteStudentsRequest,
  ): Observable<fromApi.notifyRouteStudents.NotifyRouteStudentsResponse> {
    return this.baseApi.post('notifyRouteStudents', request);
  }

  public getRider(riderId: string): Observable<fromApi.getRiders.GetRidersResponse> {
    const dataObj = {
      rider_id: riderId,
      owner_id: this.session.getUserId(),
    };
    return this.baseApi.post('getRiders', dataObj);
  }

  public reInstateRouteOriginalExplorerRouteGroup(
    request: fromApi.reInstateRouteOriginalExplorerRouteGroup.ReInstateRouteOriginalExplorerRouteGroupRequest,
  ): Observable<fromApi.reInstateRouteOriginalExplorerRouteGroup.ReInstateRouteOriginalExplorerRouteGroupResponse> {
    return this.baseApi.post('reInstateRouteOriginalExplorerRouteGroup', { ...request });
  }

  public revertToPublishedRoute(
    request: fromApi.revertToPublishedRoute.ReturnToPublishedRouteRequest,
  ): Observable<fromApi.revertToPublishedRoute.ReturnToPublishedRouteResponse> {
    return this.baseApi.post('revertToPublishedRoute', { ...request });
  }

  public requestTwoFactorCode(
    request: fromApi.requestTwoFactorCode.RequestTwoFactorCodeRequest,
  ): Observable<fromApi.requestTwoFactorCode.RequestTwoFactorCodeResponse> {
    return this.baseApi.post('requestTwoFactorCode', { ...request });
  }

  public rejectTimeEntry(
    request: fromApi.rejectTimeEntry.RejectTimeEntryRequest,
  ): Observable<fromApi.rejectTimeEntry.RejectTimeEntryResponse> {
    return this.baseApi.post('rejectTimeEntry', { ...request });
  }

  public rides<T>(request: T): Observable<fromApi.rides.RideFilterResponse> {
    return this.baseApi.post('rides', { ...request });
  }

  public routes(request: fromApi.routes.RouteFilterRequest): Observable<fromApi.routes.RouteFilterResponse> {
    return this.baseApi.post('routes', { ...request });
  }

  public setRouteGroupActivitySyncFlag(
    request: fromApi.setRouteGroupActivitySyncFlag.SetRouteGroupActivitySyncFlagRequest,
  ): Observable<fromApi.setRouteGroupActivitySyncFlag.SetRouteGroupActivitySyncFlagResponse> {
    return this.baseApi.post('setRouteGroupActivitySyncFlag', { ...request });
  }

  public getUpsertStudentForExternalId(
    request: fromApi.getUpsertStudentForExternalId.GetUpsertStudentForExternalIdRequest,
  ): Observable<fromApi.getUpsertStudentForExternalId.GetUpsertStudentForExternalIdResponse> {
    return this.baseApi.post('getStudentByExternalStudentId', { ...request });
  }

  public getUserProfile(
    request: fromApi.getUserProfile.GetUserProfileRequest,
  ): Observable<fromApi.getUserProfile.GetUserProfileResponse> {
    return this.baseApi.post('getUserProfile', { ...request });
  }

  public getYard(request: fromApi.getYard.GetYardRequest): Observable<fromApi.getYard.GetYardResponse> {
    return this.baseApi.post('getYard', { ...request });
  }

  public getVendorEmployee(
    request: fromApi.getVendorEmployee.GetVendorEmployeeRequest,
  ): Observable<fromApi.getVendorEmployee.GetVendorEmployeeResponse> {
    return this.baseApi.post('getVendorEmployee', { ...request });
  }

  /**
   * Called on page load OR immediately after login. Returns current user’s selection.
   */
  public getAccount(): Observable<fromApi.account.GetAccountResponse> {
    return this.baseApi.post('getAccount');
  }

  /**
   * Returns the data to show the available accounts to choose from
   * @param accountsPreset for testing purposes
   */
  public getAccounts(accountsPreset?: string): Observable<fromApi.account.GetAccountsResponse> {
    const params = accountsPreset ? { accountsPreset } : {};
    return this.baseApi.post('getAccounts', params);
  }

  public getUSNationalHolidays(
    request: fromApi.getUSNationalHolidays.GetUSNationalHolidaysRequest,
  ): Observable<fromApi.getUSNationalHolidays.GetUSNationalHolidaysResponse> {
    return this.baseApi.post('getUSNationalHolidays', { ...request });
  }

  /**
   * Selects the account and sub-account
   */
  public setAccount(request: fromApi.account.SetAccountRequest): Observable<fromApi.account.SetAccountResponse> {
    return this.baseApi.post('setAccount', request);
  }

  public sendRideBulkNotificationToParents(
    request: fromApi.sendRideBulkNotificationToParents.SendRideBulkNotificationToParentsRequest,
  ): Observable<fromApi.sendRideBulkNotificationToParents.SendRideBulkNotificationToParentsResponse> {
    return this.baseApi.post('sendRideBulkNotificationToParents', request);
  }

  public getProfile(): Observable<fromApi.getProfile.GetProfileResponse> {
    return this.baseApi.post('getProfile');
  }

  public updateProfile(
    request: fromApi.updateProfile.UpdateProfileRequest,
  ): Observable<fromApi.updateProfile.UpdateProfileResponse> {
    return this.baseApi.post('updateProfile', request);
  }

  public createPasswordResetRequest(
    request: fromApi.passwordReset.CreatePasswordResetRequestReq,
  ): Promise<fromApi.passwordReset.CreatePasswordResetRequestRes> {
    return this.baseApi.post('createPasswordResetRequest', request, 'admin').toPromise();
  }

  public recalculateExplorerRoute(
    request: fromApi.recalculateExplorerRoute.RecalculateExplorerRouteRequest,
  ): Observable<fromApi.recalculateExplorerRoute.RecalculateExplorerRouteResponse> {
    return this.baseApi.post('recalculateExplorerRouteV2', request);
  }

  public removeStudentsFromExplorer(
    request: fromApi.removeStudentsFromExplorer.RemoveStudentsFromExplorerRequest,
  ): Observable<fromApi.removeStudentsFromExplorer.RemoveStudentsFromExplorerResponse> {
    return this.baseApi.post('removeStudentsFromExplorer', request);
  }

  public removeRouteFromExplorerRouteGroup(
    request: fromApi.removeRouteFromExplorerRouteGroup.RemoveRouteFromExplorerRouteGroupRequest,
  ): Observable<fromApi.removeRouteFromExplorerRouteGroup.RemoveRouteFromExplorerRouteGroupResponse> {
    return this.baseApi.post('removeRouteFromExplorerRouteGroup', request);
  }

  public setPasswordForToken(
    request: fromApi.passwordReset.SetPasswordForTokenRequest,
  ): Observable<fromApi.passwordReset.SetPasswordForTokenResponse> {
    return this.baseApi.post('setPasswordForToken', request, 'guest');
  }

  public getYardPayPeriodToSync(
    request: fromApi.getYardPayPeriodToSync.GetYardPayPeriodToSyncRequest,
  ): Observable<fromApi.getYardPayPeriodToSync.GetYardPayPeriodToSyncResponse> {
    return this.baseApi.post('getYardPayPeriodToSync', request);
  }

  public getYards(request: fromApi.getYards.GetYardsRequest): Observable<fromApi.getYards.GetYardsResponse> {
    return this.baseApi.post('getYards', request);
  }

  public getCampus(request: fromApi.getCampus.GetCampusRequest): Observable<fromApi.getCampus.GetCampusResponse> {
    return this.baseApi.post('getCampus', request);
  }

  public getCreateActivityUIConfigs(
    request: fromApi.getCreateActivityUiConfigs.GetCreateActivityUiConfigsRequest,
  ): Observable<fromApi.getCreateActivityUiConfigs.GetCreateActivityUiConfigsResponse> {
    return this.baseApi.post('getCreateActivityUIConfigs', request);
  }

  public partialCompleteActivity(
    request: fromApi.partialCompleteActivity.PartialCompleteActivityRequest,
  ): Observable<fromApi.partialCompleteActivity.PartialCompleteActivityResponse> {
    return this.baseApi.post('partialCompleteActivity', request);
  }

  public publishExplorerRoute(
    request: fromApi.publishExplorerRoute.PublishExplorerRouteRequest,
  ): Observable<fromApi.publishExplorerRoute.PublishExplorerRouteResponse> {
    return this.baseApi.post('publishExplorerRoute', request);
  }

  public getCampusCalendars(
    campusId: string,
    districtId: string,
  ): Observable<fromApi.getCampusCalendars.GetCampusCalendarsResponse> {
    return this.baseApi.post('getCampusCalendars', { campusId, districtId }).pipe(map((res) => res?.calendars || []));
  }

  public getCampusLocations(
    campusId: string,
    districtId: string,
  ): Observable<fromApi.getCampusLocations.GetCampusLocationsResponse> {
    return this.baseApi.post('getCampusLocations', { campusId, districtId });
  }

  /**
   * Gets a list of check for driver, vehicles, campuses etc
   */
  public getChecks(request: fromApi.getChecks.GetChecksRequest): Observable<fromApi.getChecks.GetChecksResponse> {
    return this.baseApi.post('getChecks', request);
  }

  public getDriver(request: fromApi.getDriver.GetDriverRequest): Observable<fromApi.getDriver.GetDriverResponse> {
    return this.baseApi.post('getDriver', request);
  }

  public getDriverPayPeriod(
    request: fromApi.getDriverPayPeriod.GetDriverPayPeriodRequest,
  ): Observable<fromApi.getDriverPayPeriod.GetDriverPayPeriodResponse> {
    return this.baseApi.post('getDriverPayPeriod', request);
  }

  public createDriver(
    request: fromApi.createDriver.CreateDriverRequest,
  ): Observable<fromApi.createDriver.CreateDriverResponse> {
    return this.baseApi.post('createDriver', request);
  }

  public resetDriverPassword(
    request: fromApi.resetPassword.ResetPasswordRequest,
  ): Observable<fromApi.resetPassword.ResetPasswordResponse> {
    return this.baseApi.post('resetDriverPassword', request);
  }

  public resetPasswordByEmail(
    request: fromApi.resetPasswordByEmail.ResetPasswordByEmailRequest,
  ): Observable<fromApi.resetPasswordByEmail.ResetPasswordByEmailResponse> {
    return this.baseApi.post('resetPasswordByEmail', request, 'guest');
  }

  public updateDriver(
    request: fromApi.updateDriver.UpdateDriverRequest,
  ): Observable<fromApi.updateDriver.UpdateDriverResponse> {
    return this.baseApi.post('updateDriver', request);
  }

  public getVendorProfile(
    request: fromApi.getVendorProfile.GetVendorProfileRequest,
  ): Observable<fromApi.getVendorProfile.GetVendorProfileResponse> {
    return this.baseApi.post('getVendorProfile', request);
  }

  public getVehicle(request: fromApi.getVehicle.GetVehicleRequest): Observable<fromApi.getVehicle.GetVehicleResponse> {
    return this.baseApi.post('getVehicle', request);
  }

  public createVehicle(
    request: fromApi.createVehicle.CreateVehicleRequest,
  ): Observable<fromApi.createVehicle.CreateVehicleResponse> {
    return this.baseApi.post('createVehicle', request);
  }

  public unEnrollDriverPin(
    request: fromApi.unEnrollDriverPin.UnEnrollDriverPinRequest,
  ): Observable<fromApi.unEnrollDriverPin.UnEnrollDriverPinResponse> {
    return this.baseApi.post('unEnrollDriverPin', { ...request });
  }

  public unEnrollDriverEmail(
    request: fromApi.unEnrollDriverEmail.UnEnrollDriverEmailRequest,
  ): Observable<fromApi.unEnrollDriverEmail.UnEnrollDriverEmailResponse> {
    return this.baseApi.post('unEnrollDriverEmail', { ...request });
  }

  public unAssignRouteGroupDrivers(
    request: fromApi.unAssignRouteGroupDrivers.UnassignRouteGroupDriversRequest,
  ): Observable<fromApi.unAssignRouteGroupDrivers.UnassignRouteGroupDriversResponse> {
    return this.baseApi.post('unassignRouteGroupDrivers', { ...request });
  }

  public updateCampus(
    request: fromApi.updateCampus.UpdateCampusRequest,
  ): Observable<fromApi.updateCampus.UpdateCampusResponse> {
    return this.baseApi.post('updateCampusV2', request);
  }

  public updateDistrict(
    request: fromApi.updateDistrict.UpdateDistrictRequest,
  ): Observable<fromApi.updateDistrict.UpdateDistrictResponse> {
    return this.baseApi.post('updateDistrict', { ...request });
  }

  public updateDriverEmail(
    request: fromApi.updateDriverEmail.UpdateDriverEmailRequest,
  ): Observable<fromApi.updateDriverEmail.UpdateDriverEmailResponse> {
    return this.baseApi.post('updateDriverEmail', { ...request });
  }

  public updateVendorEmployee(
    request: fromApi.updateVendorEmployee.UpdateVendorEmployeeRequest,
  ): Observable<fromApi.updateVendorEmployee.UpdateVendorEmployeeResponse> {
    return this.baseApi.post('updateVendorEmployee', { ...request });
  }

  public updateVendorEmployeePermissions(
    request: fromApi.updateVendorEmployeePermissions.UpdateVendorEmployeePermissionsRequest,
  ): Observable<fromApi.updateVendorEmployeePermissions.UpdateVendorEmployeePermissionsResponse> {
    return this.baseApi.post('updateVendorEmployeePermissions', { ...request });
  }

  public updateVehicle(
    request: fromApi.updateVehicle.UpdateVehicleRequest,
  ): Observable<fromApi.updateVehicle.UpdateVehicleResponse> {
    return this.baseApi.post('updateVehicle', request);
  }

  public upsertCampusCalendar(
    request: fromApi.upsertCampusCalendar.UpsertCampusCalendarRequest,
  ): Observable<fromApi.upsertCampusCalendar.UpsertCampusCalendarResponse> {
    return this.baseApi.post('upsertCampusCalendar', request);
  }

  public upsertCampusLocation(
    request: fromApi.upsertCampusLocation.UpsertCampusLocationRequest,
  ): Observable<fromApi.upsertCampusLocation.UpsertCampusLocationResponse> {
    return this.baseApi.post('upsertCampusLocation', request);
  }

  public upsertDBRConfigReceiverOrders(
    request: fromApi.upsertDBRConfigReceiverOrders.UpsertDBRConfigReceiverOrdersRequest,
  ): Observable<fromApi.upsertDBRConfigReceiverOrders.UpsertDBRConfigReceiverOrdersResponse> {
    return this.baseApi.post('upsertDBRConfigReceiverOrders', request);
  }

  public updateExplorerRouteStudents(
    request: fromApi.updateExplorerRouteStudents.UpdateExplorerRouteStudentsRequest,
  ): Observable<fromApi.updateExplorerRouteStudents.UpdateExplorerRouteStudentsResponse> {
    return this.baseApi.post('updateExplorerRouteStudentsV2', request);
  }

  public upsertDistrictCalendar(
    dataObj: fromApi.upsertDistrictCalendar.UpsertDistrictCalendarRequest,
  ): Observable<fromApi.upsertDistrictCalendar.UpsertDistrictCalendarResponse> {
    return this.baseApi.post('upsertDistrictCalendar', dataObj).pipe(
      map((res) => {
        return res.calendar;
      }),
    );
  }

  public upsertRouteGroupContract(
    request: fromApi.upsertRouteGroupContract.UpsertRouteGroupContractRequest,
  ): Observable<fromApi.upsertRouteGroupContract.UpsertRouteGroupContractResponse> {
    return this.baseApi.post('upsertRouteGroupContract', request);
  }

  public optimizeExplorerRoute(
    request: fromApi.optimizeExplorerRoute.OptimizeExplorerRouteRequest,
  ): Observable<fromApi.optimizeExplorerRoute.OptimizeExplorerRouteResponse> {
    return this.baseApi.post('optimizeExplorerRouteV2', request);
  }

  public toggleDriverPin(
    request: fromApi.toggleDriverPin.ToggleDriverPinRequest,
  ): Observable<fromApi.toggleDriverPin.ToggleDriverPinResponse> {
    return this.baseApi.post('toggleDriverPin', request);
  }

  public toggleDriverEmail(
    request: fromApi.toggleDriverEmail.ToggleDriverEmailRequest,
  ): Observable<fromApi.toggleDriverEmail.ToggleDriverEmailResponse> {
    return this.baseApi.post('toggleDriverEmail', request);
  }

  public getAvailableServiceAreas(): Observable<fromApi.getAvailableServiceAreas.GetAvailableServiceAreasResponse> {
    return this.baseApi.post('getAvailableServiceAreas');
  }

  public downloadDriverDocument(documentId: string): Promise<string> {
    return this.downloadDriverDocumentApi.downloadDocument(documentId);
  }

  public uploadDriverDocument(
    request: fromApi.driverDocuments.UploadDriverDocumentRequest,
  ): Observable<fromApi.driverDocuments.UploadDriverDocumentResponse> {
    return this.baseApi.post('uploadDriverDocument', request);
  }

  public getLatestDriverDocuments(
    request: fromApi.driverDocuments.GetLatestDriverDocumentRequest,
  ): Observable<fromApi.driverDocuments.GetLatestDriverDocumentResponse> {
    return this.baseApi.post('getLatestDriverDocuments', request);
  }

  public getDriverDocument(
    request: fromApi.driverDocuments.GetDriverDocumentRequest,
  ): Observable<fromApi.driverDocuments.GetDriverDocumentResponse> {
    return this.baseApi.post('getDriverDocument', request);
  }

  public getDriverDocumentHistory(
    request: fromApi.driverDocuments.GetDriverDocumentHistoryRequest,
  ): Observable<fromApi.driverDocuments.GetDriverDocumentHistoryResponse> {
    return this.baseApi.post('getDriverDocumentHistory', request);
  }

  public saveExplorerRoute(
    request: fromApi.saveExplorerRoute.SaveExplorerRouteRequest,
  ): Observable<fromApi.saveExplorerRoute.SaveExplorerRouteResponse> {
    return this.baseApi.post('saveExplorerRoute', request);
  }

  public sendCharterTripInvoice(
    request: fromApi.sendCharterTripInvoice.SendCharterTripInvoiceRequest,
  ): Observable<fromApi.sendCharterTripInvoice.SendCharterTripInvoiceResponse> {
    return this.baseApi.post('sendCharterTripInvoice', request, this.charterEndpoint);
  }

  public submitDriverDocumentReviewDecision(
    request: fromApi.driverDocuments.SubmitDriverDocumentReviewDecisionRequest,
  ): Observable<fromApi.driverDocuments.SubmitDriverDocumentReviewDecisionResponse> {
    return this.baseApi.post('submitDriverDocumentReviewDecision', request);
  }

  public sendDriverDocumentRequest(
    request: fromApi.driverDocuments.SendDriverDocumentRequestRequest,
  ): Observable<fromApi.driverDocuments.SendDriverDocumentRequestResponse> {
    return this.baseApi.post('sendDriverDocumentRequest', request);
  }

  public getLastFetchedRouteTrace$(rideId: string): Observable<number> {
    return this.lastRouteTraceIndices$.asObservable().pipe(
      map((val) => val[rideId] || 0),
      distinctUntilChanged(),
    );
  }

  public createMockRideForRoute(
    request: fromApi.createMockRideForRoute.CreateMockRideForRouteRequest,
  ): Observable<fromApi.createMockRideForRoute.CreateMockRideForRouteResponse> {
    return this.baseApi.post('createMockRideForRoute', request);
  }

  public exportRoute(routeId: string, options: ExportRouteOptions): Promise<ExportRouteSheetData> {
    return this.exportRouteApi.exportRoute(routeId, options);
  }

  public submitRideAssignment(
    request: fromApi.submitRideAssignment.SubmitRideAssignmentRequest,
  ): Observable<fromApi.submitRideAssignment.SubmitRideAssignmentResponse> {
    return this.baseApi.post('submitRideAssignment', request);
  }

  public submitExplorerRouteGroupChangeForRoute(
    request: fromApi.submitExplorerRouteGroupChangeForRoute.SubmitExplorerRouteGroupChangeForRouteRequest,
  ): Observable<fromApi.submitExplorerRouteGroupChangeForRoute.SubmitExplorerRouteGroupChangeForRouteResponse> {
    return this.baseApi.post('submitExplorerRouteGroupChangeForRoute', request);
  }

  public driverStatusHistory(
    request: fromApi.driverStatusHistory.DriverStatusHistoryRequest,
  ): Observable<fromApi.driverStatusHistory.DriverStatusHistoryResponse> {
    return this.baseApi.post('driverStatusHistory', request);
  }

  public changeDriverStatus(
    request: fromApi.changeDriverStatus.ChangeDriverStatusRequest,
  ): Observable<fromApi.changeDriverStatus.ChangeDriverStatusResponse> {
    return this.baseApi.post('changeDriverStatusWithPrompt', request);
  }

  public changeCharterTripStatus(
    request: fromApi.changeCharterTripStatus.ChangeCharterTripStatusRequest,
  ): Observable<fromApi.changeCharterTripStatus.ChangeCharterTripStatusResponse> {
    return this.baseApi.post('changeCharterTripStatus', request, this.charterEndpoint);
  }

  public getAuthenticationProvider(
    request: fromApi.getAuthenticationProvider.GetAuthenticationProviderRequest,
  ): Observable<fromApi.getAuthenticationProvider.GetAuthenticationProviderResponse> {
    return this.authProviderApi.getAuthenticationProvider(request);
  }

  public getStudentChangeRequestConfig(
    request: fromApi.getStudentChangeRequestConfig.GetStudentChangeRequestConfigRequest,
  ): Observable<fromApi.getStudentChangeRequestConfig.GetStudentChangeRequestConfigResponse> {
    return this.baseApi.post('getStudentChangeRequestConfig', request);
  }

  public getStudentChangeRequestSummary(
    request: fromApi.getStudentChangeRequestSummary.GetStudentChangeRequestSummaryRequest,
  ): Observable<fromApi.getStudentChangeRequestSummary.GetStudentChangeRequestSummaryResponse> {
    return this.baseApi.post('getStudentChangeRequestSummary', request);
  }

  public getStudentChangeRequestLogs(
    request: fromApi.getStudentChangeRequestLogs.GetStudentChangeRequestLogsRequest,
  ): Observable<fromApi.getStudentChangeRequestLogs.GetStudentChangeRequestLogsResponse> {
    return this.baseApi.post('getStudentChangeRequestLogs', request);
  }

  public getPendingStudentChangeRequestsByStudent(
    request: fromApi.getPendingStudentChangeRequestsByStudent.GetPendingStudentChangeRequestsByStudentRequest,
  ): Observable<fromApi.getPendingStudentChangeRequestsByStudent.GetPendingStudentChangeRequestsByStudentResponse> {
    return this.baseApi.post('getPendingStudentChangeRequestsByStudent', request);
  }

  public entityAggregate(
    request: fromApi.entityAggregate.EntityAggregateRequest,
  ): Observable<fromApi.entityAggregate.EntityAggregateResponse> {
    return this.baseApi.post('entityAggregate', request);
  }

  public applyStudentChangeRequest(
    request: fromApi.applyStudentChangeRequest.ApplyStudentChangeRequestRequest,
  ): Observable<fromApi.applyStudentChangeRequest.ApplyStudentChangeRequestResponse> {
    return this.baseApi.post('applyStudentChangeRequest', request);
  }

  public getReportConfigs(
    request: fromApi.getReportConfigs.GetReportConfigsRequest,
  ): Observable<fromApi.getReportConfigs.GetReportConfigsResponse> {
    return this.baseApi.post('getReportConfigs', request);
  }

  public generateReport(
    request: fromApi.generateReport.GenerateReportRequest,
  ): Observable<fromApi.generateReport.GenerateReportResponse> {
    return this.baseApi.post('generateReport', request);
  }

  public validateStudentChanges(
    request: fromApi.validateStudentChanges.ValidateStudentChangesRequest,
  ): Observable<fromApi.validateStudentChanges.ValidateStudentChangesResponse> {
    return this.baseApi.post('validateStudentChanges', request);
  }

  public upsertCampusSchedule(
    request: fromApi.upsertCampusSchedule.UpsertCampusScheduleRequest,
  ): Observable<fromApi.upsertCampusSchedule.UpsertCampusScheduleResponse> {
    return this.baseApi.post('upsertCampusSchedule', request);
  }

  public getRideHistory(
    request: fromApi.getRideHistory.GetRideHistoryRequest,
  ): Observable<fromApi.getRideHistory.GetRideHistoryResponse> {
    return this.baseApi.post('getRideHistory', request);
  }

  public refreshExplorerRouteList(
    request: fromApi.refreshExplorerRouteList.RefreshExplorerRouteListRequest,
  ): Observable<fromApi.refreshExplorerRouteList.RefreshExplorerRouteListResponse> {
    return this.baseApi.post('refreshExplorerRouteList', request);
  }

  public refreshDistrictFinanceTransactions(
    request: fromApi.refreshDistrictFinanceTransactions.RefreshDistrictFinanceTransactionsRequest,
  ): Observable<fromApi.refreshDistrictFinanceTransactions.RefreshDistrictFinanceTransactionsResponse> {
    return this.baseApi.post('refreshDistrictFinanceTransactions', request);
  }

  public refreshFinanceTransaction(
    request: fromApi.refreshFinanceTransaction.RefreshFinanceTransactionRequest,
  ): Observable<fromApi.refreshFinanceTransaction.RefreshFinanceTransactionResponse> {
    return this.baseApi.post('refreshFinanceTransaction', request);
  }

  public createExplorerRoute(
    request: fromApi.createExplorerRoute.CreateExplorerRouteRequest,
  ): Observable<fromApi.createExplorerRoute.CreateExplorerRouteResponse> {
    return this.baseApi.post('createExplorerRouteV2', request);
  }

  public getProcessedStudentChangeRequest(
    request: fromApi.getProcessedStudentChangeRequest.GetProcessedStudentChangeRequestRequest,
  ): Observable<fromApi.getProcessedStudentChangeRequest.GetProcessedStudentChangeRequestResponse> {
    return this.baseApi.post('getStudentProcessedChangeRequest', request);
  }

  public entityFilterVehicleInspections(
    request: fromApi.entityFilterVehicleInspections.EntityFilterVehicleInspectionsRequest,
  ): Observable<fromApi.entityFilterVehicleInspections.EntityFilterVehicleInspectionsResponse> {
    return this.baseApi.post('entityFilter', request);
  }

  public getVehicleInspection(
    request: fromApi.getVehicleInspection.GetVehicleInspectionRequest,
  ): Observable<fromApi.getVehicleInspection.GetVehicleInspectionResponse> {
    return this.baseApi.post('getVehicleInspection', request);
  }

  public getVehicleInspectionReports(
    request: fromApi.getVehicleInspectionReports.GetVehicleInspectionReportsRequest,
  ): Observable<fromApi.getVehicleInspectionReports.GetVehicleInspectionReportsResponse> {
    return this.baseApi.postBlob('getVehicleInspectionReports', request).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  public getVehicleInspectionReportTemplate(
    request: fromApi.getVehicleInspectionReportTemplate.GetVehicleInspectionReportTemplateRequest,
  ): Observable<fromApi.getVehicleInspectionReportTemplate.GetVehicleInspectionReportTemplateResponse> {
    let endpoint = `getVehicleInspectionReportTemplate?vehicleDisplayId=${request.vehicleDisplayId}`;
    if (request.vendorId) {
      endpoint += `&vendorId=${request.vendorId}`;
    }
    return this.baseApi.getBlob(endpoint).pipe(map((res) => res.data));
  }

  public submitVehicleInspectionMechanicAssignment(
    request: fromApi.submitVehicleInspectionMechanicAssignment.SubmitVehicleInspectionMechanicAssignmentRequest,
  ): Observable<fromApi.submitVehicleInspectionMechanicAssignment.SubmitVehicleInspectionMechanicAssignmentResponse> {
    return this.baseApi.post('submitVehicleInspectionMechanicAssignment', request);
  }

  public updateVehicleInspectionMechanicReview(
    request: fromApi.updateVehicleInspectionMechanicReview.UpdateVehicleInspectionMechanicReviewRequest,
  ): Observable<fromApi.updateVehicleInspectionMechanicReview.UpdateVehicleInspectionMechanicReviewResponse> {
    return this.baseApi.post('updateVehicleInspectionMechanicReview', request);
  }

  public getRouteWaypoints(
    request: fromApi.getRouteWaypoints.GetRouteWaypointsRequest,
    forceRefresh?: boolean,
  ): Observable<fromApi.getRouteWaypoints.GetRouteWaypointsResponse> {
    const apiFn = (
      req: fromApi.getRouteWaypoints.GetRouteWaypointsRequest,
    ): Observable<fromApi.getRouteWaypoints.GetRouteWaypointsResponse> => this.baseApi.post('getRouteWaypoints', req);
    return this.indexedDbCacheService.indexedDbCache<CachedAPIs.getRouteWaypoints>(
      CachedAPIs.getRouteWaypoints,
      request,
      apiFn.bind(this),
      forceRefresh,
    );
  }

  public getMissingVehicleInspections(
    request: fromApi.getMissingVehicleInspections.GetMissingVehicleInspectionsRequest,
  ): Observable<fromApi.getMissingVehicleInspections.GetMissingVehicleInspectionsResponse> {
    return this.baseApi.post('getMissingVehicleInspections', request);
  }

  public getZumEmployee(
    request: fromApi.getZumEmployee.GetZumEmployeeRequest,
  ): Observable<fromApi.getZumEmployee.GetZumEmployeeResponse> {
    return this.baseApi.post('getZumEmployee', request);
  }

  public getActiveRides(
    request: fromApi.getActiveRides.GetActiveRidesRequest,
  ): Observable<fromApi.getActiveRides.GetActiveRidesResponse> {
    return this.baseApi.post('getActiveRides', request);
  }

  public getExplorerRouteGroups(
    request: fromApi.getExplorerRouteGroups.GetExplorerRouteGroupsRequest,
  ): Observable<fromApi.getExplorerRouteGroups.GetExplorerRouteGroupsResponse> {
    return this.baseApi.post('getExplorerRouteGroups', request);
  }

  public getExplorerRouteGroup(
    request: fromApi.getExplorerRouteGroup.GetExplorerRouteGroupRequest,
  ): Observable<fromApi.getExplorerRouteGroup.GetExplorerRouteGroupResponse> {
    return this.baseApi.post('getExplorerRouteGroup', request);
  }

  public getDriverBusReport(
    request: fromApi.getDriverBusReport.GetDriverBusReportRequest,
  ): Observable<fromApi.getDriverBusReport.GetDriverBusReportResponse> {
    return this.baseApi.post('getDriverBusReport', request);
  }

  public downloadDriverBusReportImage(
    request: fromApi.downloadDriverBusReportImage.DownloadDriverBusReportImageRequest,
  ): Observable<fromApi.downloadDriverBusReportImage.DownloadDriverBusReportImageResponse> {
    return this.baseApi.postBlob('downloadDriverBusReportImage', { ...request }).pipe(map((res) => res.data));
  }

  public updateCharterDraftFinalInvoice(
    request: fromApi.updateCharterDraftFinalInvoice.UpdateCharterDraftFinalInvoiceRequest,
  ): Observable<fromApi.updateCharterDraftFinalInvoice.UpdateCharterDraftFinalInvoiceResponse> {
    return this.baseApi.post('updateCharterDraftFinalInvoice', request, this.charterEndpoint);
  }

  public updateDriverBusReportImage(
    request: fromApi.updateDriverBusReportImage.UpdateDriverBusReportImageRequest,
  ): Observable<fromApi.updateDriverBusReportImage.UpdateDriverBusReportImageResponse> {
    return this.baseApi.post('updateDriverBusReportImage', request);
  }

  public updateDriverBusReportDetail(
    request: fromApi.updateDriverBusReportDetail.UpdateDriverBusReportDetailRequest,
  ): Observable<fromApi.updateDriverBusReportDetail.UpdateDriverBusReportDetailResponse> {
    return this.baseApi.post('updateDriverBusReportDetail', request);
  }

  public submitDriverBusReportReview(
    request: fromApi.submitDriverBusReportReview.SubmitDriverBusReportReviewRequest,
  ): Observable<fromApi.submitDriverBusReportReview.SubmitDriverBusReportReviewResponse> {
    return this.baseApi.post('submitDriverBusReportReview', request);
  }

  public discardDriverBusReport(
    request: fromApi.discardDriverBusReport.DiscardDriverBusReportRequest,
  ): Observable<fromApi.discardDriverBusReport.DiscardDriverBusReportResponse> {
    return this.baseApi.post('discardDriverBusReport', request);
  }

  public getStandbyDriverSchedules(
    request: fromApi.getStandbyDriverSchedules.GetStandbyDriverSchedulesRequest,
  ): Observable<fromApi.getStandbyDriverSchedules.GetStandbyDriverSchedulesResponse> {
    return this.baseApi.post('getStandbyDriverSchedules', request);
  }

  public getStandbyDriverStatuses(
    request: fromApi.getStandbyDriverStatuses.GetStandbyDriverStatusesRequest,
  ): Observable<fromApi.getStandbyDriverStatuses.GetStandbyDriverStatusesResponse> {
    return this.baseApi.post('getStandbyDriverStatuses', request);
  }

  public getOnScheduleStandbyDriverIds(
    request: fromApi.getOnScheduleStandbyDriverIds.GetOnScheduleStandbyDriverIdsRequest,
  ): Observable<fromApi.getOnScheduleStandbyDriverIds.GetOnScheduleStandbyDriverIdsResponse> {
    return this.baseApi.post('getOnScheduleStandbyDriverIds', request);
  }

  public getStandbyDriverConfigsByYard(
    request: fromApi.getStandbyDriverConfigsByYard.getStandbyDriverConfigsByYardRequest,
  ): Observable<fromApi.getStandbyDriverConfigsByYard.getStandbyDriverConfigsByYardResponse> {
    return this.baseApi.post('getStandbyDriverConfigsByYard', request);
  }

  public getStandbyDriverConfigByDateRange(
    request: fromApi.getStandbyDriverConfigByDateRange.GetStandbyDriverConfigByDateRangeRequest,
  ): Observable<fromApi.getStandbyDriverConfigByDateRange.GetStandbyDriverConfigByDateRangeResponse> {
    return this.baseApi.post('getStandbyDriverConfigByDateRange', request);
  }

  public createStandbyDriverStatus(
    request: fromApi.createStandbyDriverStatus.CreateStandbyDriverStatusRequest,
  ): Observable<fromApi.createStandbyDriverStatus.CreateStandbyDriverStatusResponse> {
    return this.baseApi.post('createStandbyDriverStatus', request);
  }

  public getUserSpecifiedStandbyDriverSchedules(
    request: fromApi.getUserSpecifiedStandbyDriverSchedules.GetUserSpecifiedStandbyDriverSchedulesRequest,
  ): Observable<fromApi.getUserSpecifiedStandbyDriverSchedules.GetUserSpecifiedStandbyDriverSchedulesResponse> {
    return this.baseApi.post('getUserSpecifiedStandbyDriverSchedules', request);
  }

  public tagRideWithRouteGroup(
    request: fromApi.tagRideWithRouteGroup.TagRideWithRouteGroupRequest,
  ): Observable<fromApi.tagRideWithRouteGroup.TagRideWithRouteGroupResponse> {
    return this.baseApi.post('tagRideWithRouteGroup', request);
  }

  public getRideId(request: fromApi.getRideId.GetRideIdRequest): Observable<fromApi.getRideId.GetRideIdResponse> {
    return this.baseApi.post('getRideId', request);
  }

  public terminateStandbyDriverStatus(
    request: fromApi.terminateStandbyDriverStatus.TerminateStandbyDriverStatusRequest,
  ): Observable<fromApi.terminateStandbyDriverStatus.TerminateStandbyDriverStatusResponse> {
    return this.baseApi.post('terminateStandbyDriverStatus', request);
  }

  public getRecentAuthenticationHistory(
    request: fromApi.getRecentAuthenticationHistory.GetRecentAuthenticationHistoryRequest,
  ): Observable<fromApi.getRecentAuthenticationHistory.GetRecentAuthenticationHistoryResponse> {
    return this.baseApi.post('getRecentAuthenticationHistory', request);
  }

  public generateFinanceTransactionReport(
    request: fromApi.generateFinanceTransactionReport.GenerateFinanceTransactionReportRequest,
  ): Observable<fromApi.generateFinanceTransactionReport.GenerateFinanceTransactionReportResponse> {
    return this.baseApi.post('generateFinanceTransactionReport', request);
  }

  public bulkChangeVendorAssignment(
    request: fromApi.bulkChangeVendorAssignment.BulkChangeVendorAssignmentRequest,
  ): Observable<fromApi.bulkChangeVendorAssignment.BulkChangeVendorAssignmentResponse> {
    return this.baseApi.post('bulkChangeVendorAssignment', request, this.charterEndpoint);
  }

  public getVendorAssignmentByRide(
    request: fromApi.getVendorAssignmentByRide.GetVendorAssignmentByRideRequest,
  ): Observable<fromApi.getVendorAssignmentByRide.GetVendorAssignmentByRideResponse> {
    return this.baseApi.post('getVendorAssignmentByRide', request, this.charterEndpoint);
  }

  public changeVendorAssignment(
    request: fromApi.changeVendorAssignment.ChangeVendorAssignmentRequest,
  ): Observable<fromApi.changeVendorAssignment.ChangeVendorAssignmentResponse> {
    return this.baseApi.post('changeVendorAssignment', request, this.charterEndpoint);
  }

  public getRideVendorAssignmentHistory(
    request: fromApi.getRideVendorAssignmentHistory.GetRideVendorAssignmentHistoryRequest,
  ): Observable<fromApi.getRideVendorAssignmentHistory.GetRideVendorAssignmentHistoryResponse> {
    return this.baseApi.post('getRideVendorAssignmentHistory', request);
  }

  public getTransportationRegistrationDetail(
    request: fromApi.getTransportationRegistrationDetail.GetTransportationRegistrationDetailRequest,
  ): Observable<fromApi.getTransportationRegistrationDetail.GetTransportationRegistrationDetailResponse> {
    return this.baseApi.post('getTransportationRegistrationDetail', request);
  }

  public processTransportationRegistration(
    request: fromApi.processTransportationRegistration.ProcessTransportationRegistrationRequest,
  ): Observable<fromApi.processTransportationRegistration.ProcessTransportationRegistrationResponse> {
    return this.baseApi.post('processTransportationRegistration', request);
  }

  public getTransportationRegistrationHistory(
    request: fromApi.getTransportationRegistrationHistory.GetTransportationRegistrationHistoryRequest,
  ): Observable<fromApi.getTransportationRegistrationHistory.GetTransportationRegistrationHistoryResponse> {
    return this.baseApi.post('getTransportationRegistrationHistory', request);
  }

  public getTransportationRegistrationListConfig(
    request: fromApi.getTransportationRegistrationListConfig.GetTransportationRegistrationListConfigRequest,
  ): Observable<fromApi.getTransportationRegistrationListConfig.GetTransportationRegistrationListConfigResponse> {
    return this.baseApi.post('getTransportationRegistrationListConfig', request);
  }

  public updateEndOfRideInspectionLeftItemImage(
    request: fromApi.updateEndOfRideInspectionLeftItemImage.UpdateEndOfRideInspectionLeftItemImageRequest,
  ): Observable<fromApi.updateEndOfRideInspectionLeftItemImage.UpdateEndOfRideInspectionLeftItemImageResponse> {
    return this.baseApi.post('updateEndOfRideInspectionLeftItemImage', request);
  }

  public updateEndOfDayInspectionLeftItemImage(
    request: fromApi.updateEndOfDayInspectionLeftItemImage.UpdateEndOfDayInspectionLeftItemImageRequest,
  ): Observable<fromApi.updateEndOfDayInspectionLeftItemImage.UpdateEndOfDayInspectionLeftItemImageResponse> {
    return this.baseApi.post('updateEndOfDayInspectionLeftItemImage', request);
  }

  public downloadPdf(
    request: fromApi.downloadPdf.DownloadPdfRequest,
  ): Observable<fromApi.downloadPdf.DownloadPdfResponse> {
    return this.baseApi.postBlob('downloadPdf', { ...request }).pipe(map((res) => res.data));
  }

  public getEndOfRideInspectionAbstractByRide(
    request: fromApi.getEndOfRideInspectionAbstractByRide.GetEndOfRideInspectionAbstractByRideRequest,
  ): Observable<fromApi.getEndOfRideInspectionAbstractByRide.GetEndOfRideInspectionAbstractByRideResponse> {
    return this.baseApi.post('getEndOfRideInspectionAbstractByRide', request);
  }

  public getStudentRfidOrder(
    request: fromApi.getStudentRfidOrder.GetStudentRfidOrderRequest,
  ): Observable<fromApi.getStudentRfidOrder.GetStudentRfidOrderResponse> {
    return this.baseApi.post('getStudentRfidOrder', request);
  }

  public changeRfidStatusByStudentId(
    request: fromApi.changeRfidStatusByStudentId.ChangeRfidStatusByStudentIdRequest,
  ): Observable<fromApi.changeRfidStatusByStudentId.ChangeRfidStatusByStudentIdResponse> {
    return this.baseApi.post('changeRfidStatusByStudentId', request);
  }

  public updateStudentRfidOrder(
    request: fromApi.updateStudentRfidOrder.UpdateStudentRfidOrderRequest,
  ): Observable<fromApi.updateStudentRfidOrder.UpdateStudentRfidOrderResponse> {
    return this.baseApi.post('updateStudentRfidOrder', request);
  }

  public approveParentStudentAuthorization(
    request: fromApi.approveParentStudentAuthorization.ApproveParentStudentAuthorizationRequest,
  ): Observable<fromApi.approveParentStudentAuthorization.ApproveParentStudentAuthorizationResponse> {
    return this.baseApi.post('approveParentStudentAuthorization', request);
  }

  public revokeParentStudentAuthorization(
    request: fromApi.revokeParentStudentAuthorization.RevokeParentStudentAuthorizationRequest,
  ): Observable<fromApi.revokeParentStudentAuthorization.RevokeParentStudentAuthorizationResponse> {
    return this.baseApi.post('revokeParentStudentAuthorization', request);
  }

  public resetStudentAuthorizationKBA(
    request: fromApi.resetStudentAuthorizationKBA.ResetStudentAuthorizationKBARequest,
  ): Observable<fromApi.resetStudentAuthorizationKBA.ResetStudentAuthorizationKBAResponse> {
    return this.baseApi.post('resetStudentAuthorizationKBA', request);
  }

  public unblockParentStudentAuthorization(
    request: fromApi.unblockParentStudentAuthorization.UnblockParentStudentAuthorizationRequest,
  ): Observable<fromApi.unblockParentStudentAuthorization.UnblockParentStudentAuthorizationResponse> {
    return this.baseApi.post('unblockParentStudentAuthorization', request);
  }

  public getRouteIssuesByDistrict(
    request: fromApi.getRouteIssuesByDistrict.GetRouteIssuesByDistrictRequest,
  ): Observable<fromApi.getRouteIssuesByDistrict.GetRouteIssuesByDistrictResponse> {
    return this.baseApi.post('getRouteIssuesByDistrict', request);
    //   .pipe(
    //   map((response: fromApi.getRouteIssuesByDistrict.GetRouteIssuesByDistrictResponse) => {
    //     const targetNumIssues = 1000;
    //     // generate target number of issues based on the real response
    //     const finalIssues = [];
    //     const sourceIssues = [...response.entities];
    //     const sourceIssueLength = sourceIssues.length;
    //     for (let i = 0; i < targetNumIssues; i++) {
    //       finalIssues.push(sourceIssues[i % sourceIssueLength]);
    //     }
    //     return {
    //       entities: finalIssues,
    //     };
    //   }),
    // );
  }

  public getRouteIssuesByEntity(
    request: fromApi.getRouteIssuesByEntity.GetRouteIssuesByEntityRequest,
  ): Observable<fromApi.getRouteIssuesByEntity.GetRouteIssuesByEntityResponse> {
    return this.baseApi.post('getRouteIssuesByEntity', request);
  }

  public refreshExplorerRoute(
    request: fromApi.refreshExplorerRoute.RefreshExplorerRouteRequest,
  ): Observable<fromApi.refreshExplorerRoute.RefreshExplorerRouteResponse> {
    return this.baseApi.post('refreshExplorerRoute', request);
  }

  public getRouteStudentV2(
    request: fromApi.getRouteStudentV2.GetRouteStudentV2Request,
  ): Observable<fromApi.getRouteStudentV2.GetRouteStudentV2Response> {
    return this.baseApi.post('getRouteStudentV2', request);
  }

  public getUnassignedExplorerStudentsV2(
    request: fromApi.getUnassignedExplorerStudentsV2.GetUnassignedExplorerStudentsV2Request,
  ): Observable<fromApi.getUnassignedExplorerStudentsV2.GetUnassignedExplorerStudentsV2Response> {
    return this.baseApi.post('getUnassignedExplorerStudentsV2', request);
    //   .pipe(
    //   map((response: fromApi.getUnassignedExplorerStudentsV2.GetUnassignedExplorerStudentsV2Response) => {
    //     const numItems = 1000;
    //     const finalItems = Object.values(response.allStudents);
    //     const studentPool = [...finalItems];
    //     if (!studentPool?.length) {
    //       return response;
    //     }
    //     const finalByDirection = { ...response.byDirection };
    //     while (finalItems.length < numItems) {
    //       const randomIndex = Math.floor(Math.random() * studentPool.length);
    //       const randomStudent = studentPool[randomIndex].student;
    //       const randomId = getRandomId();
    //       const targetStudent = { ...randomStudent, id: randomId };
    //       finalItems.push({ student: targetStudent, campusId: studentPool[randomIndex].campusId });
    //       Object.keys(response.byDirection).forEach((direction) => {
    //         Object.keys(response.byDirection[direction].byWeekday).forEach((weekday) => {
    //           finalByDirection[direction].byWeekday[weekday].studentIds.push(randomId);
    //         });
    //       });
    //     }
    //     const finalAllStudents = finalItems.reduce((acc, student) => {
    //       return { ...acc, [student.student.id]: student };
    //     }, {});
    //     return {
    //       allStudents: finalAllStudents,
    //       byDirection: finalByDirection,
    //     };
    //   }),
    // );
  }

  public getExplorerStudentV2(
    request: fromApi.getExplorerStudentV2.GetExplorerStudentV2Request,
  ): Observable<fromApi.getExplorerStudentV2.GetExplorerStudentV2Response> {
    return this.baseApi.post('getExplorerStudentV2', request);
  }

  public getAnnotatedTracesForDriver(
    request: fromApi.getAnnotatedTracesForDriver.GetAnnotatedTracesForDriverRequest,
  ): Observable<fromApi.getAnnotatedTracesForDriver.GetAnnotatedTracesForDriverResponse> {
    return this.baseApi.post('getAnnotatedTracesForDriver', request);
  }

  public getRideVideo(
    request: fromApi.getRideVideo.GetRideVideoRequest,
  ): Observable<fromApi.getRideVideo.GetRideVideoResponse> {
    return this.baseApi.post('getRideVideo', request);
  }

  public updateVehicleYard(
    request: fromApi.updateVehicleYard.UpdateVehicleYardRequest,
  ): Observable<fromApi.updateVehicleYard.UpdateVehicleYardResponse> {
    return this.baseApi.post('updateVehicleYard', request);
  }

  public getCharterAccessUrl(): Observable<fromApi.getCharterAccessUrl.GetCharterAccessUrlResponse> {
    return this.baseApi.post('getAccessUrl', undefined, this.charterEndpoint);
  }

  public uploadSBCUSDRoute(
    request: fromApi.uploadSBCUSDRoutes.UploadSBCUSDRoutesRequest,
  ): Observable<fromApi.uploadSBCUSDRoutes.UploadSBCUSDRoutesResponse> {
    const formData = objectToFormData(request);
    return this.baseApi.postFormData('uploadSBCUSDRoutes', formData);
  }

  public createActivitiesFromCsv(
    request: fromApi.createActivitiesFromCsv.CreateActivitiesFromCsvRequest,
  ): Observable<void> {
    const formData = new FormData();
    formData.append('fileName', request.file);
    formData.append('dateRange', JSON.stringify(request.dateRange));
    formData.append('isLogAndApproveTimeEntries', request.isLogAndApproveTimeEntries.toString());
    return this.baseApi.postFormData('createActivitiesFromCSV', formData);
  }

  public exportTripManifest(request: fromApi.exportTripManifest.ExportTripManifestRequest): Observable<void> {
    return this.baseApi.post('exportTripManifest', request, this.charterEndpoint);
  }

  public downloadTripManifest(
    request: fromApi.downloadTripManifest.DownloadTripManifestRequest,
  ): Observable<fromApi.downloadTripManifest.DownloadTripManifestResponse> {
    return this.baseApi.postBlob('downloadTripManifest', request, this.charterEndpoint).pipe(map((res) => res.data));
  }

  public addStudentsWithUnassignedIssueToRouteExplorer(
    request: fromApi.addStudentsWithUnassignedIssueToRouteExplorer.AddStudentsWithUnassignedIssueToRouteExplorerRequest,
  ): Observable<fromApi.addStudentsWithUnassignedIssueToRouteExplorer.AddStudentsWithUnassignedIssueToRouteExplorerResponse> {
    return this.baseApi.post('addStudentsWithRouteIssueToRouteExplorer', request);
  }

  public initializeRouteIssues(
    request: fromApi.initializeRouteIssues.InitializeRouteIssuesRequest,
  ): Observable<fromApi.initializeRouteIssues.InitializeRouteIssuesResponse> {
    return this.baseApi.post('initializeRouteIssues', request);
  }

  public getDistrictStudentRfidStatus(
    request: fromApi.getDistrictStudentRfidStatus.GetDistrictStudentRfidStatusRequest,
  ): Observable<fromApi.getDistrictStudentRfidStatus.GetDistrictStudentRfidStatusResponse> {
    return this.baseApi.post('getDistrictStudentRFIDStatus', request);
  }

  public getRouteLegManeuvers(
    request: fromApi.getRouteLegManeuvers.GetRouteLegManeuversRequest,
  ): Observable<fromApi.getRouteLegManeuvers.GetRouteLegManeuversResponse> {
    return this.baseApi.post('getRouteLegManeuvers', request);
  }

  public setRouteLegPassThroughLocations(
    request: fromApi.setRouteLegPassThroughLocations.SetRouteLegPassThroughLocationsRequest,
  ): Observable<fromApi.setRouteLegPassThroughLocations.SetRouteLegPassThroughLocationsResponse> {
    return this.baseApi.post('setRouteLegPassThroughLocations', request);
  }

  public createOptimizedRoutesForStudents(
    request: fromApi.createOptimizedRoutesForStudents.CreateOptimizedRoutesForStudentsRequest,
  ): Observable<fromApi.createOptimizedRoutesForStudents.CreateOptimizedRoutesForStudentsResponse> {
    return this.baseApi.post('createOptimizedRoutesForStudents', request);
  }

  public getRidePolyline(
    request: fromApi.getRidePolyline.GetRidePolylineRequest,
  ): Observable<fromApi.getRidePolyline.GetRidePolylineResponse> {
    return this.baseApi.post('getRidePolyline', request);
  }

  public getRideDriverFee(
    request: fromApi.getRideDriverFee.GetRideDriverFeeRequest,
  ): Observable<fromApi.getRideDriverFee.GetRideDriverFeeResponse> {
    return this.baseApi.post('getRideDriverFee', request);
  }

  public getCatalogDisplayInfo(
    request: fromApi.getCatalogDisplayInfo.GetCatalogDisplayInfoRequest,
  ): Observable<fromApi.getCatalogDisplayInfo.GetCatalogDisplayInfoResponse> {
    return this.baseApi.post('getCatalogDisplayInfo', request);
  }

  public getFlaggedCheckpoints(
    request: fromApi.getFlaggedCheckpoints.GetFlaggedCheckpointsRequest,
  ): Observable<fromApi.getFlaggedCheckpoints.GetFlaggedCheckpointsResponse> {
    return this.baseApi.post('getFlaggedCheckpoints', request, `checkpoint/${wpEnvironment.userRole}`);
  }

  public getPastCheckpoints(
    request: fromApi.getPastCheckpoints.GetPastCheckpointsRequest,
  ): Observable<fromApi.getPastCheckpoints.GetPastCheckpointsResponse> {
    return this.baseApi.post('getPastCheckpoints', request, `checkpoint/${wpEnvironment.userRole}`);
  }

  public getVehicleMakes(
    request: fromApi.getVehicleMakes.GetVehicleMakesRequest,
  ): Observable<fromApi.getVehicleMakes.GetVehicleMakesResponse> {
    return this.baseApi.post('getVehicleMakes', request);
  }

  public getVehicleMakeModels(
    request: fromApi.getVehicleMakeModels.GetVehicleMakeModelsRequest,
  ): Observable<fromApi.getVehicleMakeModels.GetVehicleMakeModelsResponse> {
    return this.baseApi.post('getVehicleMakeModels', request);
  }

  public getDispatchLiveViewMetrics(
    request: fromApi.getDispatchLiveViewMetrics.GetDispatchLiveViewMetricsRequest,
  ): Observable<fromApi.getDispatchLiveViewMetrics.GetDispatchLiveViewMetricsResponse> {
    return this.baseApi.post('getDispatchLiveViewMetrics', request, `checkpoint/${wpEnvironment.userRole}`);
  }

  public getStudentSnapshot(
    request: fromApi.getStudentSnapshot.GetStudentSnapshotRequest,
  ): Observable<fromApi.getStudentSnapshot.GetStudentSnapshotResponse> {
    return this.baseApi.post('getStudentByEntity', request);
  }

  public getCampusSnapshot(
    request: fromApi.getCampusSnapshot.GetCampusSnapshotRequest,
  ): Observable<fromApi.getCampusSnapshot.GetCampusSnapshotResponse> {
    return this.baseApi.post('getCampusByEntity', request);
  }

  public snoozeCheckpoints(
    request: fromApi.snoozeCheckpoints.SnoozeCheckpointsRequest,
  ): Observable<fromApi.snoozeCheckpoints.SnoozeCheckpointsResponse> {
    return this.baseApi.post('snoozeCheckpoints', request, `checkpoint/${wpEnvironment.userRole}`);
  }

  public unSnoozeCheckpoints(
    request: fromApi.unSnoozeCheckpoints.UnSnoozeCheckpointsRequest,
  ): Observable<fromApi.unSnoozeCheckpoints.UnSnoozeCheckpointsResponse> {
    return this.baseApi.post('unSnoozeCheckpoints', request, `checkpoint/${wpEnvironment.userRole}`);
  }

  public getDistrictSupportUrl(): Observable<fromApi.getDistrictSupportUrl.GetDistrictSupportUrlResponse> {
    return this.baseApi.post('getDistrictSupportUrl');
  }

  public getVendorSupportUrl(): Observable<fromApi.getVendorSupportUrl.GetVendorSupportUrlResponse> {
    return this.baseApi.post('getVendorSupportUrl');
  }

  public createMirrorExplorerRoutes(
    request: fromApi.createMirrorExplorerRoutes.CreateMirrorExplorerRoutesRequest,
  ): Observable<fromApi.createMirrorExplorerRoutes.CreateMirrorExplorerRoutesResponse> {
    return this.baseApi.post('createMirrorExplorerRoutes', request);
  }

  public bulkPublishExplorerRoutes(
    request: fromApi.bulkPublishExplorerRoutes.BulkPublishExplorerRoutesRequest,
  ): Observable<fromApi.bulkPublishExplorerRoutes.BulkPublishExplorerRoutesResponse> {
    return this.baseApi.post('publishExplorerRoutes', request);
  }

  public deleteExplorerRoute(
    request: fromApi.deleteExplorerRoute.DeleteExplorerRouteRequest,
  ): Observable<fromApi.deleteExplorerRoute.DeleteExplorerRouteResponse> {
    return this.baseApi.post('deleteExplorerRoute', request);
  }

  public getTransportationRegistrationConfig(
    request: fromApi.getTransportationRegistrationConfig.GetTransportationRegistrationConfigRequest,
  ): Observable<fromApi.getTransportationRegistrationConfig.GetTransportationRegistrationConfigResponse> {
    return this.baseApi.post('getTransportationRegistrationConfig', request);
  }

  public evaluateTransportationEligibility(
    request: fromApi.evaluateTransportationEligibility.EvaluateTransportationEligibilityRequest,
  ): Observable<fromApi.evaluateTransportationEligibility.EvaluateTransportationEligibilityResponse> {
    return this.baseApi.post('evaluateTransportationEligibility', request);
  }

  public getDefaultDistrictGrades(): Observable<fromApi.getDefaultDistrictGrades.GetDefaultDistrictGradesResponse> {
    return this.baseApi.post('getDefaultDistrictGrades');
  }

  public getDistrictPrograms(
    request: fromApi.getDistrictPrograms.GetDistrictProgramsRequest,
  ): Observable<fromApi.getDistrictPrograms.GetDistrictProgramsResponse> {
    return this.baseApi.post('getDistrictPrograms', request);
  }

  public createDistrictProgram(
    request: fromApi.createDistrictProgram.CreateDistrictProgramRequest,
  ): Observable<fromApi.createDistrictProgram.CreateDistrictProgramResponse> {
    return this.baseApi.post('createDistrictProgram', request);
  }

  public updateDistrictProgram(
    request: fromApi.updateDistrictProgram.UpdateDistrictProgramRequest,
  ): Observable<fromApi.updateDistrictProgram.UpdateDistrictProgramResponse> {
    return this.baseApi.post('updateDistrictProgram', request);
  }

  public setDefaultDistrictProgram(
    request: fromApi.setDefaultDistrictProgram.SetDefaultDistrictProgramRequest,
  ): Observable<fromApi.setDefaultDistrictProgram.SetDefaultDistrictProgramResponse> {
    return this.baseApi.post('setDefaultDistrictProgram', request);
  }

  public getDistrictProgram(
    request: fromApi.getDistrictProgram.GetDistrictProgramRequest,
  ): Observable<fromApi.getDistrictProgram.GetDistrictProgramResponse> {
    return this.baseApi.post('getDistrictProgram', request);
  }

  public getCampusPrograms(
    request: fromApi.getCampusPrograms.GetCampusProgramsRequest,
  ): Observable<fromApi.getCampusPrograms.GetCampusProgramsResponse> {
    return this.baseApi.post('getCampusPrograms', request);
  }

  public getCampusProgram(
    request: fromApi.getCampusProgram.GetCampusProgramRequest,
  ): Observable<fromApi.getCampusProgram.GetCampusProgramResponse> {
    return this.baseApi.post('getCampusProgram', request);
  }

  public createCampusProgram(
    request: fromApi.createCampusProgram.CreateCampusProgramRequest,
  ): Observable<fromApi.createCampusProgram.CreateCampusProgramResponse> {
    return this.baseApi.post('createCampusProgram', request);
  }

  public updateCampusProgram(
    request: fromApi.updateCampusProgram.UpdateCampusProgramRequest,
  ): Observable<fromApi.updateCampusProgram.UpdateCampusProgramResponse> {
    return this.baseApi.post('updateCampusProgram', request);
  }

  public getCampusBellSchedule(
    request: fromApi.getCampusBellSchedule.GetCampusBellScheduleRequest,
  ): Observable<fromApi.getCampusBellSchedule.GetCampusBellScheduleResponse> {
    return this.baseApi.post('getCampusBellSchedule', request);
  }

  public createCampusBellSchedule(
    request: fromApi.createCampusBellSchedule.CreateCampusBellScheduleRequest,
  ): Observable<fromApi.createCampusBellSchedule.CreateCampusBellScheduleResponse> {
    return this.baseApi.post('createCampusBellSchedule', request);
  }

  public updateCampusBellSchedule(
    request: fromApi.updateCampusBellSchedule.UpdateCampusBellScheduleRequest,
  ): Observable<fromApi.updateCampusBellSchedule.UpdateCampusBellScheduleResponse> {
    return this.baseApi.post('updateCampusBellSchedule', request);
  }

  public getCampusBellSchedulesByCampusProgram(
    request: fromApi.getCampusBellSchedulesByCampusProgram.GetCampusBellSchedulesByCampusProgramRequest,
  ): Observable<fromApi.getCampusBellSchedulesByCampusProgram.GetCampusBellSchedulesByCampusProgramResponse> {
    return this.baseApi.post('getCampusBellSchedulesByCampusProgram', request);
  }

  public getVehicleGeotabLocations(): Observable<fromApi.getVehicleGeotabLocations.GetVehicleGeotabLocationsResponse> {
    return this.baseApi.post('getVehicleGeotabLocations', {});
  }

  public getRouteIssuesByDistrictPrograms(
    request: fromApi.getRouteIssuesByDistrictPrograms.GetRouteIssuesByDistrictProgramsRequest,
  ): Observable<fromApi.getRouteIssuesByDistrictPrograms.GetRouteIssuesByDistrictProgramsResponse> {
    return this.baseApi
      .post('getRouteIssuesByDistrictPrograms', request)
      .pipe(map((res) => transformRouteIssueByDistrictProgramsResponseToLegacy(res)));
  }

  public getExplorerDistrictProgramBatchIds(
    request: fromApi.getExplorerDistrictProgramBatchIds.GetExplorerDistrictProgramBatchIdsRequest,
  ): Observable<fromApi.getExplorerDistrictProgramBatchIds.GetExplorerDistrictProgramBatchIdsResponse> {
    return this.baseApi.post('getExplorerDistrictProgramBatchIds', request);
  }

  public getExplorerRoutesByDistrictPrograms(
    request: fromApi.getExplorerRoutesByDistrictPrograms.GetExplorerRoutesByDistrictProgramsRequest,
  ): Observable<fromApi.getExplorerRoutesByDistrictPrograms.GetExplorerRoutesByDistrictProgramsResponse> {
    return this.baseApi.post('getExplorerRoutesByDistrictPrograms', request);
  }

  public refreshExplorerRouteListByDistrictPrograms(
    request: fromApi.refreshExplorerRouteListByDistrictPrograms.RefreshExplorerRouteListByDistrictProgramsRequest,
  ): Observable<fromApi.refreshExplorerRouteListByDistrictPrograms.RefreshExplorerRouteListByDistrictProgramsResponse> {
    return this.baseApi.post('refreshExplorerRouteListByDistrictPrograms', request);
  }

  public getUnassignedExplorerStudentsV2ByDistrictPrograms(
    request: fromApi.getUnassignedExplorerStudentsV2ByDistrictPrograms.GetUnassignedExplorerStudentsV2ByDistrictProgramsRequest,
  ): Observable<fromApi.getUnassignedExplorerStudentsV2ByDistrictPrograms.GetUnassignedExplorerStudentsV2ByDistrictProgramsResponse> {
    return this.baseApi
      .post('getUnassignedExplorerStudentsV2ByDistrictPrograms', request)
      .pipe(map((res) => transformGetUnassignedExplorerStudentsV2ByDistrictProgramsToLegacy(res)));
  }

  public addStudentsWithRouteIssueToRouteExplorerByDistrictPrograms(
    request: fromApi.addStudentsWithRouteIssueToRouteExplorerByDistrictPrograms.AddStudentsWithRouteIssueToRouteExplorerByDistrictProgramsRequest,
  ): Observable<fromApi.addStudentsWithRouteIssueToRouteExplorerByDistrictPrograms.AddStudentsWithRouteIssueToRouteExplorerByDistrictProgramsResponse> {
    return this.baseApi.post('addStudentsWithRouteIssueToRouteExplorerByDistrictPrograms', request);
  }

  public addStudentToExplorerByDistrictPrograms(
    request: fromApi.addStudentToExplorerByDistrictPrograms.AddStudentToExplorerByDistrictProgramsRequest,
  ): Observable<fromApi.addStudentToExplorerByDistrictPrograms.AddStudentToExplorerByDistrictProgramsResponse> {
    return this.baseApi.post('addStudentToExplorerByDistrictPrograms', request);
  }

  public removeStudentsFromExplorerByDistrictPrograms(
    request: fromApi.removeStudentsFromExplorerByDistrictPrograms.RemoveStudentsFromExplorerByDistrictProgramsRequest,
  ): Observable<fromApi.removeStudentsFromExplorerByDistrictPrograms.RemoveStudentsFromExplorerByDistrictProgramsResponse> {
    return this.baseApi.post('removeStudentsFromExplorerByDistrictPrograms', request);
  }

  public changeVehicleStatus(
    request: fromApi.changeVehicleStatus.ChangeVehicleStatusRequest,
  ): Observable<fromApi.changeVehicleStatus.ChangeVehicleStatusResponse> {
    return this.baseApi.post('updateVehicleStatus', request);
  }

  public addRouteToExplorerRouteGroup(
    request: fromApi.addRouteToExplorerRouteGroup.AddRouteToExplorerRouteGroupRequest,
  ): Observable<fromApi.addRouteToExplorerRouteGroup.AddRouteToExplorerRouteGroupResponse> {
    return this.baseApi.post('addRouteToExplorerRouteGroup', request);
  }

  public swapRoutesBetweenExplorerRouteGroups(
    request: fromApi.swapRoutesBetweenExplorerRouteGroups.SwapRoutesBetweenExplorerRouteGroupsRequest,
  ): Observable<fromApi.swapRoutesBetweenExplorerRouteGroups.SwapRoutesBetweenExplorerRouteGroupsResponse> {
    return this.baseApi.post('swapRoutesBetweenExplorerRouteGroups', request);
  }

  public getParkoutLocation(
    request: fromApi.getParkoutLocation.GetParkoutLocationRequest,
  ): Observable<fromApi.getParkoutLocation.GetParkoutLocationResponse> {
    return this.baseApi.post('getParkoutLocation', request);
  }

  public createParkoutLocation(
    request: fromApi.createParkoutLocation.CreateParkoutLocationRequest,
  ): Observable<fromApi.createParkoutLocation.CreateParkoutLocationResponse> {
    return this.baseApi.post('createParkoutLocation', request);
  }

  public updateParkoutLocation(
    request: fromApi.updateParkoutLocation.UpdateParkoutLocationRequest,
  ): Observable<fromApi.updateParkoutLocation.UpdateParkoutLocationResponse> {
    return this.baseApi.post('updateParkoutLocation', request);
  }

  public deactivateParkoutLocation(
    request: fromApi.deactivateParkoutLocation.DeactivateParkoutLocationRequest,
  ): Observable<fromApi.deactivateParkoutLocation.DeactivateParkoutLocationResponse> {
    return this.baseApi.post('deactivateParkoutLocation', request);
  }

  public getParkoutLocationEvent(
    request: fromApi.getParkoutLocationEvent.GetParkoutLocationEventRequest,
  ): Observable<fromApi.getParkoutLocationEvent.GetParkoutLocationEventResponse> {
    return this.baseApi.post('getParkoutLocationEvent', request);
  }

  public getVehicleLogRecordsByVehicleIdAndTimestampRange(
    request: fromApi.getVehicleLogRecordsByVehicleIdAndTimestampRange.GetVehicleLogRecordsByVehicleIdAndTimestampRangeRequest,
  ): Observable<fromApi.getVehicleLogRecordsByVehicleIdAndTimestampRange.GetVehicleLogRecordsByVehicleIdAndTimestampRangeResponse> {
    return this.baseApi.post('getVehicleLogRecordsByVehicleIdAndTimestampRange', request);
  }

  public findNearbyParkoutLocations(
    request: fromApi.findNearbyParkoutLocations.FindNearbyParkoutLocationsRequest,
  ): Observable<fromApi.findNearbyParkoutLocations.FindNearbyParkoutLocationsResponse> {
    return this.baseApi.post('findNearbyParkoutLocations', request);
  }

  public reviewParkoutLocationEvent(
    request: fromApi.reviewParkoutLocationEvent.ReviewParkoutLocationEventRequest,
  ): Observable<fromApi.reviewParkoutLocationEvent.ReviewParkoutLocationEventResponse> {
    return this.baseApi.post('reviewParkoutLocationEvent', request);
  }

  public getParkoutLocationDriverAuthorization(
    request: fromApi.getParkoutLocationDriverAuthorization.GetParkoutLocationDriverAuthorizationRequest,
  ): Observable<fromApi.getParkoutLocationDriverAuthorization.GetParkoutLocationDriverAuthorizationResponse> {
    return this.baseApi.post('getParkoutLocationDriverAuthorization', request);
  }

  public createParkoutLocationDriverAuthorizations(
    request: fromApi.createParkoutLocationDriverAuthorizations.CreateParkoutLocationDriverAuthorizationsRequest,
  ): Observable<fromApi.createParkoutLocationDriverAuthorizations.CreateParkoutLocationDriverAuthorizationsResponse> {
    return this.baseApi.post('createParkoutLocationDriverAuthorizations', request);
  }

  public updateParkoutLocationDriverAuthorization(
    request: fromApi.updateParkoutLocationDriverAuthorization.UpdateParkoutLocationDriverAuthorizationRequest,
  ): Observable<fromApi.updateParkoutLocationDriverAuthorization.UpdateParkoutLocationDriverAuthorizationResponse> {
    return this.baseApi.post('updateParkoutLocationDriverAuthorization', request);
  }

  public removeParkoutLocationDriverAuthorizations(
    request: fromApi.removeParkoutLocationDriverAuthorizations.RemoveParkoutLocationDriverAuthorizationsRequest,
  ): Observable<fromApi.removeParkoutLocationDriverAuthorizations.RemoveParkoutLocationDriverAuthorizationsResponse> {
    return this.baseApi.post('removeParkoutLocationDriverAuthorizations', request);
  }

  public getLocationByStudentForRouteStops(
    request: fromApi.getLocationByStudentForRouteStops.GetLocationByStudentForRouteStopsRequest,
  ): Observable<fromApi.getLocationByStudentForRouteStops.GetLocationByStudentForRouteStopsResponse> {
    return this.baseApi.post('getLocationByStudentForRouteStops', request);
  }

  public saveRouteStopAssignmentChanges(
    request: fromApi.saveRouteStopAssignmentChanges.SaveRouteStopAssignmentChangesRequest,
  ): Observable<fromApi.saveRouteStopAssignmentChanges.SaveRouteStopAssignmentChangesResponse> {
    return this.baseApi.post('saveRouteStopAssignmentChanges', request);
  }

  public getDriverCommunicationConfig(
    request: fromApi.getDriverCommunicationConfig.GetDriverCommunicationConfigRequest,
  ): Observable<fromApi.getDriverCommunicationConfig.GetDriverCommunicationConfigResponse> {
    return this.baseApi.post('getDriverCommunicationConfig', request);
  }

  public getDriverCommunications(
    request: fromApi.getDriverCommunications.GetDriverCommunicationsRequest,
  ): Observable<fromApi.getDriverCommunications.GetDriverCommunicationsResponse> {
    return this.baseApi.post('getDriverCommunications', request);
  }

  public createDriverCommunication(
    request: fromApi.createDriverCommunication.CreateDriverCommunicationRequest,
  ): Observable<fromApi.createDriverCommunication.CreateDriverCommunicationResponse> {
    return this.baseApi.post('createDriverCommunication', request);
  }

  public updateDriverCommunication(
    request: fromApi.updateDriverCommunication.UpdateDriverCommunicationRequest,
  ): Observable<fromApi.updateDriverCommunication.UpdateDriverCommunicationResponse> {
    return this.baseApi.post('updateDriverCommunication', request);
  }

  public deleteDriverCommunication(
    request: fromApi.deleteDriverCommunication.DeleteDriverCommunicationRequest,
  ): Observable<fromApi.deleteDriverCommunication.DeleteDriverCommunicationResponse> {
    return this.baseApi.post('deleteDriverCommunication', request);
  }

  public cancelDriverCommunication(
    request: fromApi.cancelDriverCommunication.CancelDriverCommunicationRequest,
  ): Observable<fromApi.cancelDriverCommunication.CancelDriverCommunicationResponse> {
    return this.baseApi.post('cancelDriverCommunication', request);
  }

  public submitDriverCommunicationForReview(
    request: fromApi.submitDriverCommunicationForReview.SubmitDriverCommunicationForReviewRequest,
  ): Observable<fromApi.submitDriverCommunicationForReview.SubmitDriverCommunicationForReviewResponse> {
    return this.baseApi.post('submitDriverCommunicationForReview', request);
  }

  public approveDriverCommunication(
    request: fromApi.approveDriverCommunication.ApproveDriverCommunicationRequest,
  ): Observable<fromApi.approveDriverCommunication.ApproveDriverCommunicationResponse> {
    return this.baseApi.post('approveDriverCommunication', request);
  }

  public rejectDriverCommunication(
    request: fromApi.rejectDriverCommunication.RejectDriverCommunicationRequest,
  ): Observable<fromApi.rejectDriverCommunication.RejectDriverCommunicationResponse> {
    return this.baseApi.post('rejectDriverCommunication', request);
  }

  public updateDriverYard(
    request: fromApi.updateDriverYard.UpdateDriverYardRequest,
  ): Observable<fromApi.updateDriverYard.UpdateDriverYardResponse> {
    return this.baseApi.post('updateDriverYard', request);
  }

  public getDriverCommunicationRecommendation(
    request: fromApi.getDriverCommunicationRecommendation.GetDriverCommunicationRecommendationRequest,
  ): Observable<fromApi.getDriverCommunicationRecommendation.GetDriverCommunicationRecommendationResponse> {
    return this.baseApi.post('getDriverCommunicationRecommendation', request);
  }

  public getDistrictBulkNotificationTemplates(
    request: fromApi.getDistrictBulkNotificationTemplates.GetDistrictBulkNotificationTemplatesRequest,
  ): Observable<fromApi.getDistrictBulkNotificationTemplates.GetDistrictBulkNotificationTemplatesResponse> {
    return this.baseApi.post('getDistrictBulkNotificationTemplates', request);
  }

  public sendDistrictBulkNotificationsToParents(
    request: fromApi.sendDistrictBulkNotificationsToParents.SendDistrictBulkNotificationsToParentsRequest,
  ): Observable<fromApi.sendDistrictBulkNotificationsToParents.SendDistrictBulkNotificationsToParentsResponse> {
    return this.baseApi.post('sendDistrictBulkNotificationsToParents', request);
  }

  public getDriverAttendanceLeaves(
    request: fromApi.getDriverAttendanceLeaves.GetDriverAttendanceLeavesRequest,
  ): Observable<fromApi.getDriverAttendanceLeaves.GetDriverAttendanceLeavesResponse> {
    return this.baseApi.post('getDriverAttendanceLeaves', request);
  }

  public getDriverAttendanceConfig(): Observable<fromApi.getDriverAttendanceConfig.GetDriverAttendanceConfigResponse> {
    return this.baseApi.post('getDriverAttendanceConfig');
  }

  public getDriverAttendanceLeave(
    request: fromApi.getDriverAttendanceLeave.GetDriverAttendanceLeaveRequest,
  ): Observable<fromApi.getDriverAttendanceLeave.GetDriverAttendanceLeaveResponse> {
    return this.baseApi.post('getDriverAttendanceLeave', request);
  }

  public deleteDriverAttendanceLeave(request: fromApi.deleteDriverAttendanceLeave.DeleteDriverAttendanceLeaveRequest) {
    return this.baseApi.post('deleteDriverAttendanceLeave', request);
  }

  public createDriverAttendanceLeave(
    request: fromApi.createDriverAttendanceLeave.CreateDriverAttendanceLeaveRequest,
  ): Observable<fromApi.createDriverAttendanceLeave.CreateDriverAttendanceLeaveResponse> {
    return this.baseApi.post('createDriverAttendanceLeave', request);
  }

  public getDriverAttendanceLeaveEditConfig(
    request: fromApi.getDriverAttendanceLeaveEditConfig.GetDriverAttendanceLeaveEditConfigRequest,
  ): Observable<fromApi.getDriverAttendanceLeaveEditConfig.GetDriverAttendanceLeaveEditConfigResponse> {
    return this.baseApi.post('getDriverAttendanceLeaveEditConfig', request);
  }

  public updateDriverAttendanceLeave(
    request: fromApi.updateDriverAttendanceLeave.UpdateDriverAttendanceLeaveRequest,
  ): Observable<fromApi.updateDriverAttendanceLeave.UpdateDriverAttendanceLeaveResponse> {
    return this.baseApi.post('updateDriverAttendanceLeave', request);
  }

  public getDriverAttendanceDriversOnLeave(
    request: fromApi.getDriverAttendanceDriversOnLeave.GetDriverAttendanceDriversOnLeaveRequest,
  ): Observable<fromApi.getDriverAttendanceDriversOnLeave.GetDriverAttendanceDriversOnLeaveResponse> {
    return this.baseApi.post('getDriverAttendanceDriversOnLeave', request);
  }

  public getRouteGroupsForDriversOnLeave(
    request: fromApi.getRouteGroupsForDriversOnLeave.GetRouteGroupsForDriversOnLeaveRequest,
  ): Observable<fromApi.getRouteGroupsForDriversOnLeave.GetRouteGroupsForDriversOnLeaveResponse> {
    return this.baseApi.post('getRouteGroupsForDriversOnLeave', request);
  }

  public getPartialRouteGroupDriverAssignments(
    request: fromApi.getPartialRouteGroupDriverAssignments.GetPartialRouteGroupDriverAssignmentsRequest,
  ): Observable<fromApi.getPartialRouteGroupDriverAssignments.GetPartialRouteGroupDriverAssignmentsResponse> {
    return this.baseApi.post('getPartialRouteGroupDriverAssignments', request);
  }

  public applyPartialDriverAssignmentChange(
    request: fromApi.applyPartialDriverAssignmentChange.ApplyPartialDriverAssignmentChangeRequest,
  ): Observable<fromApi.applyPartialDriverAssignmentChange.ApplyPartialDriverAssignmentChangeResponse> {
    return this.baseApi.post('applyPartialDriverAssignmentChange', request);
  }

  public confirmDailyPartialDriverAssignments(
    request: fromApi.confirmDailyPartialDriverAssignments.ConfirmDailyPartialDriverAssignmentsRequest,
  ): Observable<fromApi.confirmDailyPartialDriverAssignments.ConfirmDailyPartialDriverAssignmentsResponse> {
    return this.baseApi.post('confirmDailyPartialDriverAssignments', request);
  }

  public getStopAddressByLocation(
    request: fromApi.getStopAddressByLocation.GetStopAddressByLocationRequest,
  ): Observable<fromApi.getStopAddressByLocation.GetStopAddressByLocationResponse> {
    return this.baseApi.post('getStopAddressByLocation', request);
  }

  public getNearbyStops(
    request: fromApi.getNearbyStops.GetNearbyStopsRequest,
  ): Observable<fromApi.getNearbyStops.GetNearbyStopsResponse> {
    return this.baseApi.post('getNearbyStops', request);
  }

  public addStopToRoute(
    request: fromApi.addStopToRoute.AddStopToRouteRequest,
  ): Observable<fromApi.addStopToRoute.AddStopToRouteResponse> {
    return this.baseApi.post('addStopToRoute', request);
  }

  public createDraftStop(
    request: fromApi.createDraftStop.CreateStopRequest,
  ): Observable<fromApi.createDraftStop.CreateStopResponse> {
    return this.baseApi.post('createDraftStop', request);
  }

  public getCurrentRouteGroupOwners(
    request: fromApi.getCurrentRouteGroupOwners.GetCurrentRouteGroupOwnersRequest,
  ): Observable<fromApi.getCurrentRouteGroupOwners.GetCurrentRouteGroupOwnersResponse> {
    return this.baseApi.post('getCurrentRouteGroupOwners', request);
  }

  public applyRouteGroupShiftOwnerChange(
    request: fromApi.applyRouteGroupShiftOwnerChange.ApplyRouteGroupShiftOwnerChangeRequest,
  ): Observable<fromApi.applyRouteGroupShiftOwnerChange.ApplyRouteGroupShiftOwnerChangeResponse> {
    return this.baseApi.post('applyRouteGroupShiftOwnerChange', request);
  }

  public submitRouteGroupOwnerChanges(
    request: fromApi.submitRouteGroupOwnerChanges.SubmitRouteGroupOwnerChangesRequest,
  ): Observable<fromApi.submitRouteGroupOwnerChanges.SubmitRouteGroupOwnerChangesResponse> {
    return this.baseApi.post('submitRouteGroupOwnerChanges', request);
  }

  public getMonitor(request: fromApi.getMonitor.GetMonitorRequest): Observable<fromApi.getMonitor.GetMonitorResponse> {
    return this.baseApi.post('getMonitor', request);
  }

  public createMonitor(
    request: fromApi.createMonitor.CreateMonitorRequest,
  ): Observable<fromApi.createMonitor.CreateMonitorResponse> {
    return this.baseApi.post('createMonitor', request);
  }

  public updateMonitor(
    request: fromApi.updateMonitor.UpdateMonitorRequest,
  ): Observable<fromApi.updateMonitor.UpdateMonitorResponse> {
    return this.baseApi.post('updateMonitor', request);
  }

  public getMonitorPickupAddresses(
    request: fromApi.getMonitorPickupAddresses.GetMonitorPickupAddressesRequest,
  ): Observable<fromApi.getMonitorPickupAddresses.GetMonitorPickupAddressesResponse> {
    return this.baseApi.post('getMonitorPickupAddresses', request);
  }

  public cleanShiftEntities(
    request: fromApi.cleanShiftEntities.CleanShiftEntitiesRequest,
  ): Observable<fromApi.cleanShiftEntities.CleanShiftEntitiesResponse> {
    return this.baseApi.post('cleanShiftEntities', request);
  }

  public downloadParentRouteNotification(
    request: fromApi.downloadParentRouteNotification.DownloadParentRouteNotificationRequest,
  ): Observable<fromApi.downloadParentRouteNotification.DownloadParentRouteNotificationResponse> {
    return this.baseApi.post('downloadParentRouteNotification', request);
  }

  public createActiveStop(
    request: fromApi.createActiveStop.CreateActiveStopRequest,
  ): Observable<fromApi.createActiveStop.CreateActiveStopResponse> {
    return this.baseApi.post('createActiveStop', request);
  }

  public updateRouteRestrictionExceptions(
    request: fromApi.updateRouteRestrictionExceptions.UpdateRouteRestrictionExceptionsRequest,
  ): Observable<fromApi.updateRouteRestrictionExceptions.UpdateRouteRestrictionExceptionsResponse> {
    return this.baseApi.post('updateRouteRestrictionException', request);
  }

  public getRiderAttendanceConfig(
    request: fromApi.getRiderAttendanceConfig.GetRiderAttendanceConfigRequest,
  ): Observable<fromApi.getRiderAttendanceConfig.GetRiderAttendanceConfigResponse> {
    return this.baseApi.post('getRiderAttendanceConfig', request);
  }

  public getRiderAttendance(
    request: fromApi.getRiderAttendance.GetRiderAttendanceRequest,
  ): Observable<fromApi.getRiderAttendance.GetRiderAttendanceResponse> {
    return this.baseApi.post('getRiderAttendance', request);
  }

  public updateRiderAttendance(
    request: fromApi.updateRiderAttendance.UpdateRiderAttendanceRequest,
  ): Observable<fromApi.updateRiderAttendance.UpdateRiderAttendanceResponse> {
    return this.baseApi.post('updateRiderAttendanceV2', request);
  }

  public getRiderAttendanceExceptions(
    request: fromApi.getRiderAttendanceExceptions.GetRiderAttendanceExceptionsRequest,
  ): Observable<fromApi.getRiderAttendanceExceptions.GetRiderAttendanceExceptionsResponse> {
    return this.baseApi.post('getRiderAttendanceExceptions', request);
  }

  public getRouteGroupConfig(
    request: fromApi.getRouteGroupConfig.GetRouteGroupConfigRequest,
  ): Observable<fromApi.getRouteGroupConfig.GetRouteGroupConfigResponse> {
    return this.baseApi.post('getRouteGroupConfig', request);
  }

  public getRouteGroupFilterConfig(
    request: fromApi.getRouteGroupFilterConfig.GetRouteGroupFilterConfigRequest,
  ): Observable<fromApi.getRouteGroupFilterConfig.GetRouteGroupFilterConfigResponse> {
    return this.baseApi.post('getRouteGroupFilterConfig', request);
  }

  public getDistrictProgramsForRouteGroups(
    request: fromApi.getDistrictProgramsForRouteGroups.GetDistrictProgramsForRouteGroupsRequest,
  ): Observable<fromApi.getDistrictProgramsForRouteGroups.GetDistrictProgramsForRouteGroupsResponse> {
    return this.baseApi.post('getDistrictProgramsForRouteGroups', request);
  }

  public createRouteGroup(
    request: fromApi.createRouteGroup.CreateRouteGroupRequest,
  ): Observable<fromApi.createRouteGroup.CreateRouteGroupResponse> {
    return this.baseApi.post('createRouteGroup', request);
  }

  public editRouteGroup(
    request: fromApi.editRouteGroup.EditRouteGroupRequest,
  ): Observable<fromApi.editRouteGroup.EditRouteGroupResponse> {
    return this.baseApi.post('editRouteGroup', request);
  }

  public updateRouteGroupsDistrictProgram(
    request: fromApi.updateRouteGroupsDistrictProgram.UpdateRouteGroupsDistrictProgramRequest,
  ): Observable<fromApi.updateRouteGroupsDistrictProgram.UpdateRouteGroupsDistrictProgramResponse> {
    return this.baseApi.post('updateRouteGroupsDistrictProgram', request);
  }

  public manageRouteGroupsStatus(
    request: fromApi.manageRouteGroupsStatus.ManageRouteGroupsStatusRequest,
  ): Observable<fromApi.manageRouteGroupsStatus.ManageRouteGroupsStatusResponse> {
    return this.baseApi.post('manageRouteGroupsStatus', request);
  }

  public getDownloadRouteGroupSheetStatus(
    request: fromApi.getDownloadRouteGroupSheetStatus.GetDownloadRouteGroupSheetStatusRequest,
  ): Observable<fromApi.getDownloadRouteGroupSheetStatus.GetDownloadRouteGroupSheetStatusResponse> {
    return this.baseApi.post('getDownloadRouteGroupSheetStatus', request);
  }

  public getRouteExplorerCampusBoundaries(
    request: fromApi.getRouteExplorerCampusBoundaries.GetRouteExplorerCampusBoundariesRequest,
  ): Observable<fromApi.getRouteExplorerCampusBoundaries.GetRouteExplorerCampusBoundariesResponse> {
    return this.baseApi.post('getRouteExplorerCampusBoundaries', request);
  }

  public getSchoolEmployeeManagementConfig(
    request: fromApi.getSchoolEmployeeManagementConfig.GetSchoolEmployeeManagementConfigRequest,
  ): Observable<fromApi.getSchoolEmployeeManagementConfig.GetSchoolEmployeeManagementConfigResponse> {
    return this.baseApi.post('getSchoolEmployeeManagementConfig', request);
  }

  public updateSchoolEmployeeRoles(
    request: fromApi.updateSchoolEmployeeRoles.UpdateSchoolEmployeeRolesRequest,
  ): Observable<fromApi.updateSchoolEmployeeRoles.UpdateSchoolEmployeeRolesResponse> {
    return this.baseApi.post('updateSchoolEmployeeRoles', request);
  }
}
