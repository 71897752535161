import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  GetAuthenticationProviderRequest,
  GetAuthenticationProviderResponse,
} from '../endpoints/get-authentication-provider';
import { PostRequestData, ServerResponse } from './api/http-api.service';
import { RollbarApiError } from '../../rollbar';

@Injectable({
  providedIn: 'root',
})
export class AuthProviderApiService {
  constructor(private http: HttpClient) {}

  public getAuthenticationProvider(
    request: GetAuthenticationProviderRequest,
  ): Observable<GetAuthenticationProviderResponse> {
    const apiName = 'getAuthenticationProvider';
    const endpoint = `${wpEnvironment.apiBaseUrl}/guest/${apiName}`;
    const data = new PostRequestData(apiName, request);
    if (wpEnvironment.apiLogsEnabled) {
      console.log(`${apiName} request`, request);
    }
    return this.http.post(endpoint, data).pipe(
      map((response: ServerResponse) => {
        if (wpEnvironment.apiLogsEnabled) {
          console.log(`${apiName} success`, response);
        }
        return response.data as GetAuthenticationProviderResponse;
      }),
      catchError((httpError: HttpErrorResponse) => {
        console.error(new RollbarApiError(apiName, httpError.status), httpError.error);
        return throwError(httpError.error);
      }),
    );
  }
}
