import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './containers/app/app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { CustomRouteSerializer, effects, reducers } from './store';
import * as fromAuthEffects from './auth/store/effects';
import * as fromFacades from './store/facades';
import { DataStoreModule } from './data/data-store.module';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';

// containers
import { containers } from './containers';

import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RollbarModule } from './rollbar/rollbar.module';
import { SegmentModule } from './segment/segment.module';
import { HomeModule } from './home/home.module';
import { ComponentsModule } from './shared/components/components.module';
import { ButtonsModule } from './shared/buttons/buttons.module';
import { PageModule } from './shared/page/page.module';
import { AnimationsModule } from './shared/animations/animations.module';
import { TypographyModule } from './shared/typography/typography.module';
import { StackLayoutModule } from './shared/layouts/stack-layout/stack-layout.module';
import { ErrorComponent } from './containers/error/error.component';
import { ErrorPopupComponent } from './containers/error-popup/error-popup.component';
import { DeeplinkComponent } from './containers/deeplink/deeplink.component';
import { MatInputModule } from '@angular/material/input';
import { AccountTimezoneErrorComponent } from './containers/account-timezone-error/account-timezone-error.component';
import { NotificationApiPopupComponent } from './containers/notification-api-popup/notification-api-popup.component';

const metaReducers = environment.production ? [] : [storeFreeze];
const devTools = environment.production ? [] : [StoreDevtoolsModule.instrument()];

@NgModule({
  declarations: [
    ...containers,
    ErrorPopupComponent,
    DeeplinkComponent,
    AccountTimezoneErrorComponent,
    NotificationApiPopupComponent,
  ],
  providers: [...fromFacades.commonFacades],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    DataStoreModule,
    AuthModule,
    HomeModule,
    RollbarModule,
    SegmentModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer,
    }),
    EffectsModule.forRoot([...effects, ...fromAuthEffects.effects]),
    ...devTools,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: wpEnvironment.appUpdateEnabled,
      registrationStrategy: 'registerWithDelay:5000',
    }),
    AppRoutingModule,
    ComponentsModule,
    ButtonsModule,
    AnimationsModule,
    TypographyModule,
    PageModule,
    StackLayoutModule,
    MatInputModule,
  ],
  bootstrap: [AppComponent],
  exports: [ErrorComponent],
})
export class AppModule {
  constructor() {}
}
