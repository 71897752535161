import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { mapCenterDefault } from '../../../shared/google-map/models/map-center-default';
import { greyMapThemeStyles } from '../../../shared/google-map/grey-map-theme-styles';

@Component({
  selector: 'wp-polyline-viewer',
  template: `
    <div class="container">
      <h1>Polyline Viewer</h1>
      <input id="input" type="text" placeholder="Enter polyline (e.g., _p~iF~ps|U_ulLnnqC_mqNvxq\`@)" />
      <div style="display: flex; margin-bottom: 20px">
        <input id="show-markers" type="checkbox" />
        <label for="show-markers">Show markers</label>
      </div>
      <div id="map"></div>
    </div>
    <div class="points"></div>
  `,
  styleUrl: './polyline-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolylineViewerComponent implements AfterViewInit {
  private map: google.maps.Map;
  constructor() {}
  ngAfterViewInit(): void {
    this.init();
  }

  private async init(): Promise<void> {
    this.initInputsAndMap();
  }

  private initInputsAndMap(): void {
    const input: HTMLInputElement = document.getElementById('input') as HTMLInputElement;
    const showMarkersInput: HTMLInputElement = document.getElementById('show-markers') as HTMLInputElement;
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: mapCenterDefault,
      zoom: 14,
      clickableIcons: false,
      disableDefaultUI: true,
      fullscreenControl: true,
      mapTypeControlOptions: { mapTypeIds: [] },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      streetViewControl: false,
      gestureHandling: 'greedy',
      styles: [...greyMapThemeStyles],
    });
    const pointsDiv = document.getElementById('points');
    let polyline: google.maps.Polyline;
    let markers: google.maps.Marker[] = [];
    let isShowMarkers: boolean = false;

    input.addEventListener('input', updatePolyline.bind(this));
    showMarkersInput.addEventListener('input', (event) => {
      isShowMarkers = event.target['checked'];
      const bounds = this.map.getBounds();
      markers.forEach((marker) => {
        marker.setVisible(isShowMarkers && bounds.contains(marker.getPosition()));
      });
    });

    google.maps.event.addListener(this.map, 'tilesloaded', updateVisibleMarkersOnResize.bind(this));

    function decodePolyline(str) {
      var index = 0,
        lat = 0,
        lng = 0,
        coordinates = [],
        shift = 0,
        result = 0,
        byte = null,
        latitude_change,
        longitude_change,
        factor = Math.pow(10, 6);

      while (index < str.length) {
        byte = null;
        shift = 0;
        result = 0;

        do {
          byte = str.charCodeAt(index++) - 63;
          result |= (byte & 0x1f) << shift;
          shift += 5;
        } while (byte >= 0x20);

        latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

        shift = result = 0;

        do {
          byte = str.charCodeAt(index++) - 63;
          result |= (byte & 0x1f) << shift;
          shift += 5;
        } while (byte >= 0x20);

        longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

        lat += latitude_change;
        lng += longitude_change;

        coordinates.push({ lat: lat / factor, lng: lng / factor });
      }

      return coordinates;
    }

    function updateVisibleMarkersOnResize(): void {
      if (!isShowMarkers) {
        return;
      }
      const bounds = this.map.getBounds();
      markers.forEach((marker) => {
        marker.setVisible(bounds.contains(marker.getPosition()));
      });
    }

    function updatePolyline() {
      if (polyline) {
        polyline.setMap(null);
      }
      markers.forEach((marker) => marker.setMap(null));
      markers = [];

      const encoded = input.value;
      if (!encoded) return;

      try {
        const latlngs = decodePolyline(encoded);
        polyline = new google.maps.Polyline({
          path: latlngs,
          strokeColor: 'blue',
          strokeWeight: 4,
        });
        polyline.setMap(this.map);
        this.map.fitBounds(getPolylineBounds(polyline));
        latlngs.forEach((latlng, index) => {
          const isWideLabel = index >= 1000;
          const marker = new google.maps.Marker({
            position: latlng,
            visible: isShowMarkers,
            map: this.map,
            optimized: true,
            label: {
              text: '' + (index + 1),
              fontSize: '10px',
            },
            icon: {
              anchor: new google.maps.Point(0, 0),
              labelOrigin: isWideLabel ? new google.maps.Point(18, 7) : new google.maps.Point(10, 7),
              url:
                window.origin +
                (index < 1000
                  ? '/assets/icons/polyline-viewer/rectangle.png'
                  : '/assets/icons/polyline-viewer/rectangle-wide.png'),
            } satisfies google.maps.Icon,
          });
          marker.set('index', index + 1);
          markers.push(marker);
        });
      } catch (error) {
        console.error('Invalid polyline:', error);
        pointsDiv.innerHTML = 'Invalid polyline';
      }
    }

    function getPolylineBounds(poly: google.maps.Polyline) {
      const bounds = new google.maps.LatLngBounds();
      poly.getPath().forEach(function (item, index) {
        bounds.extend(new google.maps.LatLng(item.lat(), item.lng()));
      });
      return bounds;
    }
  }
}
