import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import * as fromTypes from '../../../types';

export interface DistrictListRouteData {
  page?: number;
  pageSize?: number;
  searchList?: string;
  listStatus?: fromTypes.district.DistrictListStatus;
}

export const districtListRoute: PortalRoute<DistrictListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/districts/list');
  },
  parse: (state: RouterStateUrl): DistrictListRouteData => {
    let data: DistrictListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (data: DistrictListRouteData = {}, queryParamStrategy?: QueryParamsHandlingStrategy): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['/districts', 'list'],
    };
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
  getHref(data?: DistrictListRouteData): string {
    return '/districts/list';
  },
};
