import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../api/api.service';
import { CampusPortalEntity, PortalEntity, PortalEntityType, VehiclePortalEntity } from '@rootTypes';
import { GetVehicleProjections } from '../../../api/endpoints/get-vehicle';

@Injectable()
export class PortalEntityInfoApiService {
  constructor(private api: ApiService) {}

  public getPortalEntity(entityId: string, entityType: PortalEntityType): Observable<PortalEntity> {
    switch (entityType) {
      case PortalEntityType.CAMPUS:
        return this.getCampusEntity(entityId);
      case PortalEntityType.DISTRICT:
        return this.getDistrictEntity(entityId);
      case PortalEntityType.STUDENT:
        return this.getStudentEntity(entityId);
      case PortalEntityType.PARENT:
        return this.getParentEntity(entityId);
      case PortalEntityType.DRIVER:
        return this.getDriverEntity(entityId);
      case PortalEntityType.VENDOR:
        return this.getVendorEntity(entityId);
      case PortalEntityType.RIDE:
        return this.getRideEntity(entityId);
      case PortalEntityType.ROUTE:
        return this.getRouteEntity(entityId);
      case PortalEntityType.VEHICLE:
        return this.getVehicleEntity(entityId);
      case PortalEntityType.YARD:
        return this.getYardEntity(entityId);
      case PortalEntityType.ACTIVITY_TIME_ENTRY:
        return this.getActivityEntity(entityId);
      case PortalEntityType.ROUTE_GROUP:
        return this.getRouteGroupEntity(entityId);
      default: {
        console.warn('Unknown entity type ', entityType);
        return of(undefined);
      }
    }
  }

  private getCampusEntity(campusId: string): Observable<CampusPortalEntity> {
    return this.api.getCampus({ campusId }).pipe(
      map((res) => {
        return {
          entityId: campusId,
          type: PortalEntityType.CAMPUS,
          label: res.campus.name,
          districtId: res.campus.districtId,
        };
      }),
    );
  }

  private getDistrictEntity(districtId: string): Observable<PortalEntity> {
    return this.api.getDistrict({ districtId }).pipe(
      map((res) => {
        return {
          entityId: districtId,
          type: PortalEntityType.DISTRICT,
          label: res.district.name,
        };
      }),
    );
  }

  private getStudentEntity(studentId: string): Observable<PortalEntity> {
    return this.api.getStudentV2({ studentId }).pipe(
      map((res) => {
        return {
          entityId: studentId,
          type: PortalEntityType.STUDENT,
          label: `${res?.profile?.firstName} ${res?.profile.lastName}`,
        };
      }),
    );
  }

  private getDriverEntity(driverId: string): Observable<PortalEntity> {
    return this.api.getDriverProfile(driverId).pipe(
      map((driver) => {
        return {
          entityId: driverId,
          label: `${driver.profile.first_name} ${driver.profile.last_name}`,
          type: PortalEntityType.DRIVER,
        };
      }),
    );
  }

  private getVendorEntity(vendorId: string): Observable<PortalEntity> {
    return this.api.getVendorProfile({ vendor_id: vendorId }).pipe(
      map((vendor) => {
        return {
          entityId: vendorId,
          label: `${vendor?.vendor_info?.company_name}`,
          type: PortalEntityType.VENDOR,
        };
      }),
    );
  }

  private getParentEntity(parentId: string): Observable<PortalEntity> {
    return this.api.getUserProfile({ owner_id: parentId, role: 'customer' }).pipe(
      map((res) => {
        return {
          entityId: parentId,
          type: PortalEntityType.PARENT,
          label: `${res.first_name} ${res.last_name}`,
        };
      }),
    );
  }

  private getRideEntity(rideId: string): Observable<PortalEntity> {
    return this.api.getRide({ rideId }).pipe(
      map((res) => {
        return {
          entityId: rideId,
          label: `Ride ${res.ride.displayId}`,
          type: PortalEntityType.RIDE,
        };
      }),
    );
  }

  private getRouteEntity(routeId: string): Observable<PortalEntity> {
    return this.api.getRoute({ route_id: routeId }).pipe(
      map((res) => {
        return {
          entityId: routeId,
          label: `Route ${res.display_id}`,
          type: PortalEntityType.ROUTE,
        };
      }),
    );
  }

  private getVehicleEntity(vehicleId: string): Observable<VehiclePortalEntity> {
    return this.api.getVehicle({ vehicleId, _projections: [GetVehicleProjections.DRIVER] }).pipe(
      map((res) => {
        let label = `${res.vehicle.make} ${res.vehicle.model}`;
        if (res.vehicle.vehicleDisplayId) {
          label = `${label}, ${res.vehicle.vehicleDisplayId}`;
        }
        return {
          entityId: vehicleId,
          label,
          type: PortalEntityType.VEHICLE,
          makeAndModel: `${res.vehicle.make} ${res.vehicle.model}`,
          vehicleDisplayId: res.vehicle.vehicleDisplayId,
          licenseStateAndNumber: `${res.vehicle.license.state} ${res.vehicle.license.number}`,
          imagePath: res.vehicle.vehicleImagePath,
          make: res.vehicle.make,
        } as VehiclePortalEntity;
      }),
    );
  }

  private getYardEntity(yardId: string): Observable<PortalEntity> {
    return this.api.getYard({ yardId }).pipe(
      map((res) => {
        return {
          entityId: yardId,
          label: res.yard.name,
          type: PortalEntityType.YARD,
        };
      }),
    );
  }

  private getActivityEntity(activityId: string): Observable<PortalEntity> {
    return this.api.getActivity({ activityId }).pipe(
      map((res) => {
        return {
          entityId: activityId,
          label: res.activity.activityName,
          type: PortalEntityType.ACTIVITY_TIME_ENTRY,
        };
      }),
    );
  }

  private getRouteGroupEntity(routeGroupId: string): Observable<PortalEntity> {
    return this.api.getRouteGroup({ routeGroupId }).pipe(
      map((res) => {
        return {
          entityId: routeGroupId,
          label: res.routeGroupName,
          type: PortalEntityType.ROUTE_GROUP,
        };
      }),
    );
  }
}
