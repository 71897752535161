<div
  class="y-widget-menu__contents"
  [ngClass]="{
          'open': isOpen$ | async,
          'half-drawer': isHalfDrawer$ | async,
      }"
>
  <router-outlet name="drawer"></router-outlet>
</div>

<div
  *ngIf="isOpen$ | async"
  class="y-widget-menu__page-mask"
  (click)="onMaskClicked()"
></div>
