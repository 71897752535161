import { Observable, throwError } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { DatabaseStructure, TableNames } from './index-db-config';
import { IndexDbConnectionState } from './connection-state';
import { IndexedDbQuery, QueryResult } from './indexed-db-query';
import { TableConfig } from './index-db-config';

export class IndexDbTable<T extends TableNames> {
  private name: string;
  constructor(
    public config: TableConfig<T>,
    private database$$: Observable<IndexDbConnectionState>,
  ) {
    this.name = config.tableName;
  }

  public get(key: string, transaction?: IDBTransaction): Observable<DatabaseStructure[T]> {
    if (transaction) {
      return new Observable<DatabaseStructure[T]>((observer) => {
        const objectStore = transaction.objectStore(this.name);
        const request = objectStore.get(key);
        request.onsuccess = () => {
          observer.next(request.result as DatabaseStructure[T]);
          observer.complete();
        };
        request.onerror = (event) => {
          console.error('[IndexDB]: Failed to get ', key, ' from ', this.name, event);
          observer.error(event);
        };
      });
    }
    return this.getDatabase$().pipe(
      switchMap(({ database, error }) => {
        if (error) {
          return throwError(error);
        }
        const tn = database.transaction([this.name]);
        const objectStore = tn.objectStore(this.name);
        const request = objectStore.get(key);
        return new Observable<DatabaseStructure[T]>((observer) => {
          tn.oncomplete = function (event) {
            observer.next(request.result as DatabaseStructure[T]);
            observer.complete();
          };

          request.onsuccess = function () {
            observer.next(request.result as DatabaseStructure[T]);
            observer.complete();
          };

          tn.onerror = (event) => {
            console.error('[IndexDB]: Failed to get ', key, ' from ', this.name, event);
            observer.error(event);
          };
        });
      }),
    );
  }

  public query(
    q: IndexedDbQuery<DatabaseStructure[T]>,
    removeIf?: (key: string, item: DatabaseStructure[T]) => boolean,
    transaction?: IDBTransaction,
  ): Observable<QueryResult<DatabaseStructure[T]>[]> {
    const { field, equalTo, upperBound, upperInclusive, lowerBound, lowerInclusive, direction, limit } = q;
    const result: QueryResult<DatabaseStructure[T]>[] = [];
    const bounds = equalTo
      ? IDBKeyRange.only(equalTo)
      : upperBound && lowerBound
        ? IDBKeyRange.bound(lowerBound, upperBound, lowerInclusive, upperInclusive)
        : lowerBound
          ? IDBKeyRange.lowerBound(lowerBound, lowerInclusive)
          : upperBound
            ? IDBKeyRange.upperBound(upperBound, upperInclusive)
            : undefined;
    const doForTransaction = (t: IDBTransaction): Observable<QueryResult<DatabaseStructure[T]>[]> => {
      const store = t.objectStore(this.name);
      const request = store.indexNames.contains(field as string)
        ? store.index(field as string).openCursor(bounds, direction)
        : store.openCursor(bounds, direction);
      return new Observable<QueryResult<DatabaseStructure[T]>[]>((observer) => {
        request.onsuccess = function () {
          const cursor = request.result;
          if (cursor && (!limit || result.length <= limit)) {
            const key = cursor.primaryKey as string;
            const value = cursor.value;
            result.push({ key, data: value });
            if (removeIf && removeIf(key, value)) {
              cursor.delete();
            }
            cursor.continue();
          } else {
            observer.next(result);
            observer.complete();
          }
        };
        request.onerror = function (error) {
          observer.error(error);
        };
      });
    };
    if (transaction) {
      return doForTransaction(transaction);
    }
    return this.getDatabase$().pipe(
      switchMap(({ database, error }) => {
        if (error) {
          return throwError(error);
        }
        const t: IDBTransaction = database.transaction([this.name], removeIf ? 'readwrite' : 'readonly');
        return doForTransaction(t);
      }),
    );
  }

  public upsert(key: string, value: DatabaseStructure[T], transaction?: IDBTransaction): Observable<void> {
    if (transaction) {
      return new Observable<void>((observer) => {
        const objectStore = transaction.objectStore(this.name);
        const request = objectStore.put(value, key);
        request.onsuccess = function () {
          observer.next();
          observer.complete();
        };
        request.onerror = function (error) {
          observer.error(error);
        };
      });
    }
    return this.getDatabase$().pipe(
      switchMap(({ database, error }) => {
        if (error) {
          return throwError(error);
        }
        const tn: IDBTransaction = database.transaction([this.name], 'readwrite');
        return new Observable<void>((observer) => {
          // Complete when all the data is added to the database.
          tn.oncomplete = function (event) {
            observer.next();
            observer.complete();
          };

          tn.onerror = (event) => {
            console.error('Failed to upsert into: ', this.name, ' > ', key, event);
            observer.error(event);
          };

          const objectStore = tn.objectStore(this.name);
          objectStore.put(value, key);
        });
      }),
    );
  }

  public remove(key: string, transaction?: IDBTransaction): Observable<void> {
    if (transaction) {
      return new Observable<void>((observer) => {
        const store = transaction.objectStore(this.name);
        const request = store.delete(key);
        request.onsuccess = () => {
          observer.next();
          observer.complete();
        };
        request.onerror = (error) => {
          observer.error(error);
        };
      });
    }
    return this.getDatabase$().pipe(
      switchMap(({ database, error }) => {
        if (error) {
          return throwError(error);
        }
        const tn: IDBTransaction = database.transaction([this.name], 'readwrite');
        const store = tn.objectStore(this.name);
        store.delete(key);
        return new Observable<void>((observer) => {
          tn.oncomplete = function () {
            observer.complete();
          };
          tn.onerror = function (error) {
            observer.error(error);
          };
        });
      }),
    );
  }

  private getDatabase$(): Observable<IndexDbConnectionState> {
    return this.database$$.pipe(
      filter((db) => !!db),
      take(1),
    );
  }
}
