<wp-side-bar
  [activeId]="selectedSection$ | async"
  [isExpanded]="sideBarExpanded$ | async"
  (expandedChanged)="onSidebarExpandedChanged()"
>
  <wp-side-bar-top>
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngIf="tab.isInMenuForUser$ | async">
        <a
          [href]="tab.link$ | async"
          wpScrollIntoView
          [shouldScrollIntoView]="(selectedSection$ | async) === tab.id"
          [scrollBehavior]="'smooth'"
          [scrollBlock]="'end'"
        >
          <wp-side-bar-item
            class="wp-side-bar__item"
            [id]="tab.id"
            [displayIconTooltip]="tab.name"
            [isSideBarOpened]="sideBarExpanded$ | async"
            (click)="$event.stopPropagation(); $event.preventDefault(); onSideBarItemClick(tab);"
          >
            <wp-side-bar-icon-wrap>
              <wp-side-bar-icon [type]="tab.id"></wp-side-bar-icon>
            </wp-side-bar-icon-wrap>
            <wp-side-bar-text
              [id]="tab.id"
              [text]="tab.name"
              [smallFont]="tab.smallFont"
              [isShown]="sideBarExpanded$ | async"
            >
            </wp-side-bar-text>
          </wp-side-bar-item>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isFeatureRouteManagement$ | async">
      <wp-side-bar-item
        class="wp-side-bar__item"
        [displayIconTooltip]="'Route explorer'"
        [isSideBarOpened]="sideBarExpanded$ | async"
        (click)="$event.stopPropagation(); $event.preventDefault(); onRouteManagementMenuClick();"
      >
        <wp-side-bar-icon-wrap>
          <wp-side-bar-icon [type]="'route-management'"></wp-side-bar-icon>
        </wp-side-bar-icon-wrap>
        <wp-side-bar-text
          [isShown]="sideBarExpanded$ | async"
          [text]="'Route explorer'"
          [smallFont]="false"
        >
        </wp-side-bar-text>
      </wp-side-bar-item>
    </ng-container>
  </wp-side-bar-top>

  <wp-side-bar-bottom>
    <wp-profile-menu [isTextShown]="sideBarExpanded$ | async"></wp-profile-menu>

    <div class="account" *ngIf="accountShown$ | async">
      <wp-side-bar-icon-wrap>
        <wp-display-photo
          [width]="'32px'"
          [height]="'32px'"
          [imagePath]="accountImagePath$ | async"
          [downloadImageSize]="{ width: 100, height: 100 }"
          [placeholderType]="placeholderTypes.BUILDING"
          [isRound]="true"
        ></wp-display-photo>
      </wp-side-bar-icon-wrap>

      <wp-side-bar-text
        [text]="accountName$ | async"
        [smallFont]="true"
        [isShown]="sideBarExpanded$ | async"
      >
      </wp-side-bar-text>
    </div>
  </wp-side-bar-bottom>
</wp-side-bar>
