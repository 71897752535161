import { ServerRequestCacheItem } from './tables';

export const EXECUTE_AS_TRANSACTION_EVENT_BUFFER_TIME_SPAN = 200;

/**
 * Adding new tables:
 * 1. Update table names with the new table name.
 * 2. Update DatabaseStructure with the types of the data to be found in the new table.
 * 3. Update portalIndexDbConfig with table options (do not forget to increment database version).
 */

export enum TableNames {
  server_requests_cache = 'server_requests_cache',
}

export type DatabaseStructure = {
  [TableNames.server_requests_cache]: ServerRequestCacheItem;
};

export type IndexDbConfig = {
  version: number;
  dbName: string;
  tables: {
    [N in TableNames]: TableConfig<N>;
  };
};

export interface TableConfig<T extends TableNames> {
  tableName: T;
  options: IDBObjectStoreParameters & {
    indexes?: {
      [fieldName in keyof DatabaseStructure[T]]?: IDBIndexParameters;
    };
  };
}

/**
 * Increment version on each update to table structure
 */
export const portalIndexDbConfig: IndexDbConfig = {
  version: wpEnvironment.indexedDbVersion,
  dbName: 'zum_portal_db',
  tables: {
    [TableNames.server_requests_cache]: {
      tableName: TableNames.server_requests_cache,
      options: {
        indexes: {
          lastUsedTimestamp: { unique: false },
        },
      },
    }, // eof server_requests_cache
  },
};
