import { Injectable } from '@angular/core';

import { TripListParams, TripListQuickFilterType } from '../../../features/trips/types/trip-list';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getInitialTripListState } from '../../../features/trips/utils/get-initial-trip-list-state';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import {
  deserializeQuickFilter,
  getTripListQuickFilter,
} from '../../../features/trips/utils/get-trip-list-quick-filter';
import { deserializeLocationFilter } from '../../../features/trips/utils/get-trip-list-location-filter';
import { deserializeStatusFilter } from '../../../features/trips/utils/get-trip-list-status-filter';
import { deserializeTripListTypeFilter } from '../../../features/trips/utils/get-trip-list-type-filter';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { NavigationRequest } from '../../types/navigation-request';
import { RouterStateUrl } from '@rootStore';
import { tripsPath } from './trips-path';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { deserializePaymentStatusFilter } from '../../../features/trips/utils/get-trip-list-payment-status-filter';
import { deserializeVehicleTypeFilter } from '../../../features/trips/utils/get-trip-list-vehicle-type-filter';
import { deserializeTripListModificationFilter } from '../../../features/trips/utils/get-trip-list-modification-filter';

export type TripListRouteData = Partial<TripListParams>;

export const tripListPath = 'list';

export const tripListRoute: PortalRoute<TripListRouteData> = {
  path: tripListPath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.includes(`${tripsPath}/${tripListPath}`);
  },
  parse(state: RouterStateUrl): TripListRouteData {
    const deserialized = deserializeBaseParamsFromUrl<TripListParams>(state.queryParams, 'tl', [
      deserializeQuickFilter,
      deserializeLocationFilter,
      deserializeStatusFilter,
      deserializeTripListTypeFilter,
      deserializePaymentStatusFilter,
      deserializeVehicleTypeFilter,
      deserializeTripListModificationFilter,
    ]);
    if (!deserialized.filters?.length) {
      deserialized.filters = [getTripListQuickFilter(TripListQuickFilterType.STARTING_TODAY)];
    }
    return deserialized;
  },
  request(
    data?: TripListRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const path = [tripsPath, tripListPath];
    const params = data || {};
    if (!params.filters?.length) {
      params.filters = [getTripListQuickFilter(TripListQuickFilterType.STARTING_TODAY)];
    }
    const serialized = serializeBaseParamsToUrl(params, getInitialTripListState().params, 'tl');
    return getRoutingRequest(path, serialized, queryParamStrategy, replaceUrl);
  },
  getHref(data?: TripListRouteData): string {
    return `/${tripsPath}/${tripListPath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class TripListRouterService extends PortalRouterService<TripListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = tripListRoute;
}
