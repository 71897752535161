import { GetRouteWaypointsRequest, GetRouteWaypointsResponse } from '../endpoints/get-route-waypoints';

export enum CachedAPIs {
  getRouteWaypoints = 'getRouteWaypoints',
  testCache = 'testCache',
}

export type APITypeMapping = {
  [CachedAPIs.getRouteWaypoints]: {
    request: GetRouteWaypointsRequest;
    response: GetRouteWaypointsResponse;
  };
  [CachedAPIs.testCache]: {
    request: number;
    response: string;
  };
};

export const INDEX_DB_TIMEOUT_MILLIS = 30000;
export const RETRY_TIMEOUT = 1000;
export const MAX_RETRY_COUNT = 10;
export const MAX_ALLOWED_PERCENTAGE_OF_QUOTA_USE = 40;
export const REMOVE_LEAST_RECENT_ITEMS_ON_QUOTA_VIOLATION = 400;
