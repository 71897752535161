import { Campus } from '../../../../types/entities/campus';
import { ApiCampus, Check } from '../../common';

export const getCampusFromApi = (source: ApiCampus, checks?: Check[]): Campus => {
  const target: Campus = {
    id: source.id,
    address: source.address,
    districtId: source.districtId,
    name: source.name,
    contact: {
      name: source.contact?.name,
      phone: source.contact?.phoneNumber,
      position: source.contact?.position,
    },
    gradesV2: source.gradesV2,
    websiteUrl: source.websiteUrl,
    logoPath: source.logoPath,
    checks: checks || [],
  };
  return target;
};
