import { Injectable } from '@angular/core';

import { Configuration, init, LogArgument } from 'rollbar';

import { RollbarIgnoreService } from './rollbar-ignore.service';
import { RollbarFingerprintLike } from './utils/rollbar-fingerprint-like';

/**
 * This service wraps Rollbar library instance.
 */
@Injectable()
export class RollbarService {
  private rollbar: ReturnType<typeof init>;

  constructor(private ignoreService: RollbarIgnoreService) {
    /**
     * https://docs.rollbar.com/docs/rollbarjs-configuration-reference
     */
    const rollbarConfig: Configuration = {
      // Whether to send errors/logs to Rollbar
      enabled: wpEnvironment.rollbarEnabled,
      environment: wpEnvironment.envName,
      accessToken: wpEnvironment.rollbarPostClientToken,
      // Uncaught exceptions are handled by RollbarErrorHandler
      captureUncaught: false,
      // All promise rejections are handled by ApiService or each feature module db service
      captureUnhandledRejections: false,
      // The severity level used for calls to Rollbar.log()
      logLevel: 'warning',
      /**
       * Used to filter out which messages will get reported to Rollbar.
       * @example 'warning' will report warning or higher
       */
      reportLevel: 'warning',
      // Whether or not the client also logs to console.log, in addition to sending the item to Rollbar.
      // We should not enable this because of extending console methods.
      verbose: false,
      // Telemetry config
      autoInstrument: {
        // do not log xhr request
        network: false,
        // do not send console.log to Telemetry
        log: false,
        dom: true,
        navigation: true,
        connectivity: true,
      },
      // Hide form input values
      scrubTelemetryInputs: true,
      maxTelemetryEvents: 50,
      scrubFields: ['token'],
      payload: {
        client: {
          javascript: {
            // The version number can't contain any whitespace or it will break
            code_version: wpEnvironment.gitVersion,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
    };
    this.rollbar = init(rollbarConfig);
    if (wpEnvironment.rollbarEnabled) {
      this.extendConsoleMethods();
    }
  }

  public configure(options: Configuration): void {
    if (wpEnvironment.rollbarEnabled) {
      this.rollbar.configure(options);
    }
  }

  /**
   * @args should contain 1 or 2 arguments, the second argument is an optional custom payload
   * @example error(new Error('Text'), { code: 400, message: 'Some reason' })
   *
   * @see https://docs.rollbar.com/docs/custom-log-messages#section-javascript
   */
  public error(...args: LogArgument[]): void {
    if (wpEnvironment.rollbarEnabled) {
      if (!this.ignoreService.checkIgnore(...args)) {
        const first = args[0];
        if (this.isFingerprintLike(first)) {
          // Configure item with the custom fingerprint if it is present,
          // see: https://docs.rollbar.com/docs/grouping-algorithm#section-customizing-fingerprints-in-code
          this.configure({ payload: { fingerprint: first.fingerprint } });
        }
        this.rollbar.error(...args);
        // Since the configure sets configuration globally,
        // we must reset fingerprint just after sending the item
        if (this.isFingerprintLike(this.rollbar.options.payload)) {
          this.configure({ payload: { fingerprint: undefined } });
        }
      }
    }
  }

  public warning(...args: LogArgument[]): void {
    if (wpEnvironment.rollbarEnabled) {
      if (!this.ignoreService.checkIgnore(...args)) {
        this.rollbar.warning(...args);
      }
    }
  }

  private extendConsoleMethods(): void {
    const error = console.error;
    const warn = console.warn;
    const self = this;

    console.error = function (...args) {
      self.error(...args);
      error.apply(console, arguments);
    };

    console.warn = function (...args) {
      self.warning(...args);
      warn.apply(console, arguments);
    };
  }

  private isFingerprintLike(obj: any): obj is RollbarFingerprintLike {
    return typeof obj === 'object' && !!obj.fingerprint;
  }
}
