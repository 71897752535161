import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { PortalPermissionItem } from '@rootTypes';
import { combineLatest, filter, map, Observable, of } from 'rxjs';
import { isMultiAccountManagement } from '../selectors/multi-account-management.selectors';
import {
  isAnyPermissions,
  isEitherPermissionGivenForAccount,
  isPermissionGivenForAccount,
} from '../selectors/portal-permissions.selectors';

@Injectable()
export class PortalPermissionsFacade {
  constructor(private store: Store<State>) {}

  public isAccessManagement$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.USER_PERMISSION_EDIT))
      .pipe(map((hasUserPermissionEditAccess) => hasUserPermissionEditAccess && wpEnvironment.userRole === 'admin'));
  }

  public isAccountingView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ACCOUNTING_TRANSACTION_VIEW));
  }

  public isActivityBulkUploadApproveEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ACTIVITY_BULK_UPLOAD_APPROVE_EDIT));
  }

  public isActivityBulkUploadEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ACTIVITY_BULK_UPLOAD_EDIT));
  }

  public isAnyPermissions$(): Observable<boolean> {
    return this.store.select(isAnyPermissions).pipe(filter((isAny) => typeof isAny === 'boolean'));
  }

  public isCampusEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CAMPUS_EDIT));
  }

  public isCampusView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CAMPUS_VIEW));
  }

  public isCharterTripBookingEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_BOOKING_EDIT));
  }

  public isCharterTripsBookingAmountView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_BOOKING_AMOUNT_VIEW));
  }

  public isCharterTripExportEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_EXPORT_EDIT));
  }

  public isCharterTripPricingView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_PRICING_VIEW));
  }

  public isCharterTripRideView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_RIDE_VIEW));
  }

  public isCharterTripStatusHistoryView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_STATUS_LOG_VIEW));
  }

  public isCharterTripInvoiceView$(): Observable<boolean> {
    return this.store.select(
      isPermissionGivenForAccount(PortalPermissionItem.CHARTER_PORTAL_TRIP_INVOICE_HISTORY_VIEW),
    );
  }

  public isCharterTripDetailsView$(): Observable<boolean> {
    return this.store.select(
      isEitherPermissionGivenForAccount(
        PortalPermissionItem.CHARTER_TRIP_VIEW,
        PortalPermissionItem.CHARTER_TRIP_DETAILS_VIEW,
      ),
    );
  }

  public isCharterTripsView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_VIEW));
  }

  public isCharterTripsInvoicingEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_INVOICE_EDIT));
  }

  public isCharterTripStatusEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_TRIP_STATUS_EDIT));
  }

  public isCharterVendorAssignmentEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.CHARTER_VENDOR_ASSIGNMENT_EDIT));
  }

  public isDashboardCloseShifts$(): Observable<boolean> {
    if (wpEnvironment.userRole !== 'vendor') {
      return of(false);
    }
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_ASSIGNMENT_EDIT));
  }

  public isDashboardMapView$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.DASHBOARD_VIEW))
      .pipe(map((isMapView) => isMapView && wpEnvironment.userRole !== 'admin'));
  }

  public isDashboardMapViewByVehicle$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.GEOTAB_DASHBOARD_VIEW));
  }

  public isDashboardView$(): Observable<boolean> {
    return combineLatest([this.isDashboardMapView$(), this.isDispatchControlCenterView$()]).pipe(
      map((flags) => flags.some((flag) => flag)),
    );
  }

  public isDispatchControlCenterView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DISPATCH_CONTROL_CENTER_VIEW)).pipe(
      map((isView) => {
        return isView && wpEnvironment.userRole !== 'admin';
      }),
    );
  }

  public isDispatchControlCenterPastView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DISPATCH_CONTROL_CENTER_VIEW)).pipe(
      map((isView) => {
        return isView && wpEnvironment.userRole !== 'admin';
      }),
    );
  }

  public isDistrictEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DISTRICT_EDIT));
  }

  public isDistrictProgramEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DISTRICT_PROGRAM_EDIT));
  }

  public isDistrictView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DISTRICT_VIEW));
  }

  public isDriverBusReportView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_BUS_REPORT_VIEW));
  }

  public isDriverBusReportEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_BUS_REPORT_EDIT));
  }

  public isDriverBusReportApprove$(): Observable<boolean> {
    return this.store.select(
      isEitherPermissionGivenForAccount(
        PortalPermissionItem.DRIVER_BUS_REPORT_APPROVE_EDIT,
        PortalPermissionItem.DRIVER_BUS_REPORT_RAW_OCR_EDIT,
      ),
    );
  }

  public isDriverBusReportApproveVerify$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_BUS_REPORT_APPROVE_VERIFY));
  }

  public isDriverBusReportRawOCREdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_BUS_REPORT_RAW_OCR_EDIT));
  }

  public isDriverDocumentEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_DOCUMENT_EDIT));
  }

  public isDriverDocumentExpirationView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_DOCUMENT_EXPIRATION_VIEW));
  }

  public isDriverDocumentView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_DOCUMENT_VIEW));
  }

  public isDriverEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_EDIT));
  }

  public isMonitorEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.MONITOR_EDIT));
  }

  public isMonitorView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.MONITOR_VIEW));
  }

  public isDriverLoginEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_LOGIN_EDIT));
  }

  public isDriverStatusEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_STATUS_EDIT));
  }

  public isDriverHardSyncActivities$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ACTIVITY_CHR_SYNC));
  }

  public isDriverVehicleEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_EDIT));
  }

  public isDriverView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_VIEW));
  }

  public isEndOfDayInspectionView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.END_OF_DAY_INSPECTION_VIEW));
  }

  public isEndOfRideInspectionView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.END_OF_RIDE_INSPECTION_VIEW));
  }

  public isFirebaseCacheEditPermission$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.FIREBASE_CACHE_EDIT));
  }

  public isManualRideTagEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.MANUAL_RIDE_TAG_EDIT));
  }

  public isMultiAccountManagement$(): Observable<boolean> {
    return this.store.select(isMultiAccountManagement);
  }

  public isParentRouteNotificationSend$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARENT_ROUTE_NOTIFICATION_SEND));
  }

  public isParentRouteNotificationMailersDownload$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARENT_ROUTE_NOTIFICATION_MAILERS));
  }

  public isParkoutEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARKOUT_EDIT)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isParkoutEventView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARKOUT_EVENT_VIEW)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isParkoutEventApproveEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARKOUT_EVENT_APPROVE_EDIT)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isParkoutView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PARKOUT_VIEW)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isPublishExplorerRoute$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.PUBLISH_EXPLORER_ROUTE));
  }

  public isReportStudentRfidExecute$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.REPORT_STUDENT_RFID_EXECUTE));
  }

  public isReportView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.REPORT_VIEW));
  }

  public isRideAssignmentEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_ASSIGNMENT_EDIT));
  }

  public isRideBulkNotificationsEnabled$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_BULK_NOTIFICATIONS));
  }

  public isRideLogs$(): Observable<boolean> {
    return of(wpEnvironment.userRole === 'admin' || wpEnvironment.userRole === 'vendor');
  }

  public isRideVendorAssignmentHistoryView$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_VENDOR_ASSIGNMENT_HISTORY_VIEW))
      .pipe(map((isPermission) => wpEnvironment.userRole === 'admin' && isPermission));
  }

  public isRideView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_VIEW));
  }

  public isRouteExplorerView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_EXPLORER_VIEW));
  }

  public isRouteExplorerEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_EXPLORER_EDIT));
  }

  public isRouteExplorerViolationView(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_EXPLORER_VIOLATION_VIEW));
  }

  public isRouteGroupApproveEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_GROUP_APPROVE_EDIT));
  }

  public isRouteGroupAssignYard$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.INTER_YARD_MANAGEMENT_EDIT));
  }

  public isRouteGroupChangeEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_GROUP_CHANGE_EDIT));
  }

  public isRouteGroupCreateEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_GROUP_CREATE_EDIT));
  }

  public isRouteGroupView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_GROUP_VIEW));
  }

  public isRouteMockRideCreate$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_MOCK_RIDE_CREATE));
  }

  public isRouteSheetPrint$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_SHEET_PRINT));
  }

  public isManageRunCalendarEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_CALENDAR_EDIT));
  }

  public isRouteView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.ROUTE_VIEW));
  }

  public isSchoolEmployeeEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.SCHOOL_EMPLOYEE_EDIT));
  }

  public isSchoolEmployeeMainListView$(): Observable<boolean> {
    return this.isSchoolEmployeeView$().pipe(
      map((isPermission) => isPermission && wpEnvironment.featureSchoolEmployees),
    );
  }

  public isSchoolEmployeePermissionEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.SCHOOL_EMPLOYEE_PERMISSION_EDIT));
  }

  public isSchoolEmployeeView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.SCHOOL_EMPLOYEE_VIEW));
  }

  public isSchoolSupportImpersonation$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.SCHOOL_SUPPORT_IMPERSONATION_EXECUTE))
      .pipe(
        map((isPermission) => {
          return isPermission && wpEnvironment.userRole === 'admin';
        }),
      );
  }

  public isStandbyDriverEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STANDBY_DRIVER_EDIT));
  }

  public isStandbyDriverView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STANDBY_DRIVER_VIEW));
  }

  public isDriverCommunicationsView$(): Observable<boolean> {
    // return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_COMMUNICATIONS_VIEW));
    return of(true);
  }

  public isStudentAssignVendorEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_ASSIGN_VENDOR_EDIT));
  }

  public isStudentChangeRequestLogView$(): Observable<boolean> {
    return this.isStudentEdit$();
  }

  public isStudentChangeRequestMyRequestsView$(): Observable<boolean> {
    return this.store.select(
      isPermissionGivenForAccount(PortalPermissionItem.STUDENT_CHANGE_REQUEST_SELF_REQUEST_VIEW),
    );
  }

  public isStudentEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_EDIT));
  }

  public isStudentRfidDistrictConfigView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_RFID_DISTRICT_CONFIG_VIEW));
  }

  public isStudentRfidDownloadEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_RFID_DOWNLOAD_EDIT));
  }

  public isStudentRfidOrderEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_RFID_ORDER_EDIT));
  }

  public isStudentRfidOrderView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_RFID_ORDER_VIEW));
  }

  public isStudentRfidWorkQueueEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_RFID_WORKQUEUE_EDIT));
  }

  public isStudentView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.STUDENT_VIEW));
  }

  public isTimesheetEntryEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.TIMESHEET_ENTRY_EDIT));
  }

  public isTimesheetEntryView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.TIMESHEET_ENTRY_VIEW));
  }

  public isTransportationRegistrationBusLookupView$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.TRANSPORTATION_REGISTRATION_BUS_LOOKUP_VIEW))
      .pipe(
        map((isPermission) => {
          return isPermission && wpEnvironment.userRole === 'enterprise';
        }),
      );
  }

  public isTransportationRegistrationEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.TRANSPORTATION_REGISTRATION_EDIT));
  }

  public isTransportationRegistrationView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.TRANSPORTATION_REGISTRATION_VIEW));
  }

  public isVehicleInspectionAssessmentEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_INSPECTION_ASSESSMENT_EDIT));
  }

  public isVehicleInspectionMechanicAssignmentEdit$(): Observable<boolean> {
    return this.store.select(
      isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_INSPECTION_MECHANIC_ASSIGNMENT_EDIT),
      isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_INSPECTION_MECHANIC_ASSIGNMENT_EDIT),
    );
  }

  public isVehicleInspectionView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_INSPECTION_VIEW));
  }

  public isVehicleView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_VIEW));
  }

  public isVehicleUpdateYard$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_EDIT));
  }

  public isVendorEmployeeEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VENDOR_EMPLOYEE_EDIT));
  }

  public isVendorEmployeeMainListView$(): Observable<boolean> {
    return this.isVendorEmployeeView$().pipe(
      map((isPermission) => isPermission && wpEnvironment.featureVendorEmployees),
    );
  }

  public isVendorEmployeePermissionEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VENDOR_EMPLOYEE_PERMISSION_EDIT));
  }

  public isVendorEmployeeView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VENDOR_EMPLOYEE_VIEW));
  }

  public isVendorSupportImpersonation$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.VENDOR_SUPPORT_IMPERSONATION_EXECUTE))
      .pipe(
        map((isPermission) => {
          return isPermission && wpEnvironment.userRole === 'admin';
        }),
      );
  }

  public isVendorVehicleEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VEHICLE_EDIT)).pipe(
      map((isEdit) => {
        return isEdit && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isVendorView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.VENDOR_VIEW));
  }

  public isViewRideDetailsInAdminDash$(): Observable<boolean> {
    return of(wpEnvironment.userRole === 'admin' && !!wpEnvironment.adminDashboardUrl);
  }

  public isStudentAttendanceEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.RIDE_ATTENDANCE_EDIT));
  }

  public isViewStudentProfileInAdminDashboard$(): Observable<boolean> {
    return of(wpEnvironment.userRole === 'admin' && !!wpEnvironment.adminDashboardUrl);
  }

  public isWorkQueueView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.WORK_QUEUE_VIEW));
  }

  public isYardView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.YARD_VIEW));
  }

  public isDriverCommunicationView$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_COMMUNICATION_VIEW))
      .pipe(map((value) => value && wpEnvironment.userRole === 'vendor'));
  }

  public isDriverCommunicationEdit$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_COMMUNICATION_EDIT))
      .pipe(map((value) => value && wpEnvironment.userRole === 'vendor'));
  }

  public isDriverCommunicationApproveEdit$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_COMMUNICATION_APPROVE_EDIT))
      .pipe(map((value) => value && wpEnvironment.userRole === 'vendor'));
  }

  public isDistrictBulkNotifications$(): Observable<boolean> {
    return this.store
      .select(isPermissionGivenForAccount(PortalPermissionItem.DISTRICT_BULK_NOTIFICATIONS))
      .pipe(map((value) => value && wpEnvironment.userRole === 'enterprise'));
  }

  public isDriverAttendanceView$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_ATTENDANCE_VIEW)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }

  public isDriverAttendanceEdit$(): Observable<boolean> {
    return this.store.select(isPermissionGivenForAccount(PortalPermissionItem.DRIVER_ATTENDANCE_EDIT)).pipe(
      map((hasPermission) => {
        return hasPermission && wpEnvironment.userRole === 'vendor';
      }),
    );
  }
}
