<div class="map-content">
  <div class="recenter-button-wrap">
    <wp-recenter-button (click)="onRecenterClik()"></wp-recenter-button>
  </div>
  <div #map class="map-div"></div>
  <div *ngIf="mapTilesLoading$ | async" class="map-tiles-loading-mask">
    <wp-section-spinner
      [sectionHeight]="'40px'"
      [spinnerSize]="40"
    ></wp-section-spinner>
  </div>
</div>
