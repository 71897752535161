/**
 * This module provides Rollbar error handling system to the entire application.
 * This module is imported by AppModule only, feature modules must not import it.
 * Docs:
 * @see https://docs.rollbar.com/docs/angular
 * @see https://docs.rollbar.com/docs/javascript
 */

import { NgModule, ErrorHandler } from '@angular/core';

import { RollbarErrorHandler } from './rollbar-error-handler.service';
import { RollbarIgnoreService } from './rollbar-ignore.service';
import { RollbarService } from './rollbar.service';
import { RollbarSessionService } from './rollbar-session.service';

@NgModule({
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    RollbarIgnoreService,
    RollbarService,
    RollbarSessionService,
  ],
})
export class RollbarModule {}
