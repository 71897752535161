import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { switchMap, filter, map } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ComponentItem } from '../types/component-item';
import { DrawerRef } from '../types/drawer-ref';
import { DrawerConfig } from '../types/drawer-config';

@Component({
  selector: 'wp-drawer',
  templateUrl: './widget-menu.component.html',
  styleUrls: ['./widget-menu.component.scss'],
})
export class DrawerComponent implements AfterViewInit, OnInit {
  public static open(config: DrawerConfig): DrawerRef {
    const drawerRef = new DrawerRef(config);
    this.drawerRefSubj.next(drawerRef);
    return drawerRef;
  }

  private static drawerRefSubj: BehaviorSubject<DrawerRef | null> = new BehaviorSubject<DrawerRef | null>(null);
  public static close(): void {
    DrawerComponent.closedPopup.next(undefined);
    DrawerComponent.componentSubj.next(null);
  }

  private static componentSubj: BehaviorSubject<ComponentItem | null> = new BehaviorSubject<ComponentItem | null>(null);
  private static closedPopup: Subject<any> = new Subject<any>();

  @Output() public maskClicked: EventEmitter<void> = new EventEmitter<void>();
  public isOpen$: Observable<boolean> | undefined;
  public isHalfDrawer$: Observable<boolean>;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.isOpen$ = DrawerComponent.drawerRefSubj.asObservable().pipe(map((ref) => !!ref));
    this.isHalfDrawer$ = DrawerComponent.drawerRefSubj.asObservable().pipe(map((ref) => !!ref?.config?.isHalfDrawer));

    DrawerComponent.drawerRefSubj
      .asObservable()
      .pipe(
        filter((ref) => !!ref),
        switchMap((ref) => ref.closed$()),
      )
      .subscribe((closed) => {
        DrawerComponent.drawerRefSubj.next(null);
      });
  }

  public onClose(): void {
    this.closeAll();
  }

  public closeAll(): void {
    const currentRef = DrawerComponent.drawerRefSubj.value;
    if (currentRef) {
      currentRef.close();
    }
  }

  public onMaskClicked(): void {
    this.maskClicked.emit();
  }
}
