import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlCreationOptions, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRootStore from '@rootStore';
import { CustomRouteSerializer, RouterStateUrl, State } from '@rootStore';
import { RouterOutlets } from '@rootTypes/entities/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationRequest } from './navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from './portal-route';
import { PortalRouterLinkData } from './portal-router-link-data';

@Injectable()
export abstract class PortalRouterService<D> {
  abstract route: PortalRoute<D>;

  public outlet: RouterOutlets;

  private routeSerializer = new CustomRouteSerializer();

  constructor(
    private store: Store<State>,
    private location: Location,
    private router: Router,
  ) {}

  public isOnRoute$(): Observable<boolean> {
    return this.getCurrentRouterState$().pipe(map((state) => this.route.isOnRoute(state)));
  }

  public parse$(): Observable<D> {
    return this.getCurrentRouterState$().pipe(map((state) => this.route.parse(state)));
  }

  public snapshot(routerState?: RouterStateSnapshot): D {
    const serialized = this.routeSerializer.serialize(routerState ?? this.router.routerState.snapshot);
    return this.route.parse(serialized);
  }

  public getAbsoluteUrlLink(data: D): string {
    return window.origin + this.getRelativeUrlLink(data);
  }

  public getRelativeUrlLink(data: D): string {
    return this.getUrlTree(data).toString();
  }

  public getHref(data: D): string {
    const href = 'getHref' in this.route ? this.route.getHref(data) : undefined;
    if (href) {
      return href;
    } else {
      throw new Error('getHref not implemented for this route.');
    }
  }

  public getUrlTree(
    data: D,
    queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
    replaceUrl = false,
    navigationExtras?: UrlCreationOptions,
  ): UrlTree {
    const request = this.route.request(data, queryParamsHandling, replaceUrl);
    const commands = this.getUrlTreeCommands(request);
    return this.router.createUrlTree(commands, {
      ...request.extras,
      ...(navigationExtras || {}),
      queryParams: request.query || {},
    });
  }

  public getPortalRouterLinkData(
    data: D,
    queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
    replaceUrl = false,
  ): PortalRouterLinkData {
    const request = this.route.request(data, queryParamsHandling, replaceUrl);
    const commands = this.getUrlTreeCommands(request);
    return { routerLink: ['', ...commands], queryParams: request.query || {}, queryParamsHandling, replaceUrl };
  }

  private getUrlTreeCommands(request: NavigationRequest): any[] {
    if (this.resolveOutlet() === RouterOutlets.PRIMARY) {
      let preparedPath = request.path.join('/');
      if (preparedPath.startsWith('/')) {
        preparedPath = this.trimStartSlashes(preparedPath);
      }
      return [{ outlets: { primary: preparedPath.split('/'), drawer: null } }];
    } else {
      let primaryPath = window.location.pathname.split('(')[0];
      if (primaryPath.startsWith('/')) {
        primaryPath = this.trimStartSlashes(primaryPath);
      }
      return [{ outlets: { primary: primaryPath, drawer: request.path } }];
    }
  }

  public navigate(
    data: D,
    queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
    replaceUrl = false,
    closeDrawer?: boolean,
    isHalfDrawer?: boolean,
  ): void {
    const request = this.route.request(data, queryParamsHandling, replaceUrl);

    if (this.resolveOutlet() === RouterOutlets.PRIMARY) {
      this.doNavigateInMainOutlet({ ...request }, closeDrawer);
    } else {
      this.doNavigateInDrawer({ ...request }, isHalfDrawer);
    }
  }

  public back(): void {
    this.store.dispatch(fromRootStore.back(undefined));
  }

  protected getCurrentRouterState$(): Observable<RouterStateUrl> {
    return this.store.select(fromRootStore.getRouterNavigationState);
  }

  protected doNavigateInDrawer(request: NavigationRequest, isHalfDrawer: boolean): void {
    this.store.dispatch(fromRootStore.openInDrawer({ ...request, isHalfDrawer }));
  }

  protected doNavigateInMainOutlet(request: NavigationRequest, closeDrawer?: boolean): void {
    if (closeDrawer) {
      if (request.path[0] === '/') {
        request.path.shift();
      }
      this.store.dispatch(fromRootStore.goAndCloseDrawer({ ...request }));
    } else {
      this.store.dispatch(fromRootStore.go({ ...request }));
    }
  }

  private trimStartSlashes(path: string): string {
    let result = path;
    while (result.startsWith('/')) {
      result = result.slice(1);
    }
    return result;
  }

  private resolveOutlet(): RouterOutlets {
    return this.outlet ?? this.route.outlet ?? RouterOutlets.PRIMARY;
  }
}
