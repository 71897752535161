import { Pipe, PipeTransform } from '@angular/core';
import { displayHHMMTimeString } from '@rootTypes/utils/common/time';

@Pipe({
  name: 'time24FromHHMM',
})
export class Time24FromHHMMPipe implements PipeTransform {
  transform(value: string): string {
    return displayHHMMTimeString(value, 'HH:mm');
  }
}
