import { IndexDbConnection } from './index-db-connection';
import { portalIndexDbConfig } from './index-db-config';

let connection: IndexDbConnection;

export const getConnectionInstance = (): IndexDbConnection => {
  if (!connection) {
    connection = new IndexDbConnection(portalIndexDbConfig);
  }
  return connection;
};
