import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';

export interface CampusListRouteData {
  page?: string;
  pageSize?: string;
  query?: string;
  status?: string;
}

export const campusListRoute: PortalRoute<CampusListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/campuses/list');
  },
  parse: (state: RouterStateUrl): CampusListRouteData => {
    let data: CampusListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (data: CampusListRouteData = {}, queryParamStrategy?: QueryParamsHandlingStrategy): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['/campuses', 'list'],
    };
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
  getHref(data?: CampusListRouteData): string {
    return '/campuses/list';
  },
};
