// BD-3569 TODO

import { PortalCircleOptions } from '../types/portal-map-circle';
import { MapPortalShapeConfig } from '../types/portal-shape';
import LatLngLiteral = google.maps.LatLngLiteral;
import { MapPopup } from './map-popup-renderer';

type PortalCircleRendered = MapPortalShapeConfig<google.maps.Circle, google.maps.Marker>;

export class MapCircleRenderer {
  private gCircles: PortalCircleRendered[] = [];
  private activePopup: MapPopup | null = null;

  constructor(private map: google.maps.Map) {}

  public async setCircles(circles: PortalCircleOptions[]): Promise<void> {
    this.gCircles.map((c) => this.removeCircle(c));
    console.log('Gcircles', this.gCircles);
    if (!circles) {
      return;
    }

    const removedCircles = this.getRemovedCircles(this.gCircles, circles);
    const addCircles = this.getAddedCircles(this.gCircles, circles);
    removedCircles.forEach((removed) => {
      removed.shape.setMap(null);
      // call remove for custom markers
      if ('remove' in removed) {
        (removed as any).remove();
      }
      const removedIndex = this.gCircles.findIndex((gc) => gc === removed);
      this.gCircles.splice(removedIndex, 1);
    });
    const promises = addCircles.map((c) => this.addCircleToMapHandler(c));
    await Promise.all(promises);
  }

  public fitMapToCircles(): void {
    if (!this.gCircles.length) {
      return;
    }
    if (this.gCircles.length === 1) {
      const circle = this.gCircles[0];
      this.map.setCenter(circle.shape.getCenter());
      this.map.setZoom(17);
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.gCircles.length; i++) {
      bounds.extend(this.gCircles[i].shape.getCenter());
    }
    if (this.map) {
      this.map.fitBounds(bounds);
    }
  }

  private async addCircleToMapHandler(circle: PortalCircleOptions): Promise<void> {
    if (circle) {
      return this.addCircleToMap(circle);
    }
  }

  private async addCircleToMap(circle: PortalCircleOptions): Promise<void> {
    const gCircle = new google.maps.Circle({
      center: circle.shape.center,
      radius: circle.shape.radius,
      fillColor: circle.shape.fillColor,
      fillOpacity: circle.shape.fillOpacity,
      strokeWeight: circle.shape.strokeWeight,
      strokeColor: circle.shape.strokeColor,
      clickable: circle.shape.clickable,
      editable: circle.shape.editable,
      zIndex: circle.shape.zIndex | 100,
      map: this.map,
    });

    let circleMarker: google.maps.Marker = null;

    const gCircleData = {
      shape: gCircle,
      marker: null,
    };
    if (circle.marker) {
      circleMarker = new google.maps.Marker({
        ...circle.marker,
        position: circle.marker.position
          ? ({ lat: circle.marker.position.lat, lng: circle.marker.position.lng } as LatLngLiteral)
          : circle.shape.center,
      });
      gCircleData.marker = circleMarker;
      circleMarker.setMap(this.map);
    }

    if (circle.tooltip) {
      gCircle.addListener('click', () => {
        this.removeTooltip();
        import('./map-popup-renderer').then((mp) => {
          this.activePopup = new mp.MapPopup(
            new google.maps.LatLng(circle.shape.center.lat as number, circle.shape.center.lng as number),
            circle.tooltip,
            () => circle.tooltipClickHandler,
          );
          this.activePopup.setMap(this.map);
        });
      });

      this.map.addListener('click', () => {
        this.removeTooltip();
      });
    }

    gCircle.set('compareId', circle.shape.compareId);
    this.gCircles.push(gCircleData);
    gCircle.setMap(this.map);
    // this.map.setCenter(circle.shape.center);
    if (circle.shape.onCenterChanged) {
      google.maps.event.addListener(gCircleData.shape, 'center_changed', () => {
        const newCenter = gCircleData.shape.getCenter();
        circle.shape.onCenterChanged(newCenter);
      });
    }
    if (circle.shape.onRadiusChanged) {
      google.maps.event.addListener(gCircleData.shape, 'radius_changed', () => {
        const newRadius = gCircleData.shape.getRadius();
        circle.shape.onRadiusChanged(newRadius);
      });
    }
  }

  private removeCircle(gCircleData: PortalCircleRendered) {
    gCircleData.shape.setMap(null);
    gCircleData.marker?.setMap(null);

    const index = this.gCircles.indexOf(gCircleData);
    if (index !== -1) {
      this.gCircles.splice(index, 1);
    }
  }

  private getAddedCircles(previous: PortalCircleRendered[], newCircles: PortalCircleOptions[]): PortalCircleOptions[] {
    const result = [];
    newCircles.forEach((newCircle) => {
      if (!newCircle.shape.compareId) {
        result.push(newCircle);
      } else {
        const wasAdded = previous.some((prevCircle) => prevCircle.shape.get('compareId') === newCircle.shape.compareId);
        if (!wasAdded) {
          result.push(newCircles);
        }
      }
    });
    return result;
  }

  public removeTooltip(): void {
    if (this.activePopup) {
      this.activePopup.setMap(null);
      this.activePopup = null;
    }
  }

  private getRemovedCircles(
    previous: PortalCircleRendered[],
    newCircles: PortalCircleOptions[],
  ): PortalCircleRendered[] {
    const result = [];
    previous.forEach((prevCircle) => {
      const prevCompareId = prevCircle.shape.get('compareId');
      if (!prevCompareId) {
        result.push(prevCircle);
      } else {
        const isPresentInTheNewBatch = newCircles.some((newCircle) => newCircle.shape.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevCircle);
        }
      }
    });
    return result;
  }
}
